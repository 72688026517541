body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.meet-the-team-banner .body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

.meet-the-team-banner #banner {
    clear: both;

    height:auto;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

.meet-the-team-banner #banner .banner-slide {
    background-position: center;
    background-size: cover;
}

.meet-the-team-banner .team-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.meet-the-team-banner #banner .header {
    margin: 0;
    padding: 40px 0 0;
}

.meet-the-team-banner h1 {
    font-size: 2rem;
    line-height: 1.5em;

    color: #000;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;

    margin: 0 0 20px;
}

@media only screen and (min-width: 1300px){
    .meet-the-team-banner .team-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px){
    .meet-the-team-banner .team-container {
        padding: 0 20px;
    }

    .meet-the-team-banner #banner .header {
        padding: 90px 0 0;
    }

    .meet-the-team-banner h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }   
}