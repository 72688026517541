.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    margin: 10px 0;
}

.whychooseusbreadcrumbs-body-wrap .container {
    width: 100% !important;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px !important;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
}

ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.65em;
    list-style: none;
}

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li {
    /* display: inline-block; */
    display: inline-flex;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .875rem;
    line-height: 2em;

    /* commented this to see if breadcrumbs get fixed */
    /* list-style: disc outside; */

    /* added this to see if breadcrumbs get fixed */
    padding-right: 5px;
}

/* added this to see if breadcrumbs get fixed */
ul li {
    list-style: disc outside;
}

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}

/* commented this to see if breadcrumbs get fixed */
/* .whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0px ;
}  */

.whychooseusbreadcrumbs-body-wrap #content .breadcrumbs ul li:before {
    /* content: '\07C'; */
    content: '';
    margin: 0;
}

/* commented this to see if breadcrumbs get fixed */
/* .whychooseusbreadcrumbs-body-wrap ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    margin: 0 0 20px;

    list-style: none;
} */

@media only screen and (min-width: 800px){
    .whychooseusbreadcrumbs-body-wrap .container {
        padding: 0 20px;
    }
    .home #content:after {
        margin: 0 !important;
    }
}

@media only screen and (min-width: 1300px){
    .whychooseusbreadcrumbs-body-wrap .container {
        max-width: 1240px;
    }
}