.body-wrap {   
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}
/* added this to add space between featured package content and package tabs */
.package-content-wrap {
    margin-bottom: 80px;
}
#package-banner {
    clear: both;
}
#package-banner .banner-slide {
    background-position: center;
    background-size: cover;
    padding-top: 135px;
}
.package-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    transition: max-width .3s ease;
}
#package-banner .header {
    margin: 0;
    padding: 40px 0 0;
}
.package-content-wrap p{
    padding-right: 20px;
}
h1 {
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}
#package-content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    margin: 10px 0 50px;
}
#package-content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
}
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.65em;
    list-style: none;
}
#package-content .breadcrumbs ul li {
    display: inline-block;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    /* changed from .675 to .875 */
    font-size: .875rem;
    line-height: 2em;

    padding-right: 5px;
}
ul li {
    list-style: disc outside;
    /* font-size: 18px; */
    /* font-size:16px; */
}
#package-content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}
#package-content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}
#package-content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}
/* commented this to see if breadcrumbs get fixed */
/* #package-content .breadcrumbs ul li:before {
    content: '';
    margin: 0;
} */
h3 {
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
    margin: 0 0 20px;
}
p {
    margin: 0 0 20px;
    font-size: 19px;
    line-height: 1.6;
}
.package-content-wrap .package-container .featured-package {
    padding: 40px;
    padding-bottom: 12px !important;
    /* width: 70%; */
}
.single-border {
    background: #FFF;
    position: relative;
    border: 1px solid #000;
    z-index: 1;
    color: #63666a;
}
.package-content-wrap .package-container .featured-package h5 {
    margin: 20px 0 0;
    /* font-weight: 900; */
    letter-spacing: 0;
    font-size: 1.25rem;
    line-height: 1.75em;
}
h5 {
    color: #000;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    margin: 0 0 20px;
}
strong {
    font-weight: bold;
}
em {
    font-style: italic;
}
.package-container ul {
    margin: 20px 0 !important;  
}
.package-container ul.accent-sq {
    margin-top: -15px !important;
}
ul.accent-sq {
    margin: 20px 0;  
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
}
ul.accent-sq li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
    font-size: 17px;
}
ul.accent-sq li:before {
    content: '● ';
    position: absolute;
    margin-left: -20px;
    color: #d40029;
}
ul.accent-sq li a {
    color: #000;
}
.content-section a {
    font-weight: 700;
}
a {
    text-decoration: none;
}
.package-container p{
    padding-right: 15px;
}
/* p {
    text-align: left;
    line-height: 1.6;
    font-size: 19px;
} */

/* added this because bullet points were clipped for how it works section */
.package-content-wrap li {
    margin-left:20px !important;
}

@media only screen and (min-width: 1300px) {
    .package-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .package-content-wrap .package-container .featured-package {
        /* changed from 86 to 100 percent */
        width: 100%;
    }
    .package-content-wrap p{
        padding-right: 20px;
    }
    .package-container {
        padding: 0 20px;
    }
    .package-container p {
        padding-right: 0;
    }
    #package-banner .header {
        /* changed padding from 90px 0 0 to 295px 0 0 to make space above packages page heading consistent
            with your team page
        */
        padding: 295px 0 0;
    }
     /* added this to reduce space above heading that comes with the above change */
    #package-banner .banner-slide {
        padding-top: 0;
    }
    h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }
    .package-content-wrap>.row {
        margin-bottom: 80px;
    }
    .package-content-wrap .package-container [class*='col-'] {
        margin-bottom: 0;
    }
    .twelve .col-ddd-6 {
        /* changed from 47 to 50 percent */
        width: 50%;
    }
    h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }
    #package-content .pad-left-lg {
        padding-left: 60px;
    }
}

@media only screen and (min-width: 600px) {
    .package-content-wrap>.row {
        padding-bottom: 20px;
    }
    #package-content .breadcrumbs ul li {
        font-size: .875rem;
    }
    /* p {
        text-align: left;
        line-height: 1.6;
        font-size: 15px;
    } */
}

/* to fix mobile view cropped content */
/* may be causing loading issues? */

@media only screen and (max-width: 400px){
    /* .package-container {
        margin-left:-10px !important;
    } */

    .package-container p {
        margin-right:10px !important;
    }

    .package-content-wrap li {
        margin-left:20px !important;
    }
}
