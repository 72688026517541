.franchise-reviews-wrap.row {
    margin: 0;
}

.franchise-reviews-wrap.row {
    margin-bottom: 40px;
}

.franchise-reviews-wrap {
    padding: 40px 0;
    color: #fff;
    /* background: #63666a url(https://www.acehandymanservices.com/offices/birmingham-ne/images/reviews-bg-lg.png) no-repeat center center; */
    background-size: cover;
}

.franchise-reviews-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.franchise-reviews-wrap .franchise-reviews-container .heading {
    color: #fff;
    margin: 0 0 10px;
    font-weight: 700;
}

h2 {
    font-size: 1.75rem;
    line-height: 1.5em;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    margin: 0 0 20px;
}

.franchise-reviews-wrap .franchise-reviews-container .subhead {
    display: flex;
}

.franchise-reviews-wrap .franchise-reviews-container .subhead p {
    margin-right: 5px;
}

.franchise-reviews-wrap .franchise-reviews-container .underline {
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;

    margin: 0 0 20px;
}

.franchise-reviews-wrap .franchise-reviews-container .links {
    color: #fff;
}

.franchise-reviews-wrap .franchise-reviews-container .links a {
    color: #fff;
    font-weight: 700;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews {
    position: relative;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides {
    width: 78%;
    margin: 0 auto;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item {
    width: 100%;
    text-align: center;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .rating {
    margin-bottom: 20px;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .rating span.r-star {
    display: inline-block;
    margin-right: 4px;

    /* referred corporate reviews carousel for this */
    background: transparent url('../images/reviews-star-full.png') no-repeat center center;
    background-size: contain;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .rating span {
    display: block;

    /* referred corporate reviews carousel for this */
    display: inline-block;
    width: 33px;
    height: 24px;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .review-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.3333333333333333em;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .text {
    margin-bottom: 20px;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .name {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5555555555555556em;
}

/* added this to center review text if copyescaped value contains p */
.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .text p {
    text-align: center;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .slick-arrow::before {
    font-size: 45px;
    /* color: gray; */
    display: block;
    /* font-family: revicons; */
    text-align: center;
    z-index: 2;
    position: relative;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .slick-arrow {
    background: none !important;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .slick-arrow.slick-prev {
    left: -15px;
}

.franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .slick-arrow.slick-next {
    right: -45px;
}

/* .review-custom-msg {
    min-height: 200px;
} */

.review-custom-msg,
.review-custom-msg p {
    text-align: center;
}

.review-custom-msg h3 {
    color: #fff;
    margin-top: 50px;
    margin-bottom: 16px;
    font-size: 16px;
}

.review-custom-msg p a {
    /* color: #fff; */
    color: #d40029;
    /* text-decoration: underline; */
    font-weight: 600;
}

@media only screen and (min-width: 600px) {
    .franchise-reviews-wrap {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 800px) {
    .franchise-reviews-wrap .franchise-reviews-container .subhead {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides .review-item .rating {
        width: 110%;
    }
}

@media only screen and (min-width: 800px) {
    .franchise-reviews-wrap.row {
        margin-bottom: 60px;
    }

    .franchise-reviews-wrap {
        padding: 80px 0;
    }

    .franchise-reviews-container {
        padding: 0 20px;
    }

    h2 {
        font-size: 2.625rem;
        line-height: 1.4285714285714286em;
    }

    .franchise-reviews-wrap .franchise-reviews-container p .links {
        display: inline-block;
    }

    .franchise-reviews-wrap .franchise-reviews-container #full-reviews #review-slides {
        width: 90%;
    }
}

/* @media only screen and (min-width: 1040px){
    .franchise-reviews-wrap {
        background-image: url(https://www.acehandymanservices.com/offices/birmingham-ne/images/reviews-bg-xl.png);
    }
} */

@media only screen and (min-width: 1300px) {
    .franchise-reviews-container {
        max-width: 1240px;
    }
}