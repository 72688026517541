@media screen and (min-width: 56.875em) {
  .blogBox {
    display: flex;
    flex-direction: row;
  }

  .blogBoxOne {
    order: 1;
    width: 72%;
    margin-right: -5%;
    padding-top: 0 !important;
  }

  .blogBoxTwo {
    order: 2;
    width: 30%;
    margin-left: 0%;
  }
}

@media screen and (max-width: 799px) {
  .blogBoxOne {
    padding-top: 90px;
  }
}

@media screen and (min-width: 800px) {
  .blogBoxOne {
    padding-top: 140px;
  }
}
