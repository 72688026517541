section.remodel {
  position: relative;
  padding: 20px 0;
}

section.remodel .left {
  padding-bottom: 25px;
}

section.remodel .btn {
  padding-right: 70px;
  padding-left: 70px;
  font-weight: 700;
}

.btn {
  font-weight: 400;
}

a {
  font-weight: 700;

  color: #d40029;

  text-decoration: none;
}

.btn {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.3333333333333333em;
  font-family: "Roboto", sans-serif;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 10px;
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

section.remodel .left img {
  /* changed from 290px */
  height: auto;
  width: 100%;
  /* replaced cover with contain because image was clipped */
  /* reverted to cover because of space above and below image resulting from contain */
  object-fit: cover;
  /* replaced bottom with center because image was showing space above */
  object-position: center;
}

.remodel.bg-grey.twelve ul {
  font-weight: 600;
  margin-left: 0 !important;
}

.pb-25 {
  padding-bottom: 25px;
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .left-image-copy-tray .col-dd-4,
  .left-image-copy-tray .col-dd-8 {
    width: 100% !important;
  }
  .left-image-copy-tray .row {
    display: block !important;
  }

  .left-image-copy-tray .mb-0 {
    display: block !important;
  }

  section.remodel .left {
    position: static !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  section.remodel .right {
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media only screen and (min-width: 800px) {
  section.remodel {
    padding: 0;
  }

  section.remodel .left {
    margin-left: 55px;
    padding-bottom: 0;
    position: absolute;
    /* margin-left: 15px; */
    top: 0;
    bottom: 0;
  }

  section.remodel .left img {
    height: 100%;
  }

  /* Video support */
  .image-copy-Tray {
    width: 100%;
    height: 100%;
  }

  section.remodel .right {
    /* margin-left: 33.33%;  */
    margin-left: 38%;
    padding: 40px 20px 40px 40px;
    padding-right: 0;
  }

  section.remodel.bg-grey.twelve .right.col-sd-12.col-dd-8 span p .banner {
    display: block;
    margin: 0 auto;
    max-width: 346px;
  }
}

section.changing,
section.remodel {
  /* changed margin-bottom from 40px to 10px */
  /* margin-bottom: 10px; */
  /* margin-top: 60px; */
  /* changed the margin bottom to 40 for homepage padding issue - 52577 */
  margin-bottom: 20px;
}

@media only screen and (min-width: 1920px) {
  /* gray backgroung issue in homepage and whychooseus page */
  /* .remodel .container .row .left {
        background: rgb(223, 220, 220);
    } */
  .remodel .container .row .left img {
    object-fit: contain;
  }
  /* .remodel .container {
        margin-right:11%;
    } */
}

@media only screen and (max-width: 600px) {
  section.remodel {
    text-align: center;
  }

  section.remodel.bg-grey.twelve .right {
    margin-top: 30px;
  }

  section.remodel.bg-grey.twelve .right ul li {
    padding: 0 15px;
  }

  section.remodel.bg-grey.twelve .right span div {
    padding: 0 15px;
  }
}

/* New Image Tray CSS  Start*/
.image-tray-container {
  display: flex;
  align-items: center;
}

.image-tray-container img {
  object-fit: cover;
  height: 100%;
}

.left-tray-img {
  align-self: stretch;
}

.left-tray-text {
  padding-left: 40px;
}

.left-tray-text h3 {
  padding-left: 20px;
}

@media only screen and (min-width: 1040px) {
  .left-tray-text h3 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .image-tray-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 799px) {
  .left-img-tray .container {
    width: 90% !important;
  }
}

/* New Image Tray CSS  End*/
/* Landscape mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  section.img-copy-tray {
    padding:13px
  }
}

/* Portrait mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  section.img-copy-tray {
    padding: 13px;
  }
}
