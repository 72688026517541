.services-packages-wrap .row {
    margin-bottom: 0;
}

.services-packages-wrap {
    position: relative;
    background: #efeff0;
    -webkit-box-shadow: 0 13px 13px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 13px 13px rgba(0,0,0,0.2);
    box-shadow: 0 13px 13px rgb(0 0 0 / 20%);
}

/* commented to reduce space above the icons in careers page */
/* .content-section .row {
    margin-bottom: 40px;
} */

html, body, div {
    padding-top: 0.001em;
}

/* *, *:before, *:after {
    box-sizing: inherit;
} */

.row:before, .row:after {
    content: "";
    display: table;
    clear: both;
}

/* this hides the image */
.services-packages-wrap .tools {
    display: none;
}

.services-packages-wrap img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

/* img, iframe, object {
    vertical-align: middle;
} */

.services-packages-wrap .services-packages-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.services-packages-wrap .left-col {
    margin: 30px 0 20px;
}

[class*='col-'] {
    float: left;
    vertical-align: top;
    width: 100%;
    min-height: 1px;
}

.services-packages-wrap h3{
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;

    color: #000;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

.services-packages-wrap h3, .services-packages-wrap p, .services-packages-wrap ul, .services-packages-wrap ol, .services-packages-wrap blockquote, .services-packages-wrap table {
    margin: 0 0 20px;
}

.services-packages-wrap .left-col ul {
    font-size: 1rem;
    line-height: 2em;
}

.services-packages-wrap ul.accent-sq, .services-packages-wrap .accordion ul {
    /* margin: 20px 0 !important; */
    margin: 0 !important;
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
}

.services-packages-wrap ul, .services-packages-wrap ol {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;
}

.services-packages-wrap ol, .services-packages-wrap ul {
    list-style: none;
    margin-left: 0 !important;
}

.services-packages-wrap ul.accent-sq li, .services-packages-wrap .accordion ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
}

.services-packages-wrap ul.accent-sq li {
    font-size: 15.7px;
    padding-right: 0;
}

.services-packages-wrap ul li {
    list-style: disc outside;
}

.services-packages-wrap ul.accent-sq li:before, .services-packages-wrap .accordion ul li:before {
    content: '● ';
    position: absolute;
    margin-left: -20px;
    color: #d40029;
}

.services-packages-wrap .left-col ul a {
    font-weight: 700;
    color: #000;
    text-decoration: none;
}

.services-packages-wrap ul.accent-sq li a, .services-packages-wrap .accordion ul li a {
    color: #000;
}

.services-packages-wrap a {
    font-weight: 700;

    color: #d40029;

    text-decoration: none;
}

.services-packages-wrap .single-border {
    background: #FFF;
    position: relative;
    border: 1px solid #000;
    z-index: 1;
    color: #63666a;
    margin-bottom: 20px;
}

.single-border ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
}

#packages .single-border ul li:before {
    content: '● ';
    position: absolute;
    margin-left: -20px;
    color: #d40029;
}

.single-border li a {
    color: #000;
}

.single-border li a:hover {
    text-decoration: underline;
}

.services-packages-wrap .right-col .content {
    padding: 30px 10%;
}

/* make the packages list two column */
.two-col {
    /* commented this to make columns resemble original website */
    /* display: flex;
    width: 100%;
    flex-wrap: wrap; */
    column-count:2;
}

/* commented this to make columns resemble original website */
/* .two-col li {
    flex-basis: 50%;
} */

@media only screen and (min-width: 1000px){
    .services-packages-wrap .tools {
        position: absolute;
        top: -120px;
        left: 0;
        display: block;
        width: 19%;
    }

    .services-packages-wrap .tools img {
        max-width: 100%;
    }

    .services-packages-wrap .left-col {
        padding-left: 200px;
    }
}




@media only screen and (min-width: 600px){
    .services-packages-wrap .right-col .content {
        padding: 30px 50px 10px 40px;
    }

    .services-packages-wrap.row {
        margin-bottom: 40px;
    }

    .services-packages-wrap img {
        width: auto !important;
    }

    .services-packages-wrap .left-col .services-packages-container {
        padding: 0;
    }

    .services-packages-wrap .twelve .col-md-6 {
        width: 50%;
    }

    .services-packages-wrap .right-col {
        margin: 10px 0 60px;
    }
}

@media only screen and (max-width: 800px){ 
    .twelve .col-md-12 {
        width: 100% !important;
    }
    .services-packages-wrap .right-col .content {
        padding: 30px 50px 0 40px;
    }
}

@media only screen and (min-width: 800px){
    .services-packages-wrap.row {
        margin-bottom: 80px;
    }

    .services-packages-wrap .row {
        margin-bottom: 60px;
    }

    .services-packages-wrap .services-packages-container {
        padding: 0 20px;
    }

    .services-packages-wrap .left-col {
        margin: 50px 0;
    }

    .services-packages-wrap .pad-right-lg {
        padding-right: 60px;
    }

    .twelve .col-dd-7 {
        width: 58.33%;
    }

    .services-packages-wrap h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }

    .services-packages-wrap .right-col {
        margin: 60px 0 !important;
    }

    .services-packages-wrap .twelve .col-dd-5 {
        width: 41.66%;
    }
}


@media only screen and (min-width: 1300px) {
    .services-packages-wrap .services-packages-container {
        max-width: 1240px;
    }
}

@media only screen and (max-width: 400px) {
    .two-col {
        column-count: 1;
    }
}