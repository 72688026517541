#franchise-banner .react-multi-carousel-dot button {
  margin-top: 100px !important;
}

#franchise-banner .custom-slider {
  position: relative;
  /* changed margin top from 5.9rem to 10.9rem */
  margin-top: 7.2rem;
}

#franchise-banner .custom-slider ul:first-of-type {
  padding: 0;
  margin: 0;
}

#franchise-banner .custom-slider ul {
  margin: 0 !important;
}

#franchise-banner .custom-slider ul li {
  list-style: none !important;
}

/* added to change the carousel dot styling and behavior */
#franchise-banner .custom-slider .react-multi-carousel-dot button {
  border-radius: 0;
  background: #becccf !important;
  border-color: transparent;
}
#franchise-banner .custom-slider .react-multi-carousel-dot button:hover {
  background: transparent !important;
}
#franchise-banner .custom-slider .react-multi-carousel-dot--active button {
  background: black !important;
}
#franchise-banner
  .custom-slider
  .react-multi-carousel-dot--active
  button:hover {
  background: black !important;
}

#franchise-banner {
  clear: both;
  min-height: 1px;
  position: relative;
}
#franchise-banner .custom-slider .banner-slide {
  z-index: 1;
  position: relative;
  top: 0;
  background-color: #fff;
  width: 100%;
  /* border-top: 2px solid #d40029; */
}
#franchise-banner .banner-slide {
  background-color: #efeff0;
  background-position: center center;
  background-size: cover;
}
#franchise-banner .custom-slider .banner-slide figure img {
  width: 100%;
  display: block;
}
#franchise-banner .custom-slider .banner-slide .text-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
}
#franchise-banner .custom-slider .banner-slide .cta-wrap {
  padding: 10px;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
}
#franchise-banner .custom-slider .banner-slide .cta-wrap .cta.small {
  font-size: 9vw;
  letter-spacing: -1px;
}
#franchise-banner .custom-slider .banner-slide .cta-wrap .cta {
  color: #000;
  /* white-space: nowrap; */
  font-weight: 900;
  font-family: "Roboto Slab", serif;
  font-size: 10vw;
  line-height: 1;
}
#franchise-banner .custom-slider .banner-slide .cta-wrap .cta {
  color: #000;
  /* white-space: nowrap; */
  font-weight: 900;
  font-family: "Roboto Slab", serif;
  font-size: 10vw;
  line-height: 1;
}

@media only screen and (min-width: 600px) {
  #franchise-banner {
    /* changed height from 33 vw to 25 vw to allow phone number next to heading to display on load */
    /* this is in conjunction with  making width 40% in #franchise-banner .custom-slider .banner-slide figure */
    height: 27vw;
  }
  #franchise-banner .custom-slider .banner-slide .flex-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #franchise-banner .custom-slider .banner-slide figure {
    /* changed width from 54% to 40% to allow phone number next to heading to display onload */
    /* this is in conjuction with making height 25vw in #franchise-banner */
    width: 45%;
    position: relative;
  }
  #franchise-banner .custom-slider .banner-slide .text-wrap {
    text-align: center;
    width: 46%;
    position: relative;
  }
  #franchise-banner .custom-slider .banner-slide .cta-wrap {
    padding: 0;
    display: block;
  }
  #franchise-banner .custom-slider .banner-slide .cta-wrap .cta.small {
    /* font-size: 4.8vw; */
    font-size: 3.6vw;
    line-height: 1.15;
    /* margin-right: 1rem; */
    margin-right: -7rem;
  }
  #franchise-banner .custom-slider .banner-slide .cta-wrap .cta {
    font-size: 5.35vw;
    line-height: 1.05;
  }
  #franchise-banner .custom-slider .banner-slide .cta-wrap .cta.smaller {
    font-size: 4.2vw;
    line-height: 1.15;
  }
}

@media only screen and (max-width: 799px) {
  #franchise-banner .custom-slider {
    /* margin-top: 2.9rem; */
    margin-top: 6.4rem;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  #franchise-banner .custom-slider {
    /* margin-top: 2.9rem; */
    margin-top: 7.4rem;
  }
}

@media only screen and (max-width: 600px) {
  #top {
    margin-bottom: 90px !important;
  }

  #franchise-banner .custom-slider .banner-slide .text-wrap {
    margin-top: 55px;
  }
}

@media only screen and (min-width: 1920px) {
  #franchise-banner .custom-slider .banner-slide figure {
    width: 54%;
  }

  #franchise-banner {
    height: 33vw;
  }

  #franchise-banner .custom-slider .banner-slide .cta-wrap .cta.small {
    font-size: 3.3vw;
    line-height: 1.05;
    margin-left: -30px;
  }

  #franchise-banner .custom-slider .banner-slide .text-wrap {
    width: 42%;
  }
}
