.long-zip form {
    position: relative;
}

.long-zip form .input {
    display: inline-block;
    width: calc(100% - 90px);
    vertical-align: top;
}

.long-zip form .input input {
    margin-top: 5px;
    font-size: .9375rem;
    line-height: 1.4666666666666666em;

    width: 100%;
    margin: 0 0 1px;
    padding: 15px;
    outline: 0;
    border: 0;
    background: #efeff0;
    color: #8a8c8f;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    font-style: normal;
    font-weight: normal;
    /* font-size: .9375rem;
    line-height: 2.1333333333333333em; */
    font-family: 'Roboto Condensed',sans-serif;
}

.long-zip form .error input {
    /* margin-top: 5px;
    font-size: .9375rem;
    line-height: 1.4666666666666666em;

    width: 100%;
    margin: 0 0 1px;
    padding: 15px;
    outline: 0;
    border: 0; */
    color: red!important;
    background: #fee!important;
    /* -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    font-style: normal;
    font-weight: normal;
    font-family: 'Roboto Condensed',sans-serif; */
}

.long-zip form .search {
    display: inline-block;
    width: 90px;
    vertical-align: top;
}

.long-zip form .search button {
    width: 100%;
    margin-top: 0px;
    font-size: .9375rem;
    line-height: 1.4666666666666666em;
}

.long-zip .btn {
    font-weight: 400;

    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    /* font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em; */
    font-family: 'Roboto',sans-serif;
    /* padding: 15px 30px; */
    cursor: pointer;
    /* margin-top: 10px; */
    font-style: normal;
    border: 0;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.long-zip .submit {
    /* width: 100%; */
    padding: 15px 20px;
    /* font-size: .875rem;
    line-height: 1em; */
}

.long-zip form .instructions {
    font-size: .75rem;
    line-height: 2.1666666666666665em;
    color: #63666a;
    opacity: 1;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;

    padding-left: 0px;
}

/* make HANDYMAN MATTERS text bold */
.long-zip p span p span:nth-of-type(2){
    font-weight: bold;
}

@media only screen and (min-width: 800px){
    .long-zip form {
        width: 80%;
    }
}