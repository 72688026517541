.body-wrap {   
    background-color: #fff;
    background-repeat: repeat; 
    background-position: top center;
}
#services-banner {
    clear: both;
}
#services-banner .banner-slide {
    background-position: center;
    background-size: cover;
}
#services-banner .header {
    margin: 0;
    padding: 40px 0 0;
}
h1 {
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
    color: #000;
}
#services-content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    margin: 10px 0 50px;
}
#services-content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0 ;
    line-height: 0;
}
#services-content .breadcrumbs ul li {
    display: inline-block;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .875rem;
    line-height: 2em;
    padding-right: 5px;
}
#services-content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}
#services-content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}
#services-content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}
/* commented to see if breadcrumbs get fixed */
/* #services-content .breadcrumbs ul li:before {
    content: '';
    margin: 0;
} */
p {
    font-size: 19px;
    line-height: 1.5;
    margin: 0 0 20px;
}

.services-container a {
    color: #d40029;
}

.services-container.small span p span {
    font-size: x-small;
}

@media only screen and (min-width: 800px){
    .services-container {
        padding: 0 20px;
    }
    #services-banner .header {
        /* changed padding from 50px 0 0 to 295px 0 0 to make space above heading consistent
            with your team page
        */
        padding: 300px 0 0;
    }
    /* added this to reduce space above heading that comes with the above change */
    #services-banner .banner-slide {
        padding-top: 0;
    }
    h1, .h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }
}

@media only screen and (min-width: 600px){
    .content-wrap>.row {
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 1300px){
    .services-container {
        max-width: 1240px;
    }
}
