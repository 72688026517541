.page-503 #banner{
	padding-top: 0px;
}

.page-503 .not-found-wrap {
  background: #9f9798 url('../../../assets/images/404-hero.jpg') no-repeat bottom center;
    background-size: auto;
  background-size: cover;
}
.page-503 .not-found-wrap .not-found .finder {
  padding: 16vh 13% 21vh;
  background: url('../../../assets/images/404-cta-bg-blur.png') no-repeat center center;
    background-size: auto;
  background-size: contain;
}

.page-503 .not-found-wrap .not-found{
	height: 75%;
}