.bottom-padding {
  padding-bottom: 15px;
}

.accurate-answers-aknowledgement input,
.policy-accept-acknowledge input {
  width: auto !important;
  cursor: pointer;
  margin-right: 10px;
}
