.page-not-found .content-section {
  background: #9f9798;
  width: 100%;

  height: auto;
}

.page-not-found .long-zip .white {
  background-color: inherit;
}

.whychooseuslife-body-wrap#REDEFINING_SERVICE {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 800px) {
  .not-found-wrap {
    height: 80vh;
    min-height: 850px;
    background-size: cover;
    margin-top: 14%;
  }
  .not-found-wrap .not-found .finder {
    padding: 16vh 23% 21vh;
  }
}

@media only screen and (min-width: 600px) {
  .not-found-wrap .not-found {
    background: 0;
  }
  .not-found-wrap .not-found .finder {
    padding: 16vh 13% 21vh;
    background-size: auto;
    background-size: contain;
  }
}

.not-found-wrap .not-found {
  display: flex;
  padding: 0;
  height: 100%;
  -ms-flex-align: center;
  align-items: center;
}
.page-not-found .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}
.not-found-wrap {
  background-size: auto;
  background-size: cover;
}

.not-found-wrap .not-found .finder {
  margin: 0 auto;
  padding: 16vh 23% 21vh;
  color: #fff;
  text-align: center;
  background-size: contain;
}
.not-found-wrap .not-found .finder .heading {
  font-family: "Roboto Slab", serif;
  color: #fff;
  font-weight: 700;
  font-size: 6.25rem;
  line-height: 1.2em;
}

.not-found-wrap .not-found .finder p {
  font-size: 1.5625rem;
  line-height: 1.44em;
  font-family: "Roboto Slab", serif;
}

.not-found-wrap .not-found .finder .call-cta {
  margin-top: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.3em;
}
.not-found-wrap .not-found .finder .call-cta > div {
  display: inline-block;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  margin-left: 5px;
  font-size: 1.375rem;
  line-height: 1.1818181818181819em;
}
.not-found-wrap .not-found .finder .call-cta > div::before {
  content: "\f095";
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  font-size: 80%;
  margin-right: 5px;
}

/* MPS */
.page-not-found #content .row {
  margin-bottom: 0;
}
@media only screen and (min-width: 800px) {
  .page-not-found .most-popular-services {
    margin-top: -70px;
  }
  .page-not-found .most-popular-services > .container {
    padding: 0 20px;
  }
  .page-not-found .most-popular-services.row .heading {
    font-size: 2.625rem;
  }
}
@media only screen and (min-width: 600px) {
  .page-not-found .most-popular-services {
    background: transparent;
    padding: 0 0 60px;
  }
  .page-not-found .most-popular-services.row {
    display: block;
    padding: 20px 0 30px;
  }
  .page-not-found .most-popular-services > .container .heading {
    width: auto;
  }
  .page-not-found .most-popular-services.row .heading {
    height: auto;
    margin: 0 auto 40px;
    padding: 0;
    font-size: 2.125rem;
    line-height: 1.4;
    color: #fff;
    background-size: 100% 100%;
  }
}
.page-not-found .most-popular-services.row {
  text-align: center;
  background: #a19798;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
}

.page-not-found .most-popular-services.row .heading {
  position: relative;
  display: inline-block;
  padding: 20px 0;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #fff;
  vertical-align: middle;
  background-size: 100% 100%;
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;
}
/* MPS */
