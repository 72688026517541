
.whole-content {
  margin-top: -120px;
  position: relative;
  display: flex;
  align-self: flex-start;
  height: 100%;
  position: fixed;
  right: 0px;
  /* left: 0; */
  top: 120px; }
  .whole-content .mobile-nav-slide.active {
    width: 430px;
    /* background-color: #d40029; */
    height: 100%;
    display: block;
    box-sizing: initial; }
    
    .whole-content .mobile-nav-slide.active .logo-details {
      /* margin-top: 60px; */
      align-items: center;
      width: 120px;
      height: 53.438px;
      display: flex;
      flex-direction: row; }
      
    .whole-content .mobile-nav-slide.active .content {
      cursor: pointer; }
      .whole-content .mobile-nav-slide.active .content .content-details {
        color: white;
        width: 100%;
        height: 50px; }
        
        .whole-content .mobile-nav-slide.active .content .content-details .content-name {
          position: absolute;
          margin-top: 6%;
          font-size: medium; }
  .whole-content .mobile-nav-slide {
    width: 0px;
    box-sizing: border-box; }
    .whole-content .mobile-nav-slide .content-name {
      display: none; }
    
      .fa-bars:before {
        font-weight: normal;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-weight: 900;
        content: "\f0c9";
        font-size: 1.625rem;
        line-height: 2em;
        width: 100%;
        height: 100%;
        color: #000;
    }
    .dropdown-wrap .subpage a {
      font-weight: 400;
    }

    /* .link.has-dropdown {
      border-bottom: none !important;
    } */
/* Responsive view  */
    @media only screen and (min-width: 300px){
      .whole-content {
        right: -47px !important;
      }
      .mobile-nav-slide ul li.has-dropdown>i.fa-solid {
        right: 46px;
      }
    }

    @media only screen and (min-width: 600px){
      .whole-content {
        right: -40px;
      }
    }

    @media only screen and (min-width: 900px){
      .whole-content {
        right: -100px;
      }
    }
