.franchise-estimate-submission-breadcrumb {
    background: #FFF;
    margin: 0;
    /* commented this to eliminate space above and below franchise home breadcrumbs */
    /* padding: 40px 0 !important; */

    display: block;
    position: relative;
    z-index: 1;
}

.franchise-estimate-submission-breadcrumb ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
}

.franchise-estimate-submission-breadcrumb ul li {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    color: #63666a;
    font-weight: 400;
    font-size: .875rem;
    line-height: 2em;
}

.franchise-estimate-submission-breadcrumb ul li a, 
.franchise-estimate-submission-breadcrumb ul li span {
    display: inline-block;
    margin: 0 5px 0 8px;
}


.franchise-estimate-submission-breadcrumb ul li a {
    text-decoration: none;
    color: #63666a;
    font-weight: 400;
}

.franchise-estimate-submission-breadcrumb .breadcrumb-container {
    width: 100%;
    max-width: 1200px;
    /* margin: 0 auto; */
    /* padding: 0 15px; */
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

@media only screen and (min-width: 800px) {
    .franchise-estimate-submission-breadcrumb .breadcrumb-container {
        /* added 40px */
        padding: 0 40px;
    }

    .franchise-estimate-submission-breadcrumb {
        padding: 40px 0 60px;
    }
    
}

@media only screen and (min-width: 1300px) {
    .franchise-estimate-submission-breadcrumb .breadcrumb-container {
        max-width: 1240px;
    }
}