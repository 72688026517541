.blogbreadcrumbs-body-wrap {
  background-color: #fff;
  background-repeat: repeat;
  background-position: top center;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs {
  display: block;
  position: relative;
  z-index: 1;
  /* changed from margin 10px 0 50px to 50px 0 50px */
  margin: 10px 0px;
  margin-left: -13px;
}

.blogbreadcrumbs-body-wrap .container {
  width: 100% !important;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px !important;

  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs ul {
  margin: 0 !important;
  padding: 0;
  line-height: 0;
  list-style: none;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs ul li {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2em;
  list-style: disc outside;
  padding-right: 5px;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs ul li.home:before {
  content: "";
  margin: 0;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs ul li a {
  font-weight: 400;
  color: #63666a;
  text-decoration: none;
}

.blogbreadcrumbs-body-wrap #content .breadcrumbs ul li a:after {
  content: "";
  margin-right: 4px;
}

@media only screen and (min-width: 800px) {
  .blogbreadcrumbs-body-wrap .container {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 799px) {
  .blogbreadcrumbs-body-wrap #content .breadcrumbs {
    margin-left: 5px;
  }
}
