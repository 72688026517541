.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}
#our-story-banner {
    clear: both;
}
#our-story-banner .banner-slide {
    background-position: center;
    background-size: cover;
    padding-top: 150px;
}
#our-story-banner .header {
    margin: 0;
    padding: 40px 0 0;
}
h1 {
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
    color: #000;
}
#our-story-content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    margin: 10px 0 50px;
}
#our-story-content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
}

/* added this to see if breadcrumbs gets fixed */
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.65em;
    list-style: none;
}

#our-story-content .breadcrumbs ul li {
    display: inline-block;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .875rem;
    /* uncommented this to see if breadcrumbs get fixed */
    line-height: 2em;
    
    padding-right:5px;
}

/* added this to see if breadcrumbs get fixed */
ul li {
    list-style: disc outside;
}

#our-story-content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}
#our-story-content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}
#our-story-content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}
/* #our-story-content .breadcrumbs ul li:before {
    content: '';
    margin: 0 2px; */
    /* commented above to see if breadcrumbs get fixed */

    /* content: '\07C';
    margin: 0; */
/* } */
#our-story-content .pad-right h3 {
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
    margin: 0 0 20px;
}
#our-story-content .about-welcome .message {
    padding: 30px 30px 10px;
    background-color: #efeff0;
    border-radius: 5px;
    background-clip: padding-box;
}
#our-story-content .about-welcome .message figure {
    margin-bottom: 20px;
}
#our-story-content .about-welcome .message figure img {
    max-width: 100%;
}
img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    vertical-align: middle;
}
#our-story-content .pad-right p {
    font-size: 16px ;
    line-height: 2 ;
    margin: 0px ;
}

@media only screen and (min-width: 800px){
    #our-story-content .pad-right p {
        margin: 0px 65px 20px 0px;
    }
    .container {
        padding: 0 20px;
    }
    #our-story-banner .header {
        /* changed padding from 90px 0 0 to 295px 0 0 to make space above heading consistent
            with your team page
        */
        padding: 295px 0 0;
    }
    /* added this to reduce space above heading that comes with the above change */
    #our-story-banner .banner-slide {
        padding-top: 0;
    }
    h1, .h1 {
        font-size: 3rem !important;
        line-height: 1.3333333333333333em;
    }
    /* commented to reduce space above the icons in careers page */
    /* .content-section .row {
        margin-bottom: 60px;
    } */
    /* commented below because the copyaside and left aligned image copy tray texts were not wide enough */
    /* .twelve .col-dd-8 {
        width: 66.66% !important;
    } */
    .whyChooseUs-main .bg-grey.twelve .container .row .col-dd-8 {
        width: 80.66% !important;
    }
    #our-story-content .pad-right h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }
    .twelve .col-dd-4 {
        width: 33.33%;
    }
}

@media only screen and (min-width: 600px) {
    #our-story-content .pad-right {
        /* padding-right: 60px; */
        padding-right: 0;
    }
    img {
        width: auto;
    }
}

@media only screen and (min-width: 1300px){
    .container {
        max-width: 1240px;
    }
}