body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

.philanthropy-content figure, .philanthropy-content section {
    display: block;
}

body, .philanthropy-content div, .philanthropy-content p, .philanthropy-content img, 
.philanthropy-content figure, .philanthropy-content section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
} */

.philanthropy-content .content-section .row {
    margin-bottom: 40px;
}

.philanthropy-content .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.philanthropy-content p {
    margin: 0 0 20px;
}

.philanthropy-content img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.philanthropy-content img {
    vertical-align: middle;
}

.philanthropy-content #content {
    margin-top: 40px;
}

@media only screen and (min-width: 600px){
    .philanthropy-content img {
        width: auto;
    }
}

@media only screen and (min-width: 800px){
    .philanthropy-content .content-section .row {
        margin-bottom: 60px;
    }

    .philanthropy-content .container {
        padding: 0 20px;
    }

    .philanthropy-content #content .pad-right-lg {
        padding-right: 60px;
    }

    .philanthropy-content .twelve .col-dd-8 {
        /* changed from width: 66.66% */
        width: 61.66%;
    }

    .philanthropy-content .twelve .col-dd-4 {
        width: 33.33%;
    }
}