/* .whychooseusbanner-body-wrap {
    background: #fff url(https://www.acehandymanservices.com/images/bg-pattern-triangles.png) repeat top center;
} */

.whychooseusbanner-body-wrap #why-banner {
    clear: both;
}

.whychooseusbanner-body-wrap #why-banner .banner-slide {
    background-position: center;
    background-size: cover;
}

.whychooseusbanner-body-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.whychooseusbanner-body-wrap #why-banner .header {
    margin: 0;
    /* padding: 40px 0 0; */
    /* changed padding from 185px 0 0 to 85px 0 0 so that the gap above banner gets reduced in mobile view */
  /* padding: 185px 0 0; */
  padding: 120px 15px 0;
}

.whychooseusbanner-body-wrap h1{
    font-size: 2rem;
    line-height: 1.5em;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;

    margin: 0 0 20px;
}

@media only screen and (min-width: 800px){
    .whychooseusbanner-body-wrap .container {
        padding: 0 20px;
    }

    .whychooseusbanner-body-wrap #why-banner .header {
        /* padding: 90px 0 0; */
        /* padding: 295px 0 0; */
    /* changed padding from 295px 0 0 to 140px 0 0 so that the gap above banner gets reduced */
    padding: 180px 0 0 !important;
    }

    .whychooseusbanner-body-wrap #why-banner .header.h1 {
        padding-left: 10px !important;
    }

    .whychooseusbanner-body-wrap h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }
}

@media only screen and (min-width: 1300px) {
    .whychooseusbanner-body-wrap .container {
        max-width: 1240px;
    }
}
