.remodal-close:before {
    font-size: 42px;
    /* line-height: 42px;
    font-weight: 400; */
    position: absolute;
    top: 0;
    left: 0;
    /* display: block; */
    width: 42px;
    /* font-weight: normal;
    -moz-osx-font-smoothing: grayscale; */
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f00d";
    text-align: center;
}

.remodal-close{
    background: none !important;
    top: 80px !important;
}

.modal-sytle {
    overflow-y: none;
}

@media only screen and (max-width: 600px){
    .modal {
        border: 2px solid red;
    }
    /* .slick-initialized .slick-slide {
        width: 170px !important;
    } */
    .modal-sytle {
        overflow-y: scroll;
    }
}

@media only screen and (max-width: 400px){
    .remodal-close{
        /* removed top: 43px for responsive error in our work photo gallery*/
        /* top: -43px !important; */
        right: 0px !important;
    }
}