.city-detailpage-container {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

.city-detailpage-container #pro-services-content .main-content {
    margin: 10px 0 0 0;
}

@media only screen and (min-width: 1300px) {
    .city-detailpage-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .city-detailpage-container {
        padding: 0 20px;
        margin-top: 15px;
    }
}