.mobile-nav-slide {
  z-index: 1000;
  top: 0;
  right: -100%;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: right 0.3s ease-out;
}

.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
  content: "\f00d";
  font-size: 15px;
}

.fa-bars:before {
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  content: "\f0c9";
  font-size: 1.625rem;
  line-height: 2em;
  width: 100%;
  height: 100%;
  color: #000;
}

.mobile-nav-slide ul {
  margin: 0 !important;
  padding: 0;
}

.mobile-nav-slide ul li.top,
.mobile-nav-slide ul li.back {
  display: flex;
  height: auto;
  line-height: 200%;
  background-color: #d40029;
}

.mobile-nav-slide ul li {
  position: relative;
  border-bottom: 1px solid #d5d5d7;
  list-style: none;
}

.mobile-nav-slide ul li.top a,
.mobile-nav-slide ul li.back a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #efeff0;
  font-size: 0.8125rem;
  line-height: 1.3846153846153846em;
  align-self: flex-end;
}

.mobile-nav-slide ul li > a {
  display: flex;
  padding: 15px;
  padding: 12px 0 12px 10%;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1em;
}

.mobile-nav-slide ul li.top a i,
.mobile-nav-slide ul li.back a i {
  margin-right: 5px;
  font-size: 1rem;
  line-height: 1em;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.fas {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}


.mobile-nav-slide ul li.has-dropdown > i.fa-solid {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 31px;
  display: block;
  height: 70%;
  padding: 12px 15px 0;
  color: #63666a;
  background: #efeff0;
  font-size: 1.125rem;
  line-height: 1em;
}

.mobile-nav-slide ul li .dropdown-wrap {
  z-index: 10;
  position: fixed;
  top: 0;
  right: -100%;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: right 0.3s ease-out;
}

ul {
  list-style: none;
}

.mobile-nav-slide ul li .dropdown-wrap .back a {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.725rem;
  line-height: 1.2307692307692308em;
}

.mobile-nav-slide ul li .dropdown-wrap .category a {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125em;
}

.mobile-nav-slide ul li .dropdown-wrap a {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-size: 0.9375rem;
  line-height: 1em;
}

header {
  position: relative;
  background: #fff;
  width: 100%;
  z-index: 990 !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
}

header #alert-bar {
  background-color: #000;
  color: #fff;
  text-align: center;
}

header #alert-bar p {
  color: #fff;
  margin: 0;
  line-height: 1.4em;
  font-size: 0.6em;
}

header #alert-bar p center > * {
  color: #fff;
  font-weight: 400;
  display: block;
  padding: 15px 5px;
}


header #alert-bar p center > *:hover {
  text-decoration: none !important;
}

strong {
  font-weight: bold;
}

header > .yo-local {
  display: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  background: #d40029;
  font-size: 0.8125rem;
  line-height: 1em;
}

header > .yo-local .topbar-wrap {
  padding: 10px 0;
}

header > .yo-local .name {
  display: inline-block;
}

header .head-wrap .logo {
  padding: 10px 0;
  text-align: left;
}

header .head-wrap .logo svg {
  max-width: 100%;
  width: 144px;
  height: 47px;
}

header .head-wrap .header-top-wrap {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header .head-wrap .header-top-wrap .header-top {
  display: inline-block;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  text-align: right;
  text-decoration: none;
}

header .head-wrap .header-top-wrap .header-top .anchors a {
  margin-left: 20px;
  color: #000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.725rem;
  line-height: 1em;

  /* font-size: 11px; */
  font-weight: 700;
  display: none;
}

header .head-wrap .header-top-wrap .header-top .anchors a:hover {
  color: #d40029;
}

header .head-wrap .header-top-wrap .header-top .anchors a img {
  /* width: 16px;
    height: 16px; */
  width: 30px;
  height: 30px;
}

header .head-wrap .header-top-wrap .header-top .anchors a span {
  margin-left: 7px;
  position: relative;
  /* commented this because underline was striking through text instead of under it */
  /* top: 2px; */
  margin-right: 7px;
}

header .head-wrap .header-top-wrap .header-top .header-phone {
  font-size: 0.85em;
  display: inline-block;
  margin-left: 20px;
  color: #d40029;
  font-family: "Roboto Condensed", sans-serif;
}

header .head-wrap .header-top-wrap .header-top .header-phone div img {
  height: 11px;
  width: auto;
  fill: #d40029;
  margin-right: 5px;
}

header .head-wrap .header-top-wrap .header-top .header-phone > div {
  display: inline-block;
}

header .head-wrap .header-top-wrap .header-top .header-phone a {
  color: #d40029;
  top: -1px;
  position: relative;
}

header .head-wrap .header-top-wrap .header-top .header-phone a:hover {
  text-decoration: underline;
}

.mobile-nav-slide .first-menu {
  margin: 0 !important;
}

#mobile-nav {
  cursor: pointer;
  display: block;
  margin-left: 20px;
  text-align: left;
  transition: background 0.3s ease;
}

.mobile-nav-slide .first-menu {
  margin: 0 !important;
}

.topnav {
  z-index: 1000;
  position: absolute;
  left: 0;
  display: none;
  width: 100%;
  min-width: 150px;
  font-family: "Roboto Condensed", sans-serif;
  text-align: left;
  background: #d40029;
  box-shadow: 0 5px 5px rgb(0 0 0 / 25%);
}

.topnav ul {
  position: static;
  margin: 0 !important;
  padding: 0;
  border: 0;
  background: 0;
}

.topnav ul li {
  position: static;
  display: block;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1em;
}

.topnav ul li a {
  display: block;
  margin: 0;
  padding: 15px 0 15px 20px;
  color: #000;
  border-bottom: 1px solid #000;
  transition: all 0.3s ease;
  font-weight: bold;
}

.topnav .dropdown {
  display: block;
  background: rgba(0, 0, 0, 0.95);
}

.topnav .dropdown .home-container {
  padding: 0;
  max-width: 100%;
}

.topnav .dropdown .dropdown-nav {
  float: none;
  width: 100%;
}

.topnav ul li ul li {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1em;
  width: 25%;
}

.topnav ul li ul li a {
  padding: 10px 0 10px 30px;
  font-family: "Roboto", sans-serif;
  text-transform: none;
}

ul li {
  list-style: disc outside;
}

.topnav ul li a:hover {
  color: #fff;
  text-decoration: none;
}

.mobile-nav-slide.active {
  right: 0;
}

#content-wrapper {
  position: relative;
  justify-self: flex-end;
  background-color: white;
}

#page-wrapper {
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  transition: max-width 0.3s ease;
}

header > .yo-local .local-nav {
  display: none;
}

header .head-wrap .logo img {
  max-width: 100%;
  width: 144px;
}

header .head-wrap .header-top-wrap .header-top .anchors {
  display: none;
}

header .head-wrap .header-top-wrap .header-top .header-phone .yo-local {
  display: none;
}

.topnav .dropdown .header {
  display: none;
}

.sticky-wrapper {
  height: 124px;
}

.sticky-wrapper.is-sticky {
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.yo-corporate {
  width: 125px;
  font-size: 10px;
  font-weight: 800;
}

.yo-corporate img {
  height: 10px;
}

header .head-wrap .header-top-wrap .header-top .header-phone div svg {
  height: 11px;
  width: auto;
  fill: #d40029;
  margin-right: 5px;
}

.rotate-up svg {
  transform: rotate(180deg);
}

.number-dropdown-container ul,
.number-dropdown-container li {
  margin: 0 !important;
  padding: 0;
}

.number-dropdown li {
  list-style-type: none;
  padding: 10px;
}

.open {
  display: block;
}

.close {
  display: none;
}

.number-dropdown-container {
  position: relative;
  text-align: left;
  display: flex;
  justify-content: end;
}

.number-dropdown-container a,
.number-dropdown-container li {
  cursor: pointer;
}

.number-dropdown-container a {
  color: #000;
  margin-right: 5px;
}

.number-dropdown {
  position: absolute;
  top: 40px;
  right: -20px;
  padding: 10px 16px !important;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 100;
  min-width: 500px;
}

.number-dropdown li:hover {
  background: #eaf0f1;
}

.selected-list-item {
  background: #eaf0f1;
}

#dropdown-arrow {
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-left: 5px;
  color: #d40029;
  font-weight: bold;
}

.display-selected-value p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.7rem;
}

.selected-city-name {
  color: #000;
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .number-dropdown {
    min-width: 60vw;
  }
}

@media only screen and (max-width: 1200) {
  .display-selected-value a p {
    display: block;
    font-size: 0.7rem;
  }

  .display-selected-value p {
    font-size: 0.7rem;
  }

  .selected-city-name {
    color: #000;
  }
}

@media only screen and (min-width: 800px) {
  header .head-wrap .header-top-wrap .header-top .header-phone a {
    top: 2px;
  }

  header .yo-local {
    text-align: right;
  }

  header .yo-local .name {
    margin-right: 0;
    padding-right: 15px;
    border-right: 1px solid #fff;
  }

  header .yo-local .local-nav {
    display: inline-block;
    color: #fff;
    text-decoration: none;
  }

  header .head-wrap .header-top-wrap {
    display: block;
  }

  header .head-wrap .header-top-wrap .header-top {
    border-bottom: 1px solid #efeff0;
  }

  header .head-wrap .header-top-wrap .header-top {
    padding: 10px 20px 6px;
    width: 607px;
    display: flex;
    justify-content: space-between;
  }

  .header-top-container {
    display: flex;
    justify-content: end;
  }

  header .head-wrap .header-top-wrap .header-top .anchors {
    display: inline;
  }
  header .head-wrap .header-top-wrap .header-top .anchors a {
    display: inline;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone {
    font-size: 1.375rem !important;
    line-height: 1em;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone div img {
    height: 17px;
    margin-bottom: -2px;
  }

  #mobile-nav {
    padding: 8px 10px;
    margin: 0;
    margin-right: 20px;
    text-align: right;
  }

  .twelve .col-dd-3 {
    width: 25% !important;
  }

  .twelve .col-dd-9 {
    width: 75% !important;
  }

  .home-container {
    padding: 0 20px;
  }

  header #alert-bar p {
    font-size: 0.9em;
  }

  header .yo-local {
    text-align: right;
  }

  header .head-wrap .logo img {
    width: 235px !important;
    height: 102px !important;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone div svg {
    height: 17px;
    margin-bottom: -2px;
  }

  .display-selected-value p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 799px) {
  header .head-wrap .logo img {
    width: 144px !important;
    height: 100%;
  }

  .number-dropdown-container a {
    font-size: 1rem;
  }
  .number-dropdown li {
    font-size: 1rem;
  }
  .number-dropdown {
    right: -30px;
    overflow-y: scroll;
    max-height: 300px;
    min-width: 90vw;
  }
}

@media only screen and (min-width: 600px) {
  .mobile-nav-slide {
    right: -300px;
    width: 300px;
  }

  .mobile-nav-slide ul li .dropdown-wrap {
    right: -300px;
    width: 300px;
  }

  .twelve .col-md-12 {
    width: 100%;
  }

  header #alert-bar p {
    font-size: 0.8em;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone {
    font-size: 1em;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone div img {
    height: 14px;
  }

  .sticky-wrapper {
    height: 168px;
  }

  .sticky-wrapper.is-sticky {
    width: 100%;
    position: fixed;
    z-index: 1000 !important;
  }

  .yo-corporate {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
  }

  .yo-corporate img {
    height: 17px;
  }

  .mobile-nav-slide ul li.has-dropdown > i.fa-solid {
    right: 100px;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone div svg {
    height: 14px;
  }
}

@media only screen and (max-width: 600px) {
  header .head-wrap .header-top-wrap .header-top .header-phone > div {
    margin: 14px 20px;
    width: auto;
    position: relative;
    top: 4px;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone div svg {
    height: 17px;
  }

  header .head-wrap .header-top-wrap .header-top .header-phone a {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1040px) {
  .mobile-nav-slide {
    display: none;
  }

  header #alert-bar p {
    font-size: 1em;
  }

  header .head-wrap .logo img {
    width: 235px !important;
    height: 102px !important;
   

    margin-bottom: -100px;
    margin-top: -10px;
    vertical-align: top;
  }

  #mobile-nav {
    display: none;
  }

  .topnav {
    position: static;
    display: inline-block !important;
    width: auto !important;
    max-height: 60px;
    margin-top: 10px;
    padding: 0;
    background: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .topnav ul {
    margin: 0 !important;
    padding-left: 10px;
    text-align: left;
  }

  .topnav ul li {
    display: inline-block;
    margin: 0;
    margin-right: -4px;
    font-weight: 400;
    border: 0;
    background: 0;
    font-size: 0.95rem;
    line-height: 1em;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .topnav ul li a {
    display: block;
    padding: 20px 15px;
    color: #000;
    border-bottom: 0;
  }

  .topnav ul li .dropdown {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    padding: 1vw 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.85);
    transition: opacity 0.3s ease;
  }

  .topnav ul li .dropdown .home-container {
    display: flex;
    align-items: center;
    max-width: 1240px;
  }

  .topnav ul li .dropdown .header {
    display: block;
    font-family: "Roboto Slab", serif;
    font-size: 1.75rem;
    line-height: 1em;
  }

  .topnav ul li .dropdown .header a {
    border-bottom: 0;
    background: 0;
  }

  .topnav ul li ul {
    z-index: 996;
    width: auto;
    margin: 0;
    padding: 0;
    text-align: left !important;
    overflow: auto;
  }

  .topnav ul li ul li {
    display: inline-block;
    height: auto;
    margin: 0;
    padding: 0 !important;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-size: 0.9375rem;
    line-height: 1em;
  }

  .topnav ul li .dropdown .dropdown-nav a {
    text-align: left;
    border-bottom: 0;
    background: 0;
  }

  .topnav ul li ul li a {
    height: auto;
    margin: 0;
    padding: 15px 30px !important;
    color: #fff;
    text-transform: none;
    background: 0;
    position: relative;
  }

  .topnav ul li .dropdown .header a:hover {
    color: #fff;
    text-decoration: underline;
  }

  .topnav ul li .dropdown .header a {
    border-bottom: 0;
    background: 0;
  }

  .topnav ul li a {
    display: block;
    padding: 20px 15px;
    color: #000;
    border-bottom: 0;
  }

  .topnav ul li:hover .dropdown {
    top: 100%;
    opacity: 1;
  }

  .topnav ul li .dropdown {
    position: absolute;
    top: -140%;
    left: 0;
    width: 100%;
    padding: 1vw 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.85);
    -moz-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .topnav ul li:hover a {
    color: #fff;
    background: #000;
  }

  .topnav ul li .dropdown .dropdown-nav a:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media only screen and (min-width: 1300px) {
  .home-container {
    max-width: 1240px;
  }
  header .head-wrap .header-top-wrap .header-top .anchors a {
    display: inline;
  }
}
