.red-bg {
  background-color: #d40029;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  text-align: center;
  padding-top: 20px;
}
.header > h3 {
  color: #fff;
}
.row1,
.row2 {
  display: flex;
}

.fieldNames {
  color: #fff;
}

.inputField {
  width: 262px;
  padding-right: 20px;
  /* border-radius: 2%; */
  border-style: solid;
  border-width: 1px;
  border-color: #bbbbbb;
  background-color: #ffffff;
  color: #959595;
  border-radius: 5px;
  box-shadow: inset 0px 2px 3px #dddddd;
  -webkit-box-shadow: inset 0px 2px 3px #dddddd;
  height: 28px;
  font-size: 11px;
  line-height: 11px;
  padding-left: 7px;
  padding-right: 7px;
}

.fields {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSubmit {
  margin-top: 20px;
  margin-bottom: 20px;

  border-style: none;
  border-radius: 5px;
  /* left: 235px;
    top: 134px;
    z-index: 34; */
  width: 400px;
  height: 35px;
  /* position: absolute; */
  background: rgba(204, 204, 204, 1);
  /* box-shadow: none;
    text-shadow: none; */
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
  /* font-family: Montserrat;
    font-style: normal; */
  text-align: center;
  padding: 5px;
}

.logo-div {
  margin-top: 20px;
  width: 400px;
  height: 150px;
}

.main-cont {
  display: flex;
  padding-top: 20px;
}

.about-us,
.location {
  margin-top: 20px;
  width: 30%;
  color: #fff;
  margin-left: 50px;
}

.about-us > h3,
.location > h3 {
  color: #fff;
}

.location > span {
  display: inline-block;
  width: 60%;
}

.location :nth-child(3) {
  margin-top: 20px;
}

.termsandcondition {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
}

input[type="text"] {
  font-size: 17px;
  color: #000;
}
input[type="email"] {
  font-size: 17px;
  color: #000;
}

input[type="tel"] {
  font-size: 17px;
  color: #000;
}
@media only screen and (max-width: 600px) {
  .row1,
  .row2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fields {
    margin-right: 0;
    width: 100%;
  }
  .btnSubmit {
    width: auto;
  }

  .inputField {
    width: auto;
  }

  .main-cont {
    display: inline-block;
  }

  .logo-div {
    margin-top: 0;
    width: auto;
    height: auto;
  }

  .about-us,
  .location {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .termsandcondition {
    font-size: 10px;
  }
}

.error-field{
  color: #fff;
}

.row2{
  margin-top: 20px;
}