.breadcrumb {
  background: #fff;
  margin: 0;
  /* commented this to eliminate space above and below franchise home breadcrumbs */
  /* padding: 40px 0 !important; */
  padding: 20px 0 30px;
  display: block;
  position: relative;
  z-index: 1;
}

.breadcrumb ul {
  margin: 0 !important;
  padding: 0;
  line-height: 0;
}

.breadcrumb ul li {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  color: #63666a;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2em;
}

.breadcrumb ul li a,
.breadcrumb ul li span {
  display: inline-block;
  /* margin: 0 5px 0 8px; */
  margin: 0 0px 0 5px;
}

.breadcrumb ul li a {
  text-decoration: none;
  color: #63666a;
  font-weight: 400;
}

.breadcrumb-container {
  width: 100%;
  max-width: 1200px;
  /* margin: 0 auto; */
  /* padding: 0 15px; */
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

.city-detailpage-container .breadcrumb-container a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 800px) {
  .breadcrumb-container {
    padding: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .breadcrumb-container {
    max-width: 1240px;
  }
}

.city-detailpage-container .breadcrumb {
  margin-left: 9px;
}

@media only screen and (min-width: 800px) {
  .city-detailpage-container .breadcrumb {
    padding: 40px 0 40px;
    margin-left: -4px;
  }
}
