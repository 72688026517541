/* New Image Tray CSS  Start*/
.image-tray-container {
  display: flex;
  align-items: center;
}

.image-tray-container img {
  object-fit: cover;
  height: 100%;
}

.tray-img {
  align-self: stretch;
}

.tray-text-left {
  padding-left: 40px;
  padding-top: 30px;
}

.tray-text-right {
  padding-right: 40px;
  padding-top:30px;
}

@media only screen and (min-width: 800px) {
  .tray-text-left {
    padding: 0 50px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .image-tray-container {
    flex-direction: column;
  }

  .tray-text-left {
    /* padding:0 20px; */
    padding: 0;
  }

  .tray-text-left p {
    padding: 0;
  }

  .tray-text-right {
    padding: 0;
  }

  .tray-text-right p {
    padding: 0;
  }
}

@media only screen and (max-width: 799px) {
  .img-copy-tray .container {
    width: 90% !important;
  }
}

/* New Image Tray CSS  End*/
