.whychooseuss-body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

.whychooseuss-body-wrap .content-section .row {
    margin-bottom: 40px;
    margin-right: 20px;
}

.whychooseuss-body-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.whychooseuss-body-wrap #content .craftspersons .left-col .splash {
    display: none;
    margin-bottom: 30px;
}

.whychooseuss-body-wrap .clearfix:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.whychooseuss-body-wrap #content .craftspersons .left-col .splash img {
    float: right;
    max-width: 140%;
}

.whychooseuss-body-wrap img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.whychooseuss-body-wrap img, .whychooseuss-body-wrap iframe, .whychooseuss-body-wrap object {
    vertical-align: middle;
}

.whychooseuss-body-wrap h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;

    color: #000;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

.whychooseuss-body-wrap h3, .whychooseuss-body-wrap p, 
.whychooseuss-body-wrap ul, .whychooseuss-body-wrap ol {
    margin: 0 0 20px;
}

.whychooseuss-body-wrap ul {
    display:flex;
    flex-wrap: wrap;
    margin:0 !important;
}

.whychooseuss-body-wrap #content .craftspersons .right-col ul {
    font-weight: 700;
    color: #000;
    margin-bottom: 20px !important;
    line-height: 1.875em;
}

.whychooseuss-body-wrap ul.accent-sq, .whychooseuss-body-wrap .accordion ul {
    margin: 20px 0;
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
}

.whychooseuss-body-wrap ul, .whychooseuss-body-wrap ol {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;
}

.whychooseuss-body-wrap .twelve .col-sd-6 {
    width: 50%;
}

.whychooseuss-body-wrap ul.accent-sq li, .whychooseuss-body-wrap .accordion ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
}

.whychooseuss-body-wrap ul li {
    list-style: none;
    /* flex:0 0 31%; */
}

.whychooseuss-body-wrap ul li::before {
    content: "● ";
    color:rgb(212, 0, 41);
    padding:0 10px 0 0;
}
.whychooseuss-body-wrap ul.accent-sq li::before, .whychooseuss-body-wrap .accordion ul li::before {
    content: "● ";
    position: absolute;
    margin-left: -20px;
    color: rgb(212, 0, 41);
}

/* @media only screen and (max-width: 800px){
    .whychooseuss-body-wrap ul li {
        flex: 0 0 50%;
    }
} */

@media only screen and (min-width: 600px){
    .whychooseuss-body-wrap img {
        width: auto;
    }
}

@media only screen and (min-width: 800px){
    .whychooseuss-body-wrap .content-section .row {
        margin-bottom: 60px;
        margin-right: 0;
    }

    .whychooseuss-body-wrap .container {
        padding: 0 20px;
    }

    .whychooseuss-body-wrap .twelve .col-dd-5 {
        width: 41.66%;
    }

    .whychooseuss-body-wrap #content .craftspersons .left-col .splash {
        display: block;
    }

    .whychooseuss-body-wrap #content .pad-left-lg {
        padding-left: 60px;
    }

    .whychooseuss-body-wrap .twelve .col-dd-7 {
        /* changed from width: 58.33% */
        width: 53.33%;
    }

    .whychooseuss-body-wrap h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }

    .whychooseuss-body-wrap .twelve .col-dd-4 {
        /* changed from width: 33.33% */
        width: 23.33%;
    }
}

@media only screen and (min-width: 1300px){
    .whychooseuss-body-wrap .container {
        max-width: 1240px;
    }
}