#pro-services-content .main-content {
  position: relative;
  margin: 40px 0 0 0;
}

html,
body,
div {
  padding-top: 0.001em;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#pro-services-content .main-content > .professional-service-container {
  z-index: 1;
}

#pro-services-content .content-section .row {
  margin-bottom: 40px;
}

#pro-services-content .professional-service-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 15px; */
  padding: 0;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

.professional-service-container .call-us {
  margin-top: 10px;
}

.professional-service-container .call-us p {
  font-weight: 700;
}

.professional-service-container .phone {
  font-size: 22px;
}

.professional-service-container .phone svg {
  height: 16px;
  margin-right: 5px;
  margin-left: 3px;
}

.professional-service-container .phone a {
  color: #d40029;
  text-decoration: none;
}

.professional-service-container .phone a:hover {
  text-decoration: underline;
}

.row:before,
.row:after {
  content: "";
  display: table;
  clear: both;
}

[class*="col-"] {
  float: left;
  vertical-align: top;
  width: 100%;
  min-height: 1px;
}

#pro-services-content .professional-service-container .truncate h1 {
  margin-top: -22px;
  margin-bottom: -30px;
}

#pro-services-content .professional-service-container .truncate .seo-heading {
  font-size: 2rem !important;
  margin-top: -10px !important;
}

/* hides image */
/* #content .main-content .float-container {
    display: none;
    padding: 0;
} */

#pro-services-content .main-content .float-container img.frame {
  z-index: 2;
  position: relative;
}

#pro-services-content .main-content .float-container img {
  max-width: 100%;
}

#pro-services-content img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/* img, iframe, object {
    vertical-align: middle;
} */

#pro-services-content figure,
#pro-services-content section {
  display: block;
}

#pro-services-content h1 {
  font-size: 2rem;
  line-height: 1.5em;

  color: #000;

  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

#pro-services-content h1,
#pro-services-content h2,
#pro-services-content h3,
#pro-services-content p,
#pro-services-content ul,
#pro-services-content ol,
#pro-services-content blockquote,
#pro-services-content table {
  margin: 0 0 20px;
}

#pro-services-content h2 {
  font-size: 1.75rem;
  line-height: 1.5em;

  color: #000;

  font-family: "Roboto Slab", serif;
  font-weight: 700;
  margin-bottom: 0px !important;
}

#pro-services-content ul,
#pro-services-content ol {
  margin: 20px 0 20px 20px;
  font-size: 1rem;
  line-height: 1.75em;

  list-style: none;
}

#pro-services-content ul li {
  list-style: disc outside;
}

.truncate.custom-seo-headings .truncate-span {
  /* padding: 0 20px; */
  padding: 0;
}

.truncate .truncate-span {
  padding: 0 20px;
}

#pro-services-content p {
  padding: 0;
}

.truncate.custom-seo-headings {
  margin-bottom: -50px;
}

.truncate p a {
  color: #d40029;
  text-decoration: underline;
}

.professional-service-container.row .col-dd-12 {
  margin-top: 0px;
}


.custom-seo-headings h1 {
  margin-bottom: 15px !important;
  /* line-height: 1em !important;
  font-size: 2.25rem !important; */
  line-height: 1.25em !important;
  font-size: 1.25rem !important;
}

.custom-seo-headings h2 {
  margin-bottom: 15px !important;
  line-height: 1em !important;
  font-size: 2rem !important;
}
#pro-services-content .bookbtn{
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  font-size: .9375rem;
  line-height: 1.3333333333333333em;
  font-family: 'Roboto',sans-serif;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 10px;
  font-style: normal;
  border: 0;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

/* ::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
} */

@media only screen and (min-width: 600px) {
  #pro-services-content .main-content {
    /* margin: 80px 0 0 0; */
    margin: 60px 0 0 0;
  }

  /* commneted the linear gradinet, it overlapping on schedule appointment component */
  /* #pro-services-content .main-content:before {
        content: '';
        z-index: 0;
        position: absolute;
        top: -290px;
        left: 0;
        display: block;
        width: 100%;
        height: 250px;
        
        background: -moz-linear-gradient(90deg,rgba(255,255,255,0) 0,#fff 11%,#fff 50%,rgba(255,255,255,0) 98%,rgba(255,255,255,0) 100%);
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(2%,rgba(255,255,255,0)),color-stop(50%,#fff),color-stop(89%,#fff),color-stop(100%,rgba(255,255,255,0)));
        background: -webkit-linear-gradient(90deg,rgba(255,255,255,0) 0,#fff 11%,#fff 50%,rgba(255,255,255,0) 98%,rgba(255,255,255,0) 100%);
        background: -o-linear-gradient(90deg,rgba(255,255,255,0) 0,#fff 11%,#fff 50%,rgba(255,255,255,0) 98%,rgba(255,255,255,0) 100%);
        background: -ms-linear-gradient(90deg,rgba(255,255,255,0) 0,#fff 11%,#fff 50%,rgba(255,255,255,0) 98%,rgba(255,255,255,0) 100%);
        background: linear-gradient(0deg,rgba(255,255,255,0) 0,#fff 11%,#fff 50%,rgba(255,255,255,0) 98%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ffffff',GradientType=0);
    } */

  #pro-services-content .main-content .float-container {
    position: relative;
    display: block;
    float: right;
    overflow: hidden;
    width: 50%;
    margin: 0 0 40px 40px;
  }

  #pro-services-content img {
    width: auto;
  }
}

@media only screen and (min-width: 800px) {
  #pro-services-content .professional-service-container .truncate .seo-heading {
    margin-top: -60px !important;
    font-size: 2.2rem !important;
  }

  #pro-services-content .content-section .row {
    margin-bottom: 60px;
  }

  #pro-services-content .professional-service-container {
    padding: 0 20px 0 0;
  }

  #pro-services-content .twelve .col-dd-12 {
    width: 100%;
  }

  #pro-services-content .main-content .float-container {
    width: 100%;
    max-width: 461px;
  }

  .truncate .truncate-span {
    /* padding: 0; */
    padding: 0 20px !important;
  }

  .truncate.custom-seo-headings {
    margin-bottom: 0px;
  }

  #pro-services-content h1 {
    font-size: 3rem;
    line-height: 1.3333333333333333em;
  }

  #pro-services-content h2 {
    font-size: 2.625rem;
    line-height: 1.4285714285714286em;
    margin-bottom: 5px !important;
  }

  #pro-services-content h2 span {
    margin-top: -95px;
  }

  #pro-services-content:after {
    content: "";
    display: block;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto 20px;
  }
}

@media only screen and (max-width: 800px) {
  .hide-sm {
    display: none;
  }
}
