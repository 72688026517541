/* Phone svg */
/* footer {
    width:78%;
} */
footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg {
  display: inline-block;
}
footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg img {
  width: auto;
  fill: #d40029;
  margin-right: 5px;
}

footer .foot-wrap {
  padding: 0px 0 20px;
  background: #fff;
  box-shadow: 0 -2px 3px rgb(0 0 0 / 10%);
}
footer .foot-wrap #foot-left {
  text-align: center;
}
footer .foot-wrap #foot-left a.logo {
  vertical-align: middle;
}
footer .foot-wrap #foot-left a.logo img {
  /* width: 35% !important; */
  width: 52% !important;
}
a {
  color: #d40029;
  text-decoration: none;
}
footer .foot-wrap #foot-left .call-cta {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 25px;
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}
footer .foot-wrap #foot-left .call-cta span {
  font-weight: 700;
  color: #000;
}
footer .foot-wrap #foot-left .call-cta .footer-phone {
  font-size: 1.375rem;
  line-height: 1.2727272727272727em;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div {
  color: #d40029;
  font-weight: 700;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div span,
footer .foot-wrap #foot-left .call-cta .footer-phone > div a {
  color: #d40029;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div a:hover {
  text-decoration: underline;
}
#foot-left .footer-address {
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  width: 100%;
  /* max-width: 360px; */
  max-width: 515px;
  margin: 20px 0;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}

/* added this to reduce space between the address and locally owned and independently operated text */
#foot-left .footer-address p {
  margin: 0px;
}

footer .foot-wrap #foot-mid .resources:first-child {
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .resources {
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .heading {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  color: #000;
}
footer .foot-wrap #foot-right .back-to-top-wrap svg {
  width: 13px;
  height: 20px;
}
footer .foot-wrap .copy-wrap p {
  text-align: center;
  padding: 0 10px 0 0 !important;
}
footer .foot-wrap #foot-mid .resources ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}
footer .foot-wrap #foot-mid .resources ul li {
  list-style: none;
}
footer .foot-wrap #foot-mid .resources ul a {
  color: #000 !important;
  font-size: 1.1rem;
  line-height: 2.1em;
  font-weight: 400;
}
footer .foot-wrap #foot-mid .resources ul a:hover {
  text-decoration: underline;
}
footer .foot-wrap #foot-right {
  text-align: center;
}
footer .foot-wrap #foot-right .back-to-top-wrap {
  display: inline-block;
  padding: 6px;
  color: #000;
  text-align: center;
  cursor: pointer;
  border: 1px solid #000;
}
footer .foot-wrap #foot-right .back-to-top-wrap:hover {
  border: 1px solid #d40029;
}
footer .foot-wrap #foot-right .back-to-top-wrap a:hover {
  color: #d40029;
}
footer .foot-wrap #foot-right .back-to-top-wrap a {
  color: #000;
}
/* #arrowUp .st0 {
    clip-path: url(#arrowUpclip);
    fill: currentColor;
} */
footer .foot-wrap #foot-right .back-to-top-wrap span {
  display: block;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.5625rem;
  line-height: 1.3333333333333333em;
}
.twelve .col-sd-12 {
  width: 100%;
}
footer .foot-wrap .copy-wrap {
  margin-top: 20px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.7em;
}
footer .foot-wrap .social-wrap #social-wrap {
  line-height: 1;
  text-align: center;
}
#social-wrap ul.social-links {
  display: block;
  margin: 0 15px 0 0;
  list-style: none;
}
#social-wrap ul.social-links li.social {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  text-align: center;
}
#social-wrap ul.social-links li.social a {
  display: block;
  width: 30px;
  height: 30px;
  color: #b1b2b4;
  /* vertical-align: top; */
  font-size: 0;
  transition: all 0.3s ease;
}
#social-wrap ul.social-links li.social a i {
  vertical-align: middle;
}
#social-wrap ul.social-links li.social a i:hover {
  color: #d40029;
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
.fab {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
#social-wrap ul.social-links li.social a i:before {
  font-size: 1.5rem;
  line-height: 1em;
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-linkedin:before {
  content: "\f08c";
}
/* .ff-cta-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #000;
} */
.footer-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}
/* .ff-cta-wrap .footer-container .wrap-center {
    float: none;
    margin: 0 auto;
} */
/* .ff-cta-wrap .footer-container .wrap-center .cta-left {
    color: #fff;
    font-size: .9375rem;
    line-height: 1.8666666666666667em;
} */
/* .ff-cta-wrap .footer-container .wrap-center .cta-left span {
    display: block;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    font-size: 1.4375rem;
    line-height: 1.2173913043478262em;
} */

footer .lf-cta-wrap {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #000;
}

footer .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

footer .lf-cta-wrap .container .wrap-center {
  float: none;
  margin: 0 auto;
  margin-left: 13px;
}

footer .lf-cta-wrap .container .wrap-center .cta-left {
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.8666666666666667em;
}

footer .lf-cta-wrap .container .wrap-center .cta-left p span {
  font-size: 0.9375rem;
  line-height: 1.8666666666666667em;
}

footer .twelve .col-sd-12 {
  width: 100%;
}

footer span {
  padding: 0 !important;
}

footer p {
  padding: 0 !important;
}

footer .lf-cta-wrap .container .wrap-center .cta-left span {
  /* display: block;
    font-weight: 700;
    font-family: 'Roboto Slab',serif; */
  font-size: 1.4375rem;
  line-height: 1.2173913043478262em;

  color: #fff;
}

footer .lf-cta-wrap .container .wrap-center .cta-left h3 span {
  display: block;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  font-size: 1.4375rem;
  line-height: 1.2173913043478262em;
}

/* added these 2 to reduce space between the h3 and p */
footer .lf-cta-wrap .container .wrap-center .cta-left h3 {
  margin: 0;
}

footer .lf-cta-wrap .container .wrap-center .cta-left p {
  margin: 0;
}

footer .lf-cta-wrap .container .wrap-center .cta-right form {
  color: #fff;
  font-size: 0;

  /* this was just for form alone */
  position: relative;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .input {
  display: inline-block;
  width: calc(100% - 115px);
  vertical-align: top;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .input input {
  margin-top: 5px;
  font-size: 0.9375rem;
  line-height: 1.4666666666666666em;

  width: 100%;
  margin: 0 0 1px;
  padding: 15px;
  outline: 0;
  border: 0;
  background: #efeff0;
  color: #8a8c8f;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-style: normal;
  font-weight: normal;
  /* font-size: .9375rem;
    line-height: 2.1333333333333333em; */
  font-family: "Roboto Condensed", sans-serif;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .error input {
  color: red !important;
  background: #fee !important;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .search {
  display: inline-block;
  width: 90px;
  vertical-align: top;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .search button {
  width: 100%;
  margin-top: 0;
  font-size: 0.9375rem;
  line-height: 1.4666666666666666em;
}

footer .submit {
  /* width: 100%; */
  padding: 15px 20px;
  /* font-size: .875rem;
    line-height: 1em; */
}

footer .btn {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  /* font-size: .9375rem;
    line-height: 1.3333333333333333em; */
  font-family: "Roboto", sans-serif;
  /* padding: 15px 30px; */
  cursor: pointer;
  /* margin-top: 10px; */
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

footer form .no-results {
  z-index: 2;
  position: absolute;
  left: 0;
  display: none;
  padding: 20px 30px 30px;
  color: #fff;
  text-align: center;
  background: #000;
  font-size: 1rem;
  line-height: 2em;
  -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}

footer form .no-results .close {
  cursor: pointer;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  font-weight: 700;
  color: #fff;
  background: #d40029;
  font-size: 1rem;
  line-height: 2.5em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}

footer form .no-results a {
  font-weight: bold;
  display: block;
  background: #191919;
  padding: 15px;
  margin-top: 10px;
  color: #fff;

  text-decoration: none;
}

footer .lf-cta-wrap .container .wrap-center .cta-right .instructions {
  color: #d6d6d7;

  font-size: 0.75rem;
  line-height: 2.1666666666666665em;
  /* color: #63666a; */
  opacity: 1;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.yo-corporate.footer {
  text-align: center;
  font-size: 20px;
  width: 100%;
}

.yo-corporate.footer img {
  height: 18px;
}

footer .foot-wrap #foot-mid {
  text-align: center;
  margin-top: 25px;
}

footer .foot-wrap #foot-mid .heading {
  font-size: 1.2rem;
}

footer .foot-wrap #foot-left .call-cta .footer-phone .yo-local {
  display: none;
}

footer .foot-wrap #foot-mid .payment ul {
  margin: 0 !important;
  padding: 0;
}
footer .foot-wrap #foot-mid .payment ul li {
  list-style: none !important;
  display: inline-block;
  margin-right: 10px;
  font-size: 0.75rem;
  line-height: 1em;
}
footer .foot-wrap #foot-mid .payment ul li span:first-of-type {
  color: #b1b2b4;
  display: inline-block;
  margin-right: 5px;
}

footer .foot-wrap #foot-mid .payment ul li span > p {
  display: inline-block !important;
  margin: 0;
}
.ff-phone svg {
  width: 16px;
  margin-right: 5px;
  fill: #d40029;
}

.link-margin a {
  display: inline-block;
  margin: 0 3px;
}

footer .foot-wrap #foot-left .line-separator {
  margin: 0.75em 0;
  background: #ebebeb;
  height: 1px;
}

@media only screen and (min-width: 1300px) {
  footer .container {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 800px) {
  /* footer {
        width: 100%;
    } */
  footer .foot-wrap #foot-mid .resources {
    margin-left: 80px !important;
  }
  footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg img {
    height: 17px;
    /* margin-bottom: -2px; */
  }
  footer .foot-wrap #foot-mid .resources1 .heading {
    margin-bottom: 31px;
  }
  footer .foot-wrap #foot-mid .payment {
    margin: 20px 0 0 -55px !important;
  }
  footer .foot-wrap {
    padding: 60px 0 80px;
  }
  footer .foot-wrap #foot-left {
    margin-bottom: 0;
  }
  .twelve .col-dd-5 {
    width: 41.66% !important;
  }
  footer .foot-wrap #foot-left a.logo {
    display: block !important;
  }
  footer .foot-wrap #foot-left a.logo img {
    /* width: 180px !important; */
    width: 100% !important;
    margin: auto;
  }
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 20px 0 0 0 !important;
    padding: 20px 0 0 0 !important;
    border-top: 1px solid #000;
    border-left: 0 !important;
  }
  .twelve .colm-dd-6 {
    width: 50% !important;
  }
  .twelve .col-dd-1 {
    width: 8.33%;
  }
  footer .foot-wrap #foot-bottom {
    margin-top: 0px;
    display: flex;
    align-items: flex-end;
  }
  footer .foot-wrap .copy-wrap {
    position: relative;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1.5em;
  }
  .twelve .col-dd-9 {
    width: 75%;
  }

  .twelve .col-dd-12 {
    width: 100%;
  }
  footer .foot-wrap .copy-wrap p {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
  }
  footer .foot-wrap .copy-wrap span {
    display: inline-block;
  }
  footer .foot-wrap .copy-wrap span.divider {
    display: inline-block;
  }
  footer .foot-wrap .copy-wrap a {
    color: #63666a;
    font-weight: 100;
  }
  footer .foot-wrap .copy-wrap a:hover {
    text-decoration: underline;
  }
  footer .foot-wrap .copy-wrap p {
    text-align: left;
  }
  footer .foot-wrap .social-wrap {
    /* margin: 0; */
    /* added margin-top to move social media icons lower so that it doesn't collide with footer text */
    margin-bottom: -40px;
    position: relative;
    text-align: right;
  }
  .twelve .col-dd-3 {
    width: 25%;
  }
  #social-wrap {
    text-align: right;
  }
  .ff-cta-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .footer-container {
    padding: 0 20px;
  }
  .twelve .col-dd-8 {
    width: 66.66%;
  }
  /* .ff-cta-wrap .footer-container .wrap-center .cta-left {
        padding-right: 20px;
        font-size: 1.125rem;
        line-height: 1.4444444444444444em;
    } */
  .twelve .col-dd-6 {
    width: 50% !important;
  }
  /* .ff-cta-wrap .footer-container .wrap-center .cta-left span {
        font-size: 1.625rem;
        line-height: 1.3846153846153846em;
    } */
  .twelve .colm-dd-6 {
    width: 180% !important;
    margin-top: -80px;
  }

  footer .lf-cta-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  footer .container {
    padding: 0 20px;
  }

  footer .twelve .col-dd-8 {
    width: 66.66%;
  }

  footer .lf-cta-wrap .container .wrap-center .cta-left {
    /* padding-right: 20px; */
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
  }

  footer .lf-cta-wrap .container .wrap-center .cta-left p span {
    padding-right: 20px;
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
  }

  footer .lf-cta-wrap .container .wrap-center .cta-left h3 span {
    font-size: 1.625rem;
    line-height: 1.3846153846153846em;
  }

  /* added these 2 to reduce space between the h3 and p */
  footer .lf-cta-wrap .container .wrap-center .cta-left h3 {
    margin: 0;
  }

  footer .lf-cta-wrap .container .wrap-center .cta-left p {
    margin: 0;
  }

  footer .twelve .col-dd-6 {
    width: 50%;
    padding-left: 20px !important;
  }

  footer .lf-cta-wrap .container .wrap-center .cta-left span {
    font-size: 1.625rem;
    line-height: 1.3846153846153846em;
  }

  .franchise-links-line {
    display: flex;
    flex-direction: row;
    /* width: 120%; */
    width: 125%;
  }

  footer .foot-wrap #foot-mid .line-separator {
    display: block;
    margin: 0.5em 0;
    background: #ebebeb;
    height: 1px;
  }

  footer .foot-wrap #foot-mid .pay-online-btn {
    margin-top: 15px;
    margin-left: 0;
    text-align: center;
    display: inline-block;
  }
}

footer .foot-wrap #foot-left {
  margin-top: 0px;
}

footer .foot-wrap #foot-right {
  /* To adjust footer mid content */
  margin-top: 0px;
}

@media screen and (min-width: 54.375em) {
  footer .foot-wrap {
    padding: 40px 0 20px;
  }
  footer .foot-wrap #foot-right {
    /* margin-top: 45px; */
    margin-top: 0;
  }
  footer .foot-wrap #foot-left {
    /* margin-top: 45px; */
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  #foot-left .footer-address {
    margin: 20px auto 0;
  }

  footer .foot-wrap #foot-right {
    position: absolute;
    right: 10%;
    text-align: right;
  }

  footer .foot-wrap #foot-left {
    margin-top: 25px;
  }

  footer .foot-wrap #foot-right {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1300px) {
  #foot-left .footer-address {
    margin: 20px -30px 0 !important;
  }

  footer .foot-wrap #foot-left a.logo {
    display: inline-block !important;
  }
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 0 0 0 10px !important;
    padding: 0 0 0 20px !important;
    border-top: 0 !important;
    border-left: 1px solid #000 !important;
    text-align: left;
  }
  footer .foot-wrap #foot-left {
    text-align: left;
    margin-bottom: 20px;
  }
  footer .franchise-footer-wrap #foot-left {
    padding-left: 20px;
  }

  footer .foot-wrap #foot-mid .resources1 .heading {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 800px) {
  #foot-left .footer-address {
    /* margin: 20px -30px 0; */
    margin: 20px 0px 0;
  }
}

@media only screen and (min-width: 600px) {
  /* #foot-left .footer-address {
        margin: 20px auto 0;
    } */
  .twelve .col-md-10 {
    width: 83.33%;
  }
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-left: 1px solid #000;
  }
  footer .foot-wrap #foot-left a.logo {
    display: inline-block;
  }
  footer .foot-wrap #foot-left {
    margin-bottom: 20px;

    margin-left: 0px;
  }
  footer .foot-wrap #foot-left a.logo img {
    display: block;
    width: auto !important;
    height: 70px;
  }
  footer .foot-wrap #foot-mid {
    color: #000;
    /* width: 80%; */
    width: 83%;
    text-align: left;
    margin-top: 0;
    /* padding-left: 50px; */
    padding-left: 100px;
  }
  footer .foot-wrap #foot-mid .resources {
    margin-left: 50px;
  }
  footer .foot-wrap #foot-mid .heading {
    font-weight: 700;
    font-size: 1rem;
    line-height: 2em;
  }
  footer .foot-wrap #foot-mid .payment {
    margin: 20px 0 0 -75px;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap {
    padding: 11px;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap svg {
    width: 20px;
    height: 26px;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap span {
    font-size: 0.7rem;
    line-height: 1.3333333333333333em;
  }
}

@media only screen and (min-width: 980px) {
  /* footer .foot-wrap #foot-left a.logo {
        display: inline-block !important;
    }
    footer .foot-wrap #foot-left .call-cta {
        display: inline-block;
        margin: 0 0 0 -20px !important;
        padding: 0 0 0 20px !important;
        border-top: 0 !important;
        border-left: 1px solid #000 !important;
        text-align: left;
    }   
    footer .foot-wrap #foot-left {
        text-align: left;
        margin-bottom: 20px;
        
    }
    footer .franchise-footer-wrap #foot-left {
        padding-left: 20px;
    } */
  footer .foot-wrap #foot-left a.logo {
    display: inline-block;
  }
}

@media only screen and (min-width: 1300px) {
  .footer-container {
    max-width: 1240px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 740px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 4) {
  #foot-left .footer-address {
    font-weight: 500;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    width: 100%;
    max-width: 360px;
    margin: 20px 0;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.5555555555555556em;
    padding-left: 140px;
  }
}
