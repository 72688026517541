.asw {
    margin-bottom: 80px !important;
}

.our-work-intro-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.our-work-intro-container h1 {
    font-size: 2rem;
    line-height: 1.5em;

    color: #000;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    margin: 0 0 20px;
}

.our-work-intro-container p {
    margin: 0 0 20px;
}

.our-work-intro-container a {
    font-weight: 700;

    color: #d40029;

    text-decoration: none;
}

.our-work-intro-container h2 {
    font-size: 1.75rem;
    line-height: 1.5em;

    color: #000;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    margin: 0 0 20px;
}

.our-work-intro-container ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;
}

.our-work-intro-container ul li {
    list-style: disc outside;
}




.before-after-wrap {
    z-index: 2;
    position: relative;
    padding: 40px 0;
    background: #efeff0;
    margin-bottom: 0 !important;
    -webkit-box-shadow: 0 -7px 7px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 -7px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -7px 7px rgb(0 0 0 / 20%);
    border-bottom: 1px solid #d6d6d7;
}

.before-after-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.before-after-wrap h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    margin: 0 0 20px;

    color: #000;

}

.before-after-wrap #ba-container {
    margin: 40px 0 0;
}

.before-after-slide .row {
    margin-bottom: 0;
}

.before-after-slide .image {    
    top: 0;
    opacity: 1;
    -ms-filter: "alpha(opacity=100)";
}

.before-after-slide .image:nth-child(1) {
    -moz-transition: all .3s ease-in .1s;
    -webkit-transition: all .3s ease-in .1s;
    -ms-transition: all .3s ease-in .1s;
    -o-transition: all .3s ease-in .1s;
    transition: all .3s ease-in .1s;
}

.before-after-slide .image:nth-child(2) {
    -moz-transition: all .3s ease-in .3s;
    -webkit-transition: all .3s ease-in .3s;
    -ms-transition: all .3s ease-in .3s;
    -o-transition: all .3s ease-in .3s;
    transition: all .3s ease-in .3s;
}

.before-after-slide .image {
    display: inline-block;
    float: none;
    /* opacity: 0; */
    -ms-filter: "alpha(opacity=0)";
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    padding: 10px 30px;
}

.twelve .col-sd-12 {
    width: 100%;
}

.before-after-slide .image figure {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 75px;
}

.before-after-slide .image figure img {
    width: 100%;
    height: auto;
}

img {
    width: 100%;
    height: auto;
    margin: 0 auto;

    vertical-align: middle;
}

.before-after-slide .image figure img.item.portrait {
    width: auto !important;
    height: 100%;
}

.before-after-slide .image figure img.item {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.before-after-slide .image .title {
    margin-top: 20px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto Condensed', sans-serif;
}

/* #ba-container .slick-arrow::before {
    content: "\f060";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;

    top: 40% !important;
    position: relative;
    font-size: 1.375rem;
    color: #fff;
} */

#ba-container .slick-arrow {
    cursor: pointer !important;
    z-index: 200 !important;
    position: absolute !important;
    top: 40% !important;
    height: 115px !important;
    padding: 0 30px !important;
    /* background: rgba(88, 67, 67, 0.6) !important; */
    background: rgba(0, 0, 0, 0.6) !important;
    -webkit-transform: translate(0, -70%);
    -moz-transform: translate(0, -70%);
    -ms-transform: translate(0, -70%);
    -o-transform: translate(0, -70%);
    -moz-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    -ms-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    transition: all .1s ease-in;

    border-radius: 0px;
}

#ba-container .slick-next::before {
    content: "\f061";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

#ba-container .slick-prev {
    left: 90px;
}

#ba-container .slick-prev,
#ba-container .slick-next {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#ba-container .slick-next {
    right: -90px;
}

#ba-container .slick-prev::before {
    content: "\f060";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}



.our-work-gallery-wrap {
    margin-bottom: 0 !important;
    background: #fff;
    /* padding:10px 40px 25px !important; */
    padding:10px 20px 25px !important;
}

.our-work-gallery-wrap .col-sd-12 {
    width: 100%;
}

.our-work-gallery-wrap h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;

    color: #000;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    margin: 0 0 20px;
}

.our-work-gallery-wrap #gallery-list #filters {
    margin: 20px 0;
}

.our-work-gallery-wrap #gallery-list #filters button.is-checked {
    font-weight: 900;
    color: #d40029;
}

.our-work-gallery-wrap #gallery-list #filters button:first-child {
    padding-left: 0;
    border-left: none;
}

.our-work-gallery-wrap #gallery-list #filters button {
    display: inline-block;
    padding: 2px 10px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    outline: 0;
    color: #63666a;
    border-left: 2px solid #efeff0;
    font-size: .8125rem;
    line-height: 1.5384615384615385em;
}

.our-work-gallery-wrap #gallery-list button.is-checked {
    font-weight: bold;
}

.our-work-gallery-wrap #gallery-list button {
    display: block;
    padding: 0;
    border: 0;
    background: 0;

    background: none !important;

    /* added this to disable pointer cursor when pointing at gallery category buttons */
    cursor: default;
}

.our-work-gallery-wrap #gallery-list #filters button.is-checked:after {
    width: 100%;
}

.our-work-gallery-wrap #gallery-list #filters button:after {
    content: '';
    position: relative;
    top: 10px;
    display: none;
    width: 0;
    border-bottom: 3px solid #d40029;
    -moz-transition: width .3s ease-in-out;
    -webkit-transition: width .3s ease-in-out;
    -ms-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
}

.our-work-gallery-wrap #gallery-container .image-set {
    width: 100%;
    height: 100%;
}

.our-work-gallery-wrap #gallery-container .row {
    margin-bottom: 40px;
}

.our-work-gallery-wrap #gallery-container .image {
    display: inline-block;
    float: none;
    padding: 5px;
}

.our-work-gallery-wrap #gallery-container .twelve .col-sd-6 {
    width: 50%;
}

.our-work-gallery-wrap #gallery-container .image figure {
    position: relative;
    overflow: hidden;
    margin: 0;
}

.our-work-gallery-wrap #gallery-container .image figure button {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 0;
    background: rgba(26, 26, 26, 0.8);
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    letter-spacing: 1px;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-style: normal;
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1em;
    font-family: 'Roboto', sans-serif;

    /* added this to remove the red color from the gallery images after hovering over them */
    background: none !important;
}

/* added this for dark background effect on gallery image hover */
.our-work-gallery-wrap #gallery-container .image figure button:hover {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 0;
    background: rgba(26, 26, 26, 0.8) !important;
    opacity: 1;
    -ms-filter: "alpha(opacity=0)";
    letter-spacing: 1px;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-style: normal;
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1em;
    font-family: 'Roboto', sans-serif;
}

.our-work-gallery-wrap #gallery-container .image figure button::before {
    width: 60px;
    height: 60px;
    margin: 0 auto 10px;
    padding: 15px 0 0 5px;
    border: 2px solid #fff;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f245";
    display: block;
    font-size: 1.5625rem;
    line-height: 1em;
}

.our-work-gallery-wrap #gallery-container .image figure img {
    width: 100% !important;
    height: auto;
}

.our-work-gallery-wrap #gallery-container .image figure img.item.portrait {
    width: 100%;
    height: auto;
}

.our-work-gallery-wrap #gallery-container .image figure img.item {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}



#gallery-slides figure {
    color: #FFF;
}

#gallery-slides .image figure {
    margin-bottom: 200px;
    margin-top: 130px;

    /* added this to center the text in the modal */
    text-align: center;
}

#gallery-slides .image figure img.item {
    display: block;
    width: 100%;
    max-width: 1000px;
    height: auto;
}

#gallery-slides .image figure img {
    width: auto !important;
    max-width: 80% !important;
    max-height: calc(100vh - 300px) !important;

    /* added this to center the image in the modal */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#gallery-slides figure .title {
    display: block;
    margin: 20px 0 10px;
}

#gallery-slides .image .title {
    /* margin-bottom: 30px; */
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.2857142857142858em;
    font-family: 'Roboto Slab', serif;
}

#gallery-slides figure .description {
    display: block;

            /* commented the below lines for responsive error in our work photo gallery*/
    /* padding-left: 90px;
    padding-right: 90px;

    margin-left: 60px;
    margin-right: 60px; */
}

#gallery-slides .image {
    width: 100%;
}

.our-work-gallery-wrap #gallery-container .image figure img.item.landscape {
    width: auto !important;
    height: 100%;
}

/* #gallery-slides .slick-next, #gallery-slides .slick-prev{
    transform: translate(-148%,-50%) !important;
    top: 30% !important;
} */
#gallery-slides .slick-next {
    right: 30px;
}

#gallery-slides .slick-prev {
    left: 150px;
}

#gallery-slides .slick-next::before {
    content: "\f105";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;

    font-size: 3.125rem;
}

#gallery-slides .slick-prev::before {
    content: "\f104";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;

    font-size: 3.125rem;
}

#gallery-slides .slick-arrow {
    cursor: pointer !important;
    z-index: 999 !important;
    position: absolute !important;
    font-size: 0 !important;
    /* top: 50% !important; */
    top: 38% !important;
    /* top: 64% !important; */
    overflow: hidden !important;
    width: 40px !important;
    height: 50px !important;
    font-weight: bold !important;
    color: #676869 !important;
    text-align: center !important;
    border: 0;
    outline: 0;
    background: 0;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;

    background: none !important;
}

/* #gallery-slides .slick-arrow::before {
    font-size: 45px;
    color: gray;
    display: block;
    text-align: center;
    z-index: 2;
    position: relative;
} */

/* #gallery-slides .slick-arrow {
    background: none !important;
} */

@media only screen and (min-width: 600px) {
 /* commented the below lines for responsive error in our work photo gallery*/
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 85% !important;
        /* overflow-y: visible !important; */
    }

    /* .before-after-wrap #ba-container  */
    .before-after-slide .image:nth-child(1) {
        padding-left: 0;
    }

    .before-after-slide .image figure {
        padding: 0px;
    }

    .before-after-slide .image {
        padding: 15px;
    }

    .twelve .col-md-6 {
        width: 50%;
    }

    img {
        width: auto;
    }

    .before-after-slide .image:nth-child(2) {
        padding-right: 0;
    }



    .our-work-gallery-wrap #gallery-list #filters {
        margin: 30px 0 30px;
    }

    .our-work-gallery-wrap #gallery-list #filters button {
        padding: 2px 15px;
        margin-bottom: 5px;
        font-size: 1rem;
        line-height: 1.5em;
    }

    .our-work-gallery-wrap #gallery-list #filters button:after {
        top: 0;
        display: block;
    }

    .our-work-gallery-wrap #gallery-container {
        margin: 0 -15px;
    }

    .our-work-gallery-wrap #gallery-container .image {
        padding: 15px;
    }

    .our-work-gallery-wrap #gallery-container .twelve .col-md-4 {
        width: 33.33%;
    }
}

@media only screen and (min-width: 800px) {
    .our-work-gallery-wrap {
        padding: 40px 0;
    }
}

@media only screen and (min-width: 800px) {
    /* commented the below lines for responsive error in our work photo gallery*/
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 85% !important;
        /* overflow-y: visible !important; */
    }
    
    .our-work-intro-container {
        padding: 0 20px;
    }

    .our-work-intro-container h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }

    .our-work-intro-container h2 {
        font-size: 2.625rem;
        line-height: 1.4285714285714286em;
    }

    .before-after-wrap {
        padding: 60px 0;
    }

    .before-after-wrap .container {
        padding: 0 20px;
    }

    .before-after-wrap h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }



    .our-work-gallery-wrap h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }

    .our-work-gallery-wrap #gallery-list #filters button:after {
        top: 5px;
    }

    .our-work-gallery-wrap #gallery-container .row {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 400px) {
    #gallery-slides figure .description {
        display: block;

        padding-left: 0px;
        padding-right: 0px;

        margin-left: 0px;
        margin-right: 0px;
    }

    #gallery-slides .slick-next {
         /* changed from -110px to -70px for responsive error in our work photo gallery*/
        /* right: -110px; */
        right: -70px;
    }

    #gallery-slides .slick-prev {
         /* changed from 10px to 50px for responsive error in our work photo gallery*/
        /* left: 10px; */
        left: 50px;
    }

    #gallery-slides .image figure {
        margin-bottom: 30px;
        text-align: center;
        width: 200% !important;
        margin-left: -80px;
        padding-left: 30px;
        padding-right: 30px;
    }

    #gallery-slides {
     /* changed from -60px to 0px for responsive error in our work photo gallery*/
        /* top: -60px !important; */
        top: 0px !important;
    }
}