.whychooseuslife-body-wrap {
    margin-bottom: -50px;
}

.whychooseuslife-body-wrap .st0{
    fill: currentColor;
}

.whychooseuslife-body-wrap h3 {
    padding: 0 0 0 20px;
}

.whychooseuslife-body-wrap h4 {
    padding: 0 0 0 20px;
}

/* .whychooseuslife-body-wrap {
    background: #fff url(https://www.acehandymanservices.com/images/bg-pattern-triangles.png) repeat top center;
} */

.whychooseuslife-body-wrap .content-section .row {
    margin-bottom: 40px;
}

.whychooseuslife-body-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.whychooseuslife-body-wrap #content .choose-subheading {
    margin: 0 0 3vh !important;
    font-family: 'Roboto Slab',serif !important;
    font-weight: 700;
    color: #000;
}

.whychooseuslife-body-wrap #content .choose-subheading {
    margin: 4vh 0 2vh;
}

.whychooseuslife-body-wrap #content .choose-subheading {
    /* width: 75%; */
    font-family: 'Roboto',sans-serif;
    font-size: 1.125rem;
    line-height: 1.7777777777777777em;
    margin: 0 0 4vh;
    position: relative;
    background: #FFF;
    /* padding: 30px 34px; */
    padding: 27px 32px 15px 32px;
    border: 1px solid #d6d6d7;
}

.whychooseuslife-body-wrap p, .whychooseuslife-body-wrap ul, .whychooseuslife-body-wrap blockquote {
    margin: 0 0 20px;
}

.whychooseuslife-body-wrap p {
    margin: 0 !important;
    font-size: 28px;
}

.whychooseuslife-body-wrap blockquote p {
    font-size: 16px;
    text-align: left;
    color: gray;
    font-weight: 100;
}

.whychooseuslife-body-wrap #content .reasons {
    margin: 0 !important;
    padding: 0;
    list-style: none;
    overflow: visible;
}

.whychooseuslife-body-wrap ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;
}

.whychooseuslife-body-wrap #content .reasons li {
    display: inline-block;
    float: none;
    margin: 0 0 40px;
    padding: 0 20px 0 0;
    vertical-align: middle;
    list-style: none;
    margin-left: -4px;
    color: #000;
}

.whychooseuslife-body-wrap .twelve .col-sd-12 {
    width: 100%;
}

.whychooseuslife-body-wrap ul li {
    list-style: disc outside;
}

.whychooseuslife-body-wrap #content .reasons li svg {
    display: inline-block;
    width: 20%;
    height: 70px;
    margin-right: 9%;
    color: #000;
    vertical-align: middle;
}

.whychooseuslife-body-wrap #content .reasons li img {
    margin: 15px 20px 15px 15px;
}

.whychooseuslife-body-wrap #content .reasons li span {
    display: inline-block;
    width: 60%;
    font-weight: 700;
    vertical-align: middle;
    font-size: 1.115rem;
    line-height: 1.5555555555555556em;
}

.whychooseuslife-body-wrap #content blockquote {
    font-size: 1.75rem !important;
    line-height: 1.1428571428571428em !important;
    /* margin-top: 20px !important; */
    margin-top: 25px !important; 
    /* margin-bottom: 75px !important; */
    margin-bottom: 25px !important;

    position: relative;

    color: #d40029;
    font-weight: 400;
    font-family: 'Roboto Slab',serif !important;
    font-style: normal;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
    text-align: center;
    margin: 30px 0;

    quotes: none;
    /* margin-right: 30px; */
}

.whychooseuslife-body-wrap #content blockquote cite {
    display: block;
    color: #000;
    margin-top: 10px;
    text-align: center;
    font-style: normal;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.6em;
}

.whychooseuslife-body-wrap .reasons img {
    display: inline-block;
    width: 20% !important;
    height: 70px !important;
    margin-right: 9% !important;
    vertical-align: middle;
}

.whychooseuslife-body-wrap #content .choose-subheading h2{
    font-size: 1.1rem;
    line-height: 1.75em;
    text-align: left !important;
}

.bodycopytray-cta {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff !important;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
    transition: all .3s ease;

    font-size: 1.625rem;
    line-height: 1em;

    font-weight: 400;

    text-decoration: none !important;
}

@media only screen and (min-width: 600px){
    .whychooseuslife-body-wrap .twelve .col-md-6 {
        width: 50%;
    }

    .whychooseuslife-body-wrap #content .choose-subheading h2 {
        font-size: 1.9rem;
        text-align: left !important;
        line-height: 1.5em;
    }
}

@media only screen and (min-width: 800px){
    .whychooseuslife-body-wrap #content .reasons li span {
        width: 70%;
    }

    .whychooseuslife-body-wrap h3 {
        padding: 0;
    }

    .whychooseuslife-body-wrap h4 {
        padding: 0;
    }

    .whychooseuslife-body-wrap .content-section .row {
        margin-bottom: 60px;
    }

    .whychooseuslife-body-wrap .container {
        padding: 0 20px;
    }

    .whychooseuslife-body-wrap #content .choose-subheading {
        font-size: 1.775rem;
        line-height: 1.4666666666666666em;
        /* width: 95%; */
        width: 100%;
    }

    .whychooseuslife-body-wrap #content .choose-subheading {
        margin: 4vh 0 2vh;
    }

    .whychooseuslife-body-wrap #content .reasons li {
        margin: 40px 0 40px -4px;
    }

    .whychooseuslife-body-wrap .twelve .col-dd-4 {
        /* changed from width: 31.33% */
        width: 33.33%;
    }

    
}

@media only screen and (min-width: 1300px) {
    .whychooseuslife-body-wrap .container {
        max-width: 1240px;
    }
}
