.home .most-popular-services,.services .most-popular-services {
    position: relative
}

.most-popular-services, .services .most-popular-services {
    position: relative;
}

.iphoneview {
    display:none;
}

@media only screen and (min-width: 600px){
    .most-popular-services.row {
        display: block;
        padding: 20px 0 30px;
    }

    .most-popular-services.row .heading {
        height: auto;
        margin: 0 auto 40px;
        padding: 0;
        font-size: 2.725rem !important;
        line-height: 1.4;
        color: #fff;
        background-size: 100% 100%;
    }

    /* .most-popular-services.row .heading:before {
        left: -95px !important;
    }

    .most-popular-services.row .heading:before, .most-popular-services.row .heading:after {
        width: 75px !important;
    }

    .most-popular-services.row .heading:after {
        right: -95px !important;
    } */


    .most-popular-services.row .services {
        width: 100%;
        /* display: block; */
        display: flex;
        justify-content: center;
        padding-bottom: 0 !important;
    }

    .most-popular-services.row .services .item {
        display: block;
        width: calc(95%/7);
        padding: 0;
    }
}

@media only screen and (min-width: 800px){
    .most-popular-services .row {
        margin-bottom: 60px;
    }

    .most-popular-services .most-popular-services-container {
        padding: 0 20px;
    }

    .most-popular-services.row .heading {
        font-size: 2.625rem;
    }

    .most-popular-services.row .services .item {
        width: calc(120%/7);
        font-size: 1.25rem !important;
        line-height: 1.6em !important;
    }

    .most-popular-services.row .services .item a img {
        height: 60px;
    }

    .collapsible .most-popular-services.row .services {
        display: none !important;
    }
}

@media only screen and (min-width: 1300px){
    .most-popular-services-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 1000px){
    .most-popular-services.row .services .item a img {
        height: 87px !important;
    }
}

.most-popular-services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

.most-popular-services.row {
    text-align: center;
    background: #d40029;
    margin: 0;
}

.most-popular-services .row {
    margin-bottom: 40px;
}

.most-popular-services div {
    padding-top: 0.001em;
}

.most-popular-services .most-popular-services-container, .services .most-popular-services .most-popular-services-container {
    z-index: 2;
    position: relative;
}

.most-popular-services.row .heading {
    position: relative;
    display: inline-block;
    padding: 20px 0;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    color: #fff;
    vertical-align: middle;
    background-size: 100% 100%;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

.most-popular-services .heading {
    color: #000;
}

.most-popular-services.row .heading:before {
    left: -45px;
}

/* .most-popular-services.row .heading:before, .most-popular-services.row .heading:after {
    width: 25px;
    height: 1px;
    content: '';
    background: #fff;
    position: absolute;
    top: 50%;
    z-index: 2;
} */

/* *, *:before, *:after {
    box-sizing: inherit;
} */

.most-popular-services.row .heading:after {
    right: -45px;
}

.most-popular-services.row .arrow {
    display: none;
}

.most-popular-services .fa, .most-popular-services .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.most-popular-services .fa, .most-popular-services .fas, .most-popular-services .far, .most-popular-services .fal, .most-popular-services .fad, .most-popular-services .fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.most-popular-services.row .services {
    /* display: none; */
    overflow: auto;
    padding-bottom: 20px;
    margin: 0 auto;
}

.most-popular-services.row .services .item {
    display: inline-block;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    opacity: 1;
    text-align: center;
    font-size: .8125rem;
    line-height: 2.4615384615384617em;
    -moz-transition: all .3 ease;
    -webkit-transition: all .3 ease;
    -ms-transition: all .3 ease;
    -o-transition: all .3 ease;
    transition: all .3 ease;
}

.most-popular-services .col-sd-6 {
    width: 50%;
}

.most-popular-services.row .services .item a {
    cursor: pointer;
    display: block;
    color: #fff;
    font-weight: 400;
}

.most-popular-services.row .services .item a:hover {
    text-decoration: underline;
}

.most-popular-services a {
    font-weight: 700;

    color: #d40029;

    text-decoration: none;
}

.most-popular-services.row .services .item a img {
    height: 70px;
    fill: #fff;
    margin-bottom: 10px;
}

.most-popular-services.row .services .item a span {
    display: block;
}

@media only screen and (max-width: 600px){
    .most-popular-services.row .heading {
        font-size: 1.3rem !important;
    }
    .notiphone {
        display:none;
    }

    .iphoneview {
        display: block;
        /* commented to see if most popular services gets uncropped */
        /* margin-left: 6% !important; */
    }

    /* commented to see if most popular services gets uncropped */
    /* .most-popular-services.row .iphoneview .heading {
        margin-top: -10px ;
    } */

    .most-popular-services.row .services.content.iphoneview {
        padding-top: 15px;
    }
}