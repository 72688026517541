.bathroomrrbreadcrumbs-body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs {
    background: #efeff0;
    margin: 0 !important;
    padding: 30px 0 20px;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    display: flex;
}

.bathroomrrbreadcrumbs-body-wrap .br-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 12px;
    transition: max-width .3s ease;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
    list-style: none;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul li {
    display: inline-block;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .875rem;
    line-height: 2em;

    list-style: disc outside;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul li:before {
    content: '';
    margin: 0 2px;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}

.bathroomrrbreadcrumbs-body-wrap #content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}

@media only screen and (min-width: 800px){
    /* .bathroomrrbreadcrumbs-body-wrap .br-container {
        padding: 0 20px;
    } */
}

@media only screen and (min-width: 1300px) {
    .bathroomrrbreadcrumbs-body-wrap .br-container {
        max-width: 1240px;
    }
}