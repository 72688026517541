.bathroomrrservicedetails-body-wrap {
  background-color: #fff;
  background-repeat: repeat;
  background-position: top center;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  left: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  margin: 0;
  position: relative;
  font-size: 1em;
  line-height: inherit;
  color: #63666a;
  background-color: #fff;
  -moz-transition: left 0.3s ease-out;
  -webkit-transition: left 0.3s ease-out;
  -ms-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-page .Collapsible__trigger {
  display: flex;
  justify-content: unset;
  align-items: center;
}

.home-page .Collapsible__trigger span {
  margin-left: -55px;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .heading .Collapsible__trigger {
  padding-left: 39px;
  height: 47px;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .heading .Collapsible svg {
  margin-right: 20px;
  height: 16px;
  width: 16px;
  color: #fff;
}

.bathroomrrservicedetails-body-wrap .headingiphone .content p {
  font-weight: 100;
  font-size: 16px;
  color: #63666a;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.bathroomrrservicedetails-body-wrap .service-details {
  margin-bottom: 0;
  padding: 0;
}

.bathroomrrservicedetails-body-wrap .content-section .row {
  margin-bottom: 40px;
}

.bathroomrrservicedetails-body-wrap .br-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 15px; */
  transition: max-width 0.3s ease;
}

.bathroomrrservicedetails-body-wrap .twelve .col-sd-12 {
  width: 100%;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .heading {
  position: relative;
  margin: 1px 0;
  /* padding: 10px 10%; */
  font-weight: 700;
  color: #fff;
  border: 0;
  background: #000;
  font-family: "Roboto Slab", serif;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.25rem;
  line-height: 1.4em;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .headingiphone .content {
  color: #000;
}

.bathroomrrservicedetails-body-wrap .heading {
  color: #000;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .heading .fa {
  position: absolute;
  top: 13px;
  right: 20px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bathroomrrservicedetails-body-wrap .fa,
.bathroomrrservicedetails-body-wrap .fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.bathroomrrservicedetails-body-wrap .fa,
.bathroomrrservicedetails-body-wrap .fas,
.bathroomrrservicedetails-body-wrap .far,
.bathroomrrservicedetails-body-wrap .fal,
.bathroomrrservicedetails-body-wrap .fad,
.bathroomrrservicedetails-body-wrap .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content {
  /* display: block; */
  display: none;
  padding: 20px 10%;
  background: #fff;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .headingiphone .content {
  /* display: none; */
  display: block;
  padding: 20px 10%;
  background: #fff;
}

.bathroomrrservicedetails-body-wrap p,
.bathroomrrservicedetails-body-wrap ul {
  margin: 0 0 20px;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content p span {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #d40029;
  display: block;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content p span:before {
  /* content: "\f095"; */
  margin: 0 5px;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  /* -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1); */
  font-size: 80%;
}

.bathroomrrservicedetails-body-wrap .content-section a {
  font-weight: 700;

  color: #d40029;

  text-decoration: none;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.bathroomrrservicedetails-body-wrap ul {
  margin: 20px 0 20px 20px;
  font-size: 1rem;
  line-height: 1.75em;

  list-style: none;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bathroomrrservicedetails-body-wrap ul li {
  list-style: disc outside;
}

.bathroomrrservicedetails-body-wrap .service-details .content-list .content ul li a {
  font-weight: 700;
  color: #000;
  letter-spacing: 0.3px;
}

.bathroomrrservicedetails-body-wrap .service-details .blank {
  display: none;
}

.bathroomrrservicedetails-body-wrap .service-details .content-right .accordion {
  border-bottom: 1px solid #c5c5c5;
}

.bathroomrrservicedetails-body-wrap .ui-widget {
  font-size: 1.1em;
  font-family: Verdana, Arial, sans-serif;
}

.bathroomrrservicedetails-body-wrap .ui-helper-reset {
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: 1.3;
  text-decoration: none;
  outline: 0;
  border: 0;
  list-style: none;
}

.bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .title {
  cursor: pointer;
  margin: 0;
  padding: 15px 50px 15px 10%;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #000;
  letter-spacing: 0.3px;
  outline: 0;
  border: 0;
  border-top: 1px solid #c5c5c5;
  background: #fff;
  font-size: 1.25rem;
  line-height: 1.4em;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bathroomrrservicedetails-body-wrap .ui-accordion .ui-accordion-icons {
  padding-left: 2.2em;
}

.bathroomrrservicedetails-body-wrap .ui-accordion .ui-accordion-header {
  cursor: pointer;
  position: relative;
  display: block;
  min-height: 0;
  margin: 2px 0 0 0;
  padding: 0.5em 0.5em 0.5em 0.7em;
  font-size: 100%;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-bottom,
.bathroomrrservicedetails-body-wrap .ui-corner-right,
.bathroomrrservicedetails-body-wrap .ui-corner-br {
  border-bottom-right-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-bottom,
.bathroomrrservicedetails-body-wrap .ui-corner-left,
.bathroomrrservicedetails-body-wrap .ui-corner-bl {
  border-bottom-left-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-top,
.bathroomrrservicedetails-body-wrap .ui-corner-right,
.bathroomrrservicedetails-body-wrap .ui-corner-tr {
  border-top-right-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-top,
.bathroomrrservicedetails-body-wrap .ui-corner-left,
.bathroomrrservicedetails-body-wrap .ui-corner-tl {
  border-top-left-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-state-default,
.bathroomrrservicedetails-body-wrap .ui-widget-content .ui-state-default,
.bathroomrrservicedetails-body-wrap .ui-widget-header .ui-state-default {
  font-weight: normal;
  color: #555;
  border: 1px solid #d3d3d3;
  background: #e6e6e6;
}

.bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .title .ui-icon {
  display: none;
}

.bathroomrrservicedetails-body-wrap .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  left: 0.5em;
  margin-top: -8px;
}

.bathroomrrservicedetails-body-wrap .ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.bathroomrrservicedetails-body-wrap .ui-icon {
  width: 16px;
  height: 16px;
}

.bathroomrrservicedetails-body-wrap .ui-icon {
  display: block;
  overflow: hidden;
  text-indent: -99999px;
  background-repeat: no-repeat;
}

.bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .title .fa {
  position: absolute;
  top: 33px;
  right: 20px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 700;
}

.bathroomrrservicedetails-body-wrap .fa-angle-down:before {
  content: "\f107";
}

.bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .content {
  /* padding: 0 10% 30px; */
  font-family: "Roboto", sans-serif;
  border: 0;
  font-size: 1rem;
  line-height: 2em;
  background: #fff;

  font-weight: normal;
}

.bathroomrrservicedetails-body-wrap .ui-accordion .ui-accordion-content {
  overflow: auto;
  padding: 1em 2.2em;
  border-top: 0;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-bottom,
.bathroomrrservicedetails-body-wrap .ui-corner-right,
.bathroomrrservicedetails-body-wrap .ui-corner-br {
  border-bottom-right-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-corner-all,
.bathroomrrservicedetails-body-wrap .ui-corner-bottom,
.bathroomrrservicedetails-body-wrap .ui-corner-left,
.bathroomrrservicedetails-body-wrap .ui-corner-bl {
  border-bottom-left-radius: 4px;
}

.bathroomrrservicedetails-body-wrap .ui-widget-content {
  color: #222;
  border: 1px solid #aaa;
  background: #fff;
}

.bathroomrrservicedetails-body-wrap ul.accent-sq,
.bathroomrrservicedetails-body-wrap .accordion ul {
  margin: 20px 0 !important;
  padding: 0;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  list-style: none;
}

.bathroomrrservicedetails-body-wrap ul.accent-sq li,
.bathroomrrservicedetails-body-wrap .accordion ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 3px;
  list-style: none;
}

.bathroomrrservicedetails-body-wrap ul.accent-sq li::before,
.bathroomrrservicedetails-body-wrap .accordion ul li::before {
  /* content: "â— "; */
  content: "● ";
  position: absolute;
  margin-left: -20px;
  color: rgb(212, 0, 41);
}

.bathroomrrservicedetails-body-wrap .Collapsible__trigger.is-open

/* :hover */
/* , .bathroomrrservicedetails-body-wrap .Collapsible__trigger:hover  */
  {
  background: #d40029 !important;
}

.bathroomrrservicedetails-body-wrap .accordion .Collapsible__trigger.is-open {
  background: #fff !important;
}

.bathroomrrservicedetails-body-wrap .accordion .Collapsible__trigger.is-open p {
  color: #d40029 !important;
  margin-bottom: 4px;
}

.bathroomrrservicedetails-body-wrap .Collapsible__trigger.is-open p {
  color: #000 !important;
}

.bathroomrrservicedetails-body-wrap .Collapsible__trigger.is-open p:hover {
  color: #d40029 !important;
}

.bathroomrrservicedetails-body-wrap .accordion .Collapsible__trigger.is-open svg {
  /* color: #000 !important; */
  color: #fff;
}

.bathroomrrservicedetails-body-wrap .Collapsible__trigger.is-open svg {
  /* color: #000 !important; */
  color: #fff;
}

.bathroomrrservicedetails-body-wrap .Collapsible__trigger.is-open svg:hover {
  color: #d40029 !important;
}

@media only screen and (min-width: 600px) {
  .bathroomrrservicedetails-body-wrap .content-section .row {
    margin-bottom: 40px;
  }

  .bathroomrrservicedetails-body-wrap .service-details {
    margin-bottom: 40px;
    padding: 0 10%;
  }

  .bathroomrrservicedetails-body-wrap .twelve .col-md-3 {
    width: 25%;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-list .heading {
    margin-bottom: 20px;
    padding: 0;
    color: #000;
    background: 0;
    font-size: 1.75rem;
    line-height: 1.5em;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-list .heading .fa {
    display: none;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-list .heading .fa {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 5px;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-list .content {
    display: block;
    margin-bottom: 20px;
    padding: 0;
    background: transparent;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-list .content ul {
    margin: 0 0 40px;
  }

  .bathroomrrservicedetails-body-wrap .service-details .blank {
    display: block;
  }

  .bathroomrrservicedetails-body-wrap .twelve .col-md-1 {
    width: 8.33%;
  }

  .bathroomrrservicedetails-body-wrap .twelve .col-md-8 {
    width: 66.66%;
  }

  /* .bathroomrrservicedetails-body-wrap .Collapsible:hover {
        color: #d40029;
    } */

  .bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .title {
    cursor: pointer;
    padding: 30px;
    font-size: 1.75rem;
    line-height: 1.5em;
    background: transparent;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .title .fa {
    position: relative;
    top: 0;
    right: auto;
    margin-left: 10px;
  }

  .bathroomrrservicedetails-body-wrap .service-details .content-right .accordion .content {
    /* padding: 0 30px 30px; */
    margin-top: 25px;
    background: transparent;
  }

  .bathroomrrservicedetails-body-wrap .headingiphone {
    display: none;
  }

  .bathroomrrservicedetails-body-wrap .accordion .Collapsible__trigger.is-open p {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 800px) {
  .bathroomrrservicedetails-body-wrap .content-section .row {
    margin-bottom: 80px;
  }

  .bathroomrrservicedetails-body-wrap .service-details {
    margin-bottom: 80px;
    padding: 40px 20px;
  }

  .bathroomrrservicedetails-body-wrap .content-section .row {
    margin-bottom: 60px;
  }

  .bathroomrrservicedetails-body-wrap .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  .bathroomrrservicedetails-body-wrap .headingnormal {
    display: none;
  }

  .bathroomrrservicedetails-body-wrap .headingiphone .content p {
    font-weight: normal;
  }

  .Collapsible__trigger {
    /* height: 43px; */
    height: 60px;
    /* margin-left: 12px;
        margin-right: 12px; */
  }
}