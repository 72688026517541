.body-wrap {   
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}
.white {
    padding: 20px 0;
    background: #fff;
}
.whitetab {
    padding: 20px 0;
    background: #fff;
}
.white .row {
    margin: 0 auto;
}
.twelve .colm-sd-12 {
    width: 70%;
}
.package-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    transition: max-width .3s ease;
}
.packages-grid .item {
    width: 100%;
    display: inline-block;
    float: none;
    left: 0;
    top: 650px;
}
.packages-grid .item figure img {
    max-width: 100%;
}
.packages-grid .item figure img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    vertical-align: middle;
}
.packages-grid .item .title {
    margin: 20px 0;
    font-weight: 700;
}
h5 {
    color: #000;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
}
p {
    margin: 0 0 20px;
}
strong {
    font-weight: bold;
}
em {
    font-style: italic !important;
}
ul.accent-sq {
    margin: 20px 0;
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
    text-align: start;
}
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;
}
ul.accent-sq li {
    position: relative;
    padding-left: 20px;
    padding-right: 15px;
    margin-bottom: 3px;
    list-style: none;
    /* font-size: 17px; */
}
ul.accent-sq li:before {
    content: '● ';
    position: absolute;
    margin-left: -20px;
    color: #d40029;
}
.packages-grid .item a {
    font-weight: 700;
}
ul.accent-sq li a {
    color: #000;
}
a {
    text-decoration: none;
}
.packages-grid .item .title {
    text-align: start;
    margin: 20px 0;
    font-weight: 700;
    left: 0;
    top: 650px;
}
.small-para {
    margin-top: 180px;
    font-size: 9px;
}
.packages-grid {
    position: relative;
}

@media only screen and (min-width: 1300px){
    .package-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .package-container {
        padding: 0 20px;
    }
    .packages-grid .col-dddd-6 {
        width: 46% !important;
    }
    h5 {
        font-size: 1.25rem;
        line-height: 1.3em;
    }
}

@media only screen and (min-width: 600px) {
    .white {
        padding: 40px 0 60px;
    }
    .whitetab {
        padding: 40px 0 60px;
    }
    .packages-grid .item {
        padding: 20px;
        left: 600px;
        top: 0.016px;
    }
    .packages-grid .item figure img {
        width: auto !important;
    }
    .small-para {
        margin-top: 0;
        font-size: 11px;
    }
    .packages-grid {
        position: relative;
    }
}

.colm-sd-12.col-md-6.col-dddd-6.item.general:last-child{
    float: left;
}