.last-yo-local {
    /* display: none; */
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    background: #d40029;
    font-size: .8125rem;
    line-height: 1em;
}

.last-yo-local .topbar-wrap {
    padding: 10px 0;
}

.last-franchise-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.last-yo-local .twelve .col-sd-12 {
    width: 100%;
}

.last-yo-local .name {
    display: inline-block;
}

.last-yo-local .name a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
}

.last-yo-local .local-nav ul {
    font-size: .8125rem;
    line-height: 1em;
}

ul li {
    list-style: disc outside;
}

@media only screen and (min-width: 600px){
    .last-yo-local .twelve .col-md-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 800px){
    .last-yo-local {
        text-align: right;
    }

    .last-franchise-container {
        padding: 0 20px;
    }

    .last-yo-local .name {
        margin-right: 0;
        padding-right: 15px;
        border-right: 1px solid #fff;
    }

    .last-yo-local .local-nav {
        display: inline-block;
        color: #fff;
        text-decoration: none;
    }

    .last-yo-local .local-nav ul {
        display: inline-block;
        margin: 0 !important;
        padding: 0;
        list-style: none;
    }

    .last-yo-local .local-nav ul li {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    .last-yo-local .local-nav ul li a {
        margin-left: 10px;
        color: #fff;
    }
}

@media only screen and (max-width: 799px){
    .last-yo-local .local-nav {
        display: none;
    }
}