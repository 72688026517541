.review-twelve .reviews-wrap {
    padding: 40px 0;
    background: #63666a no-repeat center center;
    background-size: cover;
}

.review-twelve .row {
    margin-bottom: 40px;
}

.review-twelve .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.review-twelve .reviews-wrap .home-reviews {
    position: relative;
    width: 78%;
    margin: 0 auto;
    color: #fff;
    text-align: center;
}

.review-twelve .reviews-wrap .home-reviews .item {
    width: 80%;
}

.review-twelve .reviews-wrap .home-reviews .rating {
    margin-bottom: 20px;
}

.review-twelve .reviews-wrap .home-reviews .rating span.r-star-full {
    background: transparent url('../FranchiseCommon/images/reviews-star-full.png') no-repeat center center;
    background-size: contain;
}

.review-twelve .reviews-wrap .home-reviews .rating span {
    display: inline-block;
    width: 33px;
    height: 24px;
}

.review-twelve .reviews-wrap .home-reviews .heading {
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    color: #fff;
    font-size: 1.75rem;
    line-height: 1.1428571428571428em;
}

.review-twelve .reviews-wrap .home-reviews .text {
    margin-bottom: 20px;
    line-height: 1.5em;
    overflow: scroll;
}

.review-twelve .reviews-wrap .home-reviews .signature {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.7777777777777777em;
}

.review-twelve .reviews-wrap .home-reviews .location {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.1666666666666667em;
}

.slick-next, .slick-prev{
    transform: translate(-148%,-50%) !important;
    top: 30% !important;

    /* added this because prev button was not having hand cursor effect on hover */
    z-index:999;
}
.slick-next:hover::before, .slick-prev:hover::before{
    -webkit-text-stroke: -5px white !important;
    opacity:1 !important;
}

.home-reviews .slick-slide .text p {
    text-align: center;
}

/* .review-twelve .home-reviews .slick-arrow::before {
    font-size: 45px;
    color: gray;
    display: block; */
    /* font-family: revicons; */
    /* text-align: center;
    z-index: 2;
    position: relative;
} */

.review-twelve li {
    list-style: none;
}

.review-twelve .home-reviews .slick-arrow {
    background: none !important;
}

.review-twelve .home-reviews .slick-next {
    right:-20px;
}
.review-twelve .home-reviews .slick-next::before {
    content: "\f054";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;

    font-size:45px;
    -webkit-text-stroke:5px rgb(44,48,50);
    opacity:0.5;
    position:relative;
    z-index: 2;
}

.review-twelve .home-reviews .slick-prev {
    /* changed from left: 15px */
    left:35px;
}
.review-twelve .home-reviews .slick-prev::before {
    content: "\f053";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;

    font-size:45px;
    -webkit-text-stroke:5px rgba(36,29,30,255);
    opacity:0.5;
    position:relative;
    z-index: 2;
}

@media only screen and (min-width: 800px){
    .review-twelve .reviews-wrap {
        padding: 60px 0;
    }

    .review-twelve .row {
        /* margin-bottom: 60px; */
        margin-bottom: 20px;
    }

    .review-twelve .container {
        padding: 0 20px;
    }

    .review-twelve .reviews-wrap .home-reviews .rating {
        margin-bottom: 40px;
    }

    .review-twelve .reviews-wrap .home-reviews .heading {
        margin-bottom: 40px;
    }

    .review-twelve .reviews-wrap .home-reviews .text {
        margin-bottom: 40px;
        line-height: 2rem;
        overflow: auto;
    }

    .review-twelve .location {
        display: block;
    }
}

/* changed from max-width: 400px to 600px for iphone 11 view */
@media only screen and (max-width: 600px){
    .review-twelve .reviews-wrap .home-reviews {
        position: relative;
        width: 100%;
        margin: 0 auto;
        color: #fff;
        text-align: center;
    }
}