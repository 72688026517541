.widget_search {
  margin-bottom: 1.615384615em !important;
}
.widget {
  margin-bottom: 3.5em;
}
.widget-bar {
  margin-bottom: 3.5em;
  padding-top: 1.75em;
  margin-top: -20px;
}
.widget-area > :last-child,
.widget > :last-child,
.widget-bar > :last-child {
  margin-bottom: 0 !important;
  margin: 0 0 20px !important;
}
.search-forms {
  position: relative;
}
.widget_search label {
  font-size: 0.9375rem;
  line-height: 2.1333333333333333em;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
}
.says,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.widget_search input {
  outline: 0;
  border: 0;
  background: #efeff0;
  color: #8a8c8f;
  transition: all 0.3s ease;
  font-size: 0.9375rem;
  line-height: 2.1333333333333333em;
  font-family: "Roboto Condensed", sans-serif;
}
input[type="search"].search-field {
  border-radius: 2px 0 0 2px;
  width: -webkit-calc(100% - 42px);
  width: calc(100% - 42px);
}
input[type="search"] {
  -webkit-appearance: textfield;
}
button,
input,
select,
textarea {
  max-width: 100%;
}
input,
button {
  color: inherit;
  font: inherit;
  margin: 0;
}
.widget_search button.search-submit {
  width: 55px;
}
button {
  background: #d40029;
  color: #fff;
  border: 0;
  border-radius: 2px;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
  -webkit-appearance: button;
  cursor: pointer;
}
.search-submit {
  border-radius: 0 2px 2px 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
}
.search-submit:before {
  font-size: 24px;
  left: 2px;
  line-height: 42px;
  position: relative;
  width: 40px;
}
.search-submit:before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "Genericons";
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  vertical-align: top;
}
#page .widget [class*="title"] {
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  text-transform: uppercase;
  font-size: 1.15rem;
  line-height: 1.6em;
  margin-bottom: 0;
  font-weight: 700;
}
.widget .widget-title {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 0.046875em;
  line-height: 1.3125;
  margin: 0 0 1.75em;
  text-transform: uppercase;
}
.singleBorder {
  background: #fff;
  position: relative;
  border-top: 3px solid #4c4d4f;
  z-index: 1;
  color: #63666a;
  padding: 10px;
}
.widget .widget-title1 {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 18px !important;
  line-height: 22px !important;
  margin: 0 0 1.75em;
  text-transform: uppercase;
  text-align: center;
}
.singleBorder p {
  font-size: 15px;
  color: #000;
  line-height: 17px;
  padding: 0 10px;
  margin-top: 13px;
  text-align: center;
}
.time-estimate {
  text-align: center;
}
.time-estimate a {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 2px !important;
  font-family: Roboto, sans-serif;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 5px;
  font-style: normal;
  border: 2px solid;
  transition: all 0.3s ease;
  text-decoration: none !important;
}
.widget-area ul {
  color: #d40029;
}
ul {
  list-style: none;
}
.widget-area ul li {
  list-style: disc outside;
  line-height: 1;
}
.widget-area a {
  color: #000;
  font-weight: 100;
  font-size: 13px;
  line-height: 1.75;
}
.widget-area a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 800px) {
  .singleBorder {
    margin-top: 12px;
    margin-left: -15px;
    margin-right: -25px;
    background-color: #d40029;
  }
  .singleBorder p {
    text-align: center;
    padding: 0 10px;
    color: #fff;
  }
  .widget .widget-title1 {
    color: #fff !important;
  }
  .widget-area ul {
    margin: 0 !important;
  }
}

@media screen and (min-width: 56.875em) {
  .sidebar {
    margin-left: 0%;
    padding: 0;
    width: 100%;
  }
  .sidebar {
    position: fixed;
    z-index: 10;
    width: 22% !important;
  }
  .widget {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin-bottom: 3.230769231em;
    padding-top: 1.615384615em;
  }
  .widget-bar {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin-bottom: 3.230769231em;
    padding-top: 0;
  }
  .widget-bar input[type="search"].search-field {
    width: -webkit-calc(100% - 35px);
    width: calc(100% - 0px);
  }
  .widget-bar input[type="search"] {
    padding: 0.4615384615em 0.5384615385em;
  }
  .widget-bar button.search-submit {
    padding: 0;
    width: 35px;
  }
  .widget input[type="search"].search-field {
    width: -webkit-calc(100% - 35px);
    width: calc(100% - 0px);
  }
  .widget input[type="search"] {
    padding: 0.4615384615em 0.5384615385em;
  }
  .widget_search button.search-submit {
    width: 45px !important;
  }
  .widget button.search-submit {
    padding: 0;
    width: 35px;
  }
  .widget-bar button {
    line-height: 1 !important;
    padding: 0.846153846em;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
  }
  .widget-bar .search-submit:before {
    font-size: 16px;
    left: 1px;
    line-height: 35px;
    width: 34px;
  }
  .widget .widget-title {
    margin-bottom: 1.3125em;
  }
  l .widget .widget-title1 {
    margin-bottom: 1.3125em;
  }
  .widget ul {
    margin-bottom: 1.6153846154em;
  }
}

@media screen and (min-width: 54.375em) {
  .sidebar,
  .widecolumn {
    margin-bottom: 5.25em;
  }
  .widget {
    margin-bottom: 5.25em;
    padding-top: 1.75em;
  }
  .widget-bar {
    margin-bottom: 5.25em;
  }
  .widget .widget-title1 {
    color: #fff !important;
  }
  .singleBorder p {
    color: #fff;
  }
}

@media screen and (min-width: 61.5625em) {
  .widget {
    margin-bottom: 4.307692308em;
  }
  .widget-bar {
    margin-bottom: 4.307692308em;
  }
}
