.faqscontentwrap-body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

/* .faqscontentwrap-body-wrap .Collapsible svg {
    display: none;
} */

.faqscontentwrap-body-wrap .Collapsible p {
    margin: 0;
}


.faqscontentwrap-body-wrap .Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 30px;

    cursor: pointer;
  }

  .Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
  }

.faqscontentwrap-body-wrap .content-section .row {
    margin-bottom: 40px;
}

.faqscontentwrap-body-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.faqscontentwrap-body-wrap #content .accordion {
    border-bottom: 1px solid #c5c5c5;
}

.faqscontentwrap-body-wrap .ui-widget {
    font-size: 1.1em;
    font-family: Verdana,Arial,sans-serif;
}

.faqscontentwrap-body-wrap .ui-helper-reset {
    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.3;
    text-decoration: none;
    outline: 0;
    border: 0;
    list-style: none;
}

.faqscontentwrap-body-wrap #content .accordion .title:hover {
    color: #d40029;
}

.faqscontentwrap-body-wrap #content .accordion .title {
    margin: 0;
    padding: 20px 5% 15px 5%;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    color: #000;
    letter-spacing: 1px;
    outline: 0;
    border: 0;
    border-top: 1px solid #c5c5c5;
    background: #fff;
    font-size: 1.25rem;
    line-height: 1.4em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.faqscontentwrap-body-wrap .ui-accordion .ui-accordion-icons {
    padding-left: 2.2em;
}

.faqscontentwrap-body-wrap .ui-accordion .ui-accordion-header {
    cursor: pointer;
    position: relative;
    display: block;
    min-height: 0;
    margin: 2px 0 0 0;
    padding: 0.5em 0.5em 0.5em 0.7em;
    font-size: 100%;
}

.faqscontentwrap-body-wrap .ui-corner-all, .faqscontentwrap-body-wrap .ui-corner-bottom, 
.faqscontentwrap-body-wrap .ui-corner-right, .faqscontentwrap-body-wrap .ui-corner-br {
    border-bottom-right-radius: 4px;
}

.faqscontentwrap-body-wrap .ui-corner-all, .faqscontentwrap-body-wrap .ui-corner-bottom, 
.faqscontentwrap-body-wrap .ui-corner-left, .faqscontentwrap-body-wrap .ui-corner-bl {
    border-bottom-left-radius: 4px;
}

.faqscontentwrap-body-wrap .ui-corner-all, .faqscontentwrap-body-wrap .ui-corner-bottom, 
.faqscontentwrap-body-wrap .ui-corner-left, .faqscontentwrap-body-wrap .ui-corner-bl {
    border-bottom-left-radius: 4px;
}

.faqscontentwrap-body-wrap .ui-corner-all, .faqscontentwrap-body-wrap .ui-corner-top, 
.faqscontentwrap-body-wrap .ui-corner-left, .faqscontentwrap-body-wrap .ui-corner-tl {
    border-top-left-radius: 4px;
}

.faqscontentwrap-body-wrap .ui-state-default, .faqscontentwrap-body-wrap .ui-widget-content .ui-state-default, 
.faqscontentwrap-body-wrap .ui-widget-header .ui-state-default {
    font-weight: normal;
    color: #555;
    border: 1px solid #d3d3d3;
    background: #e6e6e6;
}

.faqscontentwrap-body-wrap #content .accordion .title .ui-icon {
    display: none;
}

.faqscontentwrap-body-wrap .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    top: 50%;
    left: 0.5em;
    margin-top: -8px;
}

.faqscontentwrap-body-wrap .ui-icon-triangle-1-s {
    background-position: -64px -16px;
}

.faqscontentwrap-body-wrap .ui-icon {
    width: 16px;
    height: 16px;

    display: block;
    overflow: hidden;
    text-indent: -99999px;
    background-repeat: no-repeat;
}

.faqscontentwrap-body-wrap #content .accordion .content {
    padding: 0 5px 5px;
    font-family: 'Roboto',sans-serif;
    border: 0;
    color: #000;
    font-size: 1rem;
    line-height: 2em;
    
    font-weight: normal;
}

.faqscontentwrap-body-wrap .ui-accordion .ui-accordion-content {
    overflow: auto;
    padding: 1em 2.2em;
    border-top: 0;
}

.faqscontentwrap-body-wrap .ui-widget-content {
    color: #222;
    border: 1px solid #aaa;
    background: #fff;
}

.faqscontentwrap-body-wrap .ui-helper-reset {
    margin: 0;
    padding: 0;
    font-size: 100%;
    line-height: 1.3;
    text-decoration: none;
    outline: 0;
    border: 0;
    list-style: none;
}

.faqscontentwrap-body-wrap p, .faqscontentwrap-body-wrap ol {
    margin: 0 0 20px;
}

.faqscontentwrap-body-wrap strong {
    font-weight: bold;
}

.faqscontentwrap-body-wrap ol {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;
}

.faqscontentwrap-body-wrap #content .accordion .content a {
    color: #d40029;

    text-decoration: none;
}

.faqscontentwrap-body-wrap .content-section a {
    font-weight: 700;
}

.faqscontentwrap-body-wrap .ui-widget-content a {
    color: #222;
}

@media only screen and (min-width: 600px){
    .faqscontentwrap-body-wrap .Collapsible__trigger {
        padding: 10px 0 15px 0px;
    }

    .faqscontentwrap-body-wrap #content .accordion .content {
        padding : 20px 0 30px 5px;
    }

    .faqscontentwrap-body-wrap #content .accordion .title {
        /* padding: 30px; */
        font-size: 1.75rem;
        line-height: 1.5em;
    }
}

.faqscontentwrap-body-wrap .content-section .row h2 {
    margin-left:15px;
    margin-top:20px;
}

@media only screen and (min-width: 800px){
    .faqscontentwrap-body-wrap .content-section .row {
        margin-bottom: 60px;
    }

    .faqscontentwrap-body-wrap .container {
        padding: 0 20px;
    }
    .faqscontentwrap-body-wrap .content-section .row h2 {
        margin-left:58px;
        margin-top:20px;
    }
}


