.careers-banner {
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
}

.careers-banner h1 {
  width: 90%;
  margin: 10px auto;
  color: #111;
  padding-top: 30px;
  margin-bottom: 0 !important;
}

.submission-success-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 300px;
}

.submission-success-text h1,
.submission-success-text h2 {
  color: #d40029;
  margin-bottom: 15px;
}

.submit-spinner {
  margin-left: 10px;
}
