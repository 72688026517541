.bathroomrrbanner-body-wrap {
    background: #fff;
    background-repeat: repeat;
    background-position: top center;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

.detail-page-banner {
    position: relative;
    background-color: #63666a;
    /* changed background-position from top right to center */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* changed padding from 50px 0 to 70px 0 */
    padding: 70px 0;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}



@media only screen and (min-width: 800px) {
    #detailBanner {
        /* changed margin-top from 7rem to 14rem so that the heading isn't clipped */
        margin-top: 11rem !important;
    }

    .container {
        padding: 0 20px;
    }

    .detail-page-banner .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .detail-page-banner {
        /* padding: 96px 0; */
        padding: 150px 0;
    }

    .banner-heading-container {
        display: flex;
        position: relative;
        max-width: max-content;
    }

    .banner-heading-container .paintstroke-left {
        width: 81px;
        /* height: 212px; */
        height: 100px;

    }

    .banner-heading-container .header {
        display: flex;
        align-items: center;
        padding: 40px 40px 40px 0;
        color: #fff;
        margin: 0px;
        background-repeat: repeat-x;
        font-size: 3rem !important;
    }

    .banner-heading-container .paintstroke-right {
        width: 71px;
        /* height: 212px; */
        height: 100px;

    }

}

@media only screen and (max-width: 800px) {
    #detailBanner {
        margin-top: 4.2rem;
    }

    .container {
        padding: 0 20px;
    }

    .detail-page-banner .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .detail-page-banner::after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(59, 61, 64, 0.3);
        background: svg-gradient(to bottom, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
        background: -moz-linear-gradient(top, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(59, 61, 64, 0.3)), color-stop(100%, rgba(59, 61, 64, 0.9)));
        background: -webkit-linear-gradient(top, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
        background: -o-linear-gradient(top, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
        background: -ms-linear-gradient(top, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
        background: linear-gradient(to bottom, rgba(59, 61, 64, 0.3) 0, rgba(59, 61, 64, 0.9) 100%);
    }

    .banner-heading-container {
        display: flex;
        max-width: max-content;
        position: relative;
        z-index: 2;
    }

    .banner-heading-container .paintstroke-left {
        background-image: none;

    }

    .banner-heading-container .header {
        background-image: none !important;
        color: #fff;
        font-size: 2rem !important;
    }

    .banner-heading-container .paintstroke-right {
        background-image: none;
    }

}

@media only screen and (min-width: 1300px) {
    .container {
        max-width: 1240px;
    }
}

/* 
.bathroomrrbanner-body-wrap #banner {
    background: #fff;

    clear: both;
}

.bathroomrrbanner-body-wrap #banner .banner-slide {
    position: relative;
    background-color: #000;

    background-position: center;
    background-size: cover;
} */

/* .bathroomrrbanner-body-wrap #banner .banner-slide.bathroom_repair_and_refresh {
    background-image: url(https://www.acehandymanservices.com/images/services/bathroom_repair_and_refresh_banner.jpg);
} */

/* .bathroomrrbanner-body-wrap .br-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.bathroomrrbanner-body-wrap #banner .banner-slide .banner-text {
    z-index: 2;
    position: relative;
}

.bathroomrrbanner-body-wrap #banner .banner-slide .banner-text .header {
    padding: 40px 40px 40px 0;
    color: #fff;
}

.bathroomrrbanner-body-wrap h1 {
    font-size: 2rem;
    line-height: 1.5em;

    color: #000;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;

    margin: 0 0 20px;
}
.bathroomrrbanner-body-wrap #banner .banner-slide:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0,0,0,0.1);
    background: linear-gradient(to bottom,rgba(0,0,0,0.1) 0,rgba(26,26,26,0.9) 100%)
}

@media only screen and (min-width: 800px){
    .bathroomrrbanner-body-wrap #banner .banner-slide:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0,0,0,0.1);
    }

    .bathroomrrbanner-body-wrap #banner .banner-slide>.br-container {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .bathroomrrbanner-body-wrap .br-container {
        padding: 0 20px;
        max-width: max-content;
    }

    .bathroomrrbanner-body-wrap #banner .banner-slide .banner-text {
        width: auto;
        background-size: 100% 100%;
        background-repeat: repeat-x;
        display: inline-flex;
        align-items: center;
        height: 212px;
        vertical-align: middle;
        left: 60px;
        padding: 0 10px 0 0;
    }

    .bathroomrrbanner-body-wrap #banner .banner-slide .banner-text:before {
        content: '';
        position: relative;
        top: 0;
        left: -81px;
        display: block;
        width: 81px;
        height: 212px;
        background: transparent url('https://acehandyman-dev-dss-staging.igxdev.com/Corporate%20Site/Textures/paintstroke-bl-endcap-left.png') no-repeat center center;
    }

    .bathroomrrbanner-body-wrap #banner .banner-slide .banner-text .header {
        padding: 0;
    }

    .bathroomrrbanner-body-wrap #banner .header {
        padding: 90px 0 0;
    }

    .bathroomrrbanner-body-wrap #banner .header {
        margin: 0;
        padding: 40px 0 0;
    }

    .bathroomrrbanner-body-wrap h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }

    .bathroomrrbanner-body-wrap #banner .banner-slide .banner-text:after {
        content: '';
        position: relative;
        top: 0;
        right: -71px;
        display: block;
        width: 71px;
        height: 212px;
        background: transparent url('https://acehandyman-dev-dss-staging.igxdev.com/Corporate%20Site/Textures/paintstroke-bl-endcap-right.png') no-repeat center center;
    }

    .bathroomrrbanner-body-wrap #banner {
        height:auto;
    }
}

@media only screen and (min-width: 1300px) {
    .bathroomrrbanner-body-wrap .br-container {
        max-width: 1240px;
    }
} */