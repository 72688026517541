section.remodel {
    position: relative;
    padding: 20px 0
}

section.remodel .left {
    padding-bottom: 25px
}

section.remodel .btn {
    padding-right: 70px;
    padding-left: 70px;
    font-weight: 700;
}

.btn {
    font-weight: 400;
}

a {
    font-weight: 700;

    color: #d40029;

    text-decoration: none;
}

.btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: 10px;
    font-style: normal;
    border: 0;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

 section.remodel .left img {
    height: 290px;
    width: 100%;
    object-fit: cover;
    object-position: bottom
}

@media only screen and (min-width: 800px) {
    section.remodel {
        padding:0
    }

    section.remodel .left {
        /* margin-left: -55px; */
        padding-bottom: 0;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0
    }

    section.remodel .left img {
        height: 100%
    }

    section.remodel .right {
        margin-left: 33.33%; 
        padding: 40px 20px 40px 40px;
        padding-right: 0
    }
}

section.changing, section.remodel {
    margin-bottom: 40px;
    /* margin-top: 60px; */
}

@media only screen and (max-width: 600px) {
    section.remodel {
        text-align: center;
    }
}