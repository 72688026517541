.franchise-estimate-submission-map {
    margin-bottom: 40px;
}

.franchise-estimate-submission-map img {
    max-width: 100%;
    border: 1px solid #ccc;
}

@media only screen and (min-width: 600px){
    .franchise-estimate-submission-map img {
        width: auto;
    }
    .franchise-estimate-submission-map {
        padding: 0 40px;
    }
}