/* making text not bold anymore */
.banner-home-wrap h3 {
  font-weight: 300;
  width: 85%;
  max-width: 820px !important;
  margin-left: auto;
  margin-right: auto;
}

#banner {
  clear: both;
  /* changed from padding-top 125px to 67px for reducing gap above banner in moobile view */
  padding-top: 67px;
}

#banner.banner-logo {
  padding-top: 115px;
}

section {
  display: block;
}

h1 {
  margin: 0 0 20px;
  font-family: "Roboto Slab", serif;
}

h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

#banner .banner-slide.home {
  overflow: visible;
  width: 100%;
  vertical-align: middle;
}

#banner .banner-slide.home .banner-text {
  padding: 30px 20px;
  background: rgba(35, 36, 37, 0.8);
  justify-content: center;
  text-align: center;
}

#banner .banner-slide.home .banner-text img {
  max-width: 300px;
}

#banner .banner-slide.home .banner-text .header {
  padding: 0;
  font-family: "Roboto Slab", serif;
  color: #fff;
  text-align: center;
  font-size: 0.8125rem;
  line-height: 1.7692307692307692em;
}

#banner .header {
  margin: 0;
  padding: 40px 0 0;
}

#banner .banner-slide.home .banner-text .header .headline {
  font-weight: bold;
  color: #fff;
  font-size: 2.125rem;
  line-height: 1.3823529411764706em;
}

#banner .banner-slide.home .banner-text .header .headline span {
  display: block;
  /* changed font weight from 700 to 300 */
  font-weight: 300;
}

#banner .banner-slide.home .banner-text .header .headline span p {
  font-size: 1.125rem;
  text-align: center;
}

#banner .banner-slide.home .banner-text .header .headline span p span {
  display: block;
  font-weight: 300;
}

#banner .banner-slide.home .banner-text .header .headline a {
  color: #fff;
}

h3.white {
  color: #fff;
  padding: 0;
}

.home-banner-container {
  width: 100%;
  max-width: fit-content;
  margin: 0;
  padding: 0;
  transition: max-width 0.3s ease;
}

.banner-slide.home .white {
  background: none;
}

.banner-slide.home .white p {
  text-align: center;
}

.display-flex {
  display: flex;
}

.pos-left {
  justify-content: flex-start;
}

.pos-center {
  justify-content: center;
}

.pos-right {
  justify-content: flex-end;
}

.mr-50 {
  margin-right: 50px;
}

.ml-50 {
  margin-left: 50px;
}

@media only screen and (min-width: 1600px) {
  .mr-50 {
    margin-right: 100px;
  }
  .ml-50 {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 1792px) {
  .mr-50 {
    margin-right: 200px;
  }
  .ml-50 {
    margin-left: 200px;
  }
}

@media only screen and (min-width: 1300px) {
  .home-banner-container {
    max-width: fit-content;
  }
}

@media only screen and (min-width: 800px) {
  /* added ".content" because margin-bottom was affecting other pages' banners*/
  .content #banner {
    height: auto !important;
    /* changed padding-top from 146px to 120px so that the gap above banner gets reduced */
    padding-top: 120px;
    /* added this because submit button was cropped out */
    margin-bottom: 20px !important;
  }

  .content #banner.banner-logo {
    padding-top: 150px;
  }

  #banner .header {
    padding: 90px 0 0;
  }

  .home-banner-container {
    padding: 0;
  }

  #banner .banner-slide.home .banner-text {
    width: auto !important;
    flex-direction: row;
    justify-content: center;
    height: auto;
    margin: 0 !important;
    padding: 100px 120px 80px;
    vertical-align: middle;
  }

  #banner .banner-slide.home .banner-text .header {
    font-size: 1.375rem !important;
    line-height: 1.4545454545454546em !important;
  }

  #banner .banner-slide.home .banner-text .header .headline {
    font-size: 3.75rem !important;
    line-height: 1.1em !important;
  }

  #banner .banner-slide.home .banner-text .header .headline span {
    /* changed font size from 2.75rem to 1.75rem */
    font-size: 1.75rem;
    line-height: 1.8125em;
  }
}

@media only screen and (min-width: 600px) {
  .find-local-plumber > span {
    font-size: 1.8rem;
  }
  #banner .banner-slide.home .banner-text img {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }

  .banner-slide.home .white {
    padding: 0;
  }

  #banner .banner-slide.home .banner-text .header .headline {
    font-size: 2.625rem;
    line-height: 1.0476190476190477em;
  }

  #banner .banner-slide.home .banner-text .header .headline span {
    margin-bottom: 20px !important;
  }

  #banner .banner-slide.home .banner-text {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 55px 10%;
  }

  #banner .banner-slide.home .banner-text .banner-home-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  #banner .banner-slide.home .banner-text img {
    max-width: 500px;
  }

  #banner .banner-slide.home .banner-text .header {
    font-size: 1.25rem;
    line-height: 1.4em;
  }

  #banner .banner-slide.home .banner-text .header .headline span p {
    font-size: 2rem;
    text-align: center;
  }

  .content #banner {
    margin-bottom: 20px !important;
  }

  #banner .banner-slide.home .banner-text img {
    max-width: 300px;
  }

  #banner .banner-slide.home .banner-text .header .headline {
    line-height: 3.6rem;
  }
}

.banner-logo img {
  width: 300px !important;
}

@media only screen and (max-width: 1040px) {
  #banner .banner-slide.home .home-banner-container {
    max-width: 100%;
    margin: 0;
  }
}

@media only screen and (min-width: 1040px) {
  #banner .banner-slide.home .banner-text img {
    max-width: 650px !important;
    object-fit: scale-down;
  }

  #banner .banner-slide.home .banner-text .header .headline span {
    margin-top: 20px;
  }

  #banner .banner-slide.home .home-banner-container .banner-text {
    padding: 20px 20px;
    background: transparent;
    justify-content: flex-end;
  }

  .content #banner {
    height: auto;
  }

  /* shifting elements up */
  #banner .banner-slide.home .banner-text .header {
    font-size: 1.25rem;
    line-height: 1.4em;

    margin-top: -40px;
  }

  #banner .banner-slide.home .banner-text .header .headline span:nth-child(2) {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1920px) {
  .content #banner {
    padding-top: 118px;
  }
  #banner .banner-slide {
    height: 853px;
  }
}

@media only screen and (min-width: 1920px) {
  #banner .banner-slide {
    height: 750px;
  }
  #banner .banner-slide.home .banner-text img {
    object-fit: initial;
    max-width: 550px !important;
  }
  #banner .banner-slide.home .banner-text .header .headline span {
    font-size: 2.75rem;
  }
  #banner .banner-slide.home .banner-text .header .headline .find-local-plumber span {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 1040px) {
  #banner .banner-slide.home .home-banner-container {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .find-local-plumber > span {
    font-size: 1.42rem;
    width: auto !important;
  }
  #banner .banner-slide.home .home-banner-container {
    max-width: 100%;
    margin: 0;
  }

  #banner .banner-slide.home .banner-text .banner-logo img {
    margin-top: 50px;
  }

  #banner .banner-slide.home .banner-text img {
    max-width: 200px;
    margin: 0 !important;
    margin-bottom: 15px !important;
  }

  #banner .banner-slide.home .banner-text .header .headline {
    font-size: 2rem;
    margin-bottom: 10px;
    line-height: 2.1rem;
  }

  #banner .banner-slide.home .banner-text .header .headline span {
    margin-bottom: 20px !important;
  }

  #banner .banner-slide.home .banner-text .header .headline span p {
    margin-bottom: 0;
  }
}
.find-local-plumber > span {
  background-color: #d40029;
  width: 400px;
  margin: 0 auto;
  border-radius: 5px;
}
