.right-promise-wrap {
    position: relative;
    /* padding: 40px 0; */
    background: #fff;
    outline: 1px solid #000;
    z-index: 1;
    text-align: center;
}

.right-promise-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 15px; */
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
    background: rgba(0, 0, 0, 0.5);
}

.right-promise-container .right-promise-slider ul li p {
    color: #fff;
}

.right-promise-wrap .heading {
    font-size: 1.75rem;
    line-height: 1.3571428571428572em;
    /* color: #000; */
    color: #fff;
    margin-bottom: 5px;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

.right-promise-wrap .right-promise-slider .slide .more {
    display: block;
    margin: 20px auto;
    text-align: center;

    padding-bottom: 20px !important;
}

.right-promise-wrap .right-promise-slider .slide a, .right-promise-wrap .right-promise-slider .slide .more {
    font-weight: 700;

    color: #d40029;

    font-size: 20px;
}

.right-promise-slider ul li {
    list-style: none;
    margin-top: 15px;
}

.right-promise-slider .slide {
    padding: 0 20px;
    /* margin-top: -25px; */
}

.right-promise-slider .react-multi-carousel-dot button {
    border-radius:0px;
    background: #becccf !important;
    border-color: transparent;
}
.right-promise-slider .react-multi-carousel-dot button:hover {
    background: transparent !important;
}
.right-promise-slider .react-multi-carousel-dot--active button {
    background: black !important;
}
.right-promise-slider .react-multi-carousel-dot--active button:hover {
    background: black !important;
}

@media only screen and (min-width: 800px){
    .right-promise-wrap {
        padding: 50px 0;
        background-repeat: no-repeat;
        background-position: right 20%;
        background-size: 50% auto;
    }

    .right-promise-container {
        padding: 0 20px;
        background: none;
    }

    .right-promise-wrap .heading {
        color: #000;
    }

    .right-promise-slider ul li {
        /* margin-top: 0; */
        margin-top: -22px;
    }

    .twelve .right-promise.col-dd-6 {
        width: 50%;
    }

    /* added */
    .right-promise-wrap .right-promise-slider-wrap {
        left: -5%;
    }

    .right-promise-container .right-promise-slider ul li p {
        color: #000;
    }
}

@media only screen and (min-width: 1000px){
    .right-promise-wrap .heading {
        font-size: 2.0625rem;
        /* line-height: 1.3636363636363635em; */
        line-height: 1em;
    }
}


@media only screen and (min-width: 1300px){
    .right-promise-container {
        max-width: 1240px;
    }
}

@media only screen and (max-width: 600px){
    .right-promise-wrap .right-promise-slider .slide p {
        /* color: #000; */
        color: #fff;
        font-weight: bold;
    }
}


