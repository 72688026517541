.applied-before .first-field input {
  width: auto !important;
  cursor: pointer;
  margin-right: 10px;
}

.applied-before .first-field,
.applied-date {
  flex-basis: 50%;
}
