body {
    position: relative;
    left: 0;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.fsp-home #content .wood-pattern {
    position: relative;
    margin-bottom: 40px;
    background-color: #fff;
    background-repeat: repeat;
    background-position: bottom center;
}

.fsp-home #content .wood-pattern>.row.services-wrap {
    margin-bottom: 0;
}

.fsp-home #content .wood-pattern>.row {
    margin-bottom: 0;
}

/* commented to reduce space above the icons in careers page*/
/* .content-section .row {
    margin-bottom: 40px;
} */

.fsp-home .services-wrap {
    padding: 0;
}

.fsp-home .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.fsp-home .twelve .col-sd-12 {
    width: 100%;
}

.fsp-home .services-wrap .group-list .heading {
    margin: 0;
    /* commented to increase space between collapsible button and details */
    /* padding: 20px 0; */
    text-align: center;
    /* commented to increase space between collapsible button and details */
    /* border-bottom: 1px solid #d3d3d3; */
    background: #fff;
}

.fsp-home .heading {
    color: #000;
}

.fsp-home h4 {
    font-size: 1.125rem;
    line-height: 1.3888888888888888em;

    color: #000;

    font-family: 'Roboto Slab', serif;
    font-weight: 700;
}

.fsp-home h4,
.fsp-home p,
.fsp-home ul {
    margin: 0 0 20px;
}

.fsp-home .services-wrap .group-list .heading span.arrow {
    position: absolute;
    right: 10%;
    font-size: 150%;
    color: #8c8e91;
    font-weight: 700;
}

.fsp-home html .fa {
    color: inherit;
}

.fsp-home .fa,
.fsp-home .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fsp-home .fa,
.fsp-home .fas,
.fsp-home .far,
.fsp-home .fal,
.fsp-home .fad,
.fsp-home .fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fsp-home .fa-angle-down:before {
    content: "\f107";
}

/* .services-wrap .group-list .content {
    display: none;
    padding: 20px 10%;
    background: #efeff0;
} */

.fsp-home .services-wrap .group-list .headingiphone {
    display: none;
}

.fsp-home .services-wrap .group-list .content ul {
    overflow: auto;
    margin: 0 !important;
    padding: 0;
    list-style: none;
    text-align: left;
}

.fsp-home ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;
}

.fsp-home .services-wrap .group-list .content ul li {
    list-style: none;
}

.fsp-home ul li {
    list-style: disc outside;
}

.fsp-home .services-wrap .group-list .content ul li a {
    font-weight: 700;
    color: #000;
    font-size: .9375rem;
    line-height: 2.1333333333333333em;
}

.fsp-home .content-section a {
    font-weight: 700;

    color: #d40029;
    text-decoration: none;
}

.fsp-home .services-wrap .services-tagline-wrap {
    clear: both;
    /* commented to keep the tagline to the left, otherwise it is still center */
    /* padding: 20px 10%; */
    text-align: center;
    background: #fff;

    /* padding-left: 200px !important; */
}

.fsp-home .services-wrap .services-tagline-wrap p {
    margin-bottom: 0;
    /* changed from center to left for nonmobile view */
    text-align: left;
}

.fsp-home .services-wrap .services-tagline-wrap p .phone-number {
    color: #d40029;
}

.fsp-home .services-wrap .services-tagline-wrap p a {
    font-weight: 700;

    color: #d40029;
}

/* added to replace whitespace above and beneath by service by area by room section with background image */
.fsp-home {
    background-color: #fff;
    background-repeat: repeat;
    background-position: bottom center;
}

@media only screen and (min-width: 600px) {
    .fsp-home #content .wood-pattern {
        margin-bottom: 0;
    }

    .fsp-home #content .wood-pattern>.row.services-wrap {
        margin-top: 40px;
    }

    .fsp-home #content .wood-pattern>.row {
        margin-bottom: 40px;
    }

    .fsp-home .services-wrap {
        padding: 0 10%;
    }

    /* changed from 40px to 10px to reduce space between services list and tagline */
    .fsp-home .services-wrap .group-list {
        margin-bottom: 10px;
    }

    .fsp-home .twelve .col-md-6 {
        width: 50%;
    }

    .fsp-home .services-wrap .group-list .heading {
        margin-bottom: 40px;
        padding: 0;
        text-align: left;
        border-bottom: 0;
        background: transparent;
    }

    .fsp-home .services-wrap .group-list .heading .arrow {
        display: none;
    }

    .fsp-home .services-wrap .group-list .content {
        display: block;
        padding: 0;
        background: transparent;
    }

    .fsp-home .services-wrap .group-list .content ul li a {
        padding-right: 10px;
    }

    .fsp-home .twelve .col-md-12 {
        width: 100%;
    }

    .fsp-home .services-wrap .services-tagline-wrap {
        width: 100%;
        /* commented to reduce space at the bottom of by area by service by room section */
        /* padding: 20px 0 60px; */
        text-align: left;
        background: transparent;
    }
}


@media only screen and (min-width: 800px) {

    /* this is adding whitespace between breadcrumbs and content in services subpage.
        but without this, the iphone view changes back 
    */
    .fsp-home #content:after {
        content: '';
        display: block;
        width: 100%;
        max-width: 1240px;
        margin: 0 auto 20px;
    }

    /* commented to reduce space on top of by area by service by room section */
    /* .home #content .wood-pattern>.row.services-wrap {
        margin-bottom: 80px;
    } */

    .fsp-home #content .wood-pattern>.row {
        margin-bottom: 80px;
    }

    /* commented to reduce space above the icons in careers page */
    /* .content-section .row {
        margin-bottom: 60px;
    } */

    .fsp-home .services-wrap {
        padding: 0 20px;
    }

    .fsp-home .container {
        padding: 0 20px;
    }

    .fsp-home .twelve .col-dd-3 {
        width: 25%;
    }

    .fsp-home h4 {
        font-size: 1.5rem;
        line-height: 1.1666666666666667em;
    }

    .fsp-home .twelve .col-dd-6 {
        width: 50%;
    }

    .fsp-home .twelve .col-dd-2 {
        width: 16.66%;
    }

    /* .fsp-home #content .wood-pattern>.row.services-wrap {
        margin-bottom: 80px;
    } */
    .fsp-home .row.services-wrap {
        /* margin-bottom: 130px !important; bug - 52577*/
        margin-bottom: 20px !important;
    }

    .fsp-home .services-wrap .services-tagline-wrap span p:nth-child(3) {
        padding-left: 200px !important;
        padding-top: 20px;
    }

    .fsp-home .services-wrap .services-tagline-wrap span p:nth-child(1) {
        padding-top: 10px;
        /* padding-bottom: 10px; */
        padding-bottom: 110px;
        padding-left: 200px;
    }
}

@media only screen and (max-width: 600px) {

    .fsp-home .services-wrap .group-list .headingnormal,
    .fsp-home .services-wrap .group-list .contentnormal {
        display: none;
    }

    .fsp-home .services-wrap .group-list .contentiphone {
        display: block;
        padding: 20px 10%;
        background: #efeff0;

        text-align: center;
    }

    .fsp-home .services-wrap .group-list .Collapsible__trigger {
        display: block;
        margin: 0;
        padding: 20px 0;
        /* text-align: center; */
        border-bottom: 1px solid #d3d3d3;
        /* background: #fff; */
    }

    .fsp-home #content .wood-pattern {
        background-image: none;
    }

    .fsp-home .services-wrap .group-list .Collapsible__trigger h4 {
        display: block;
        color: #000;
    }

    .fsp-home .services-wrap .services-tagline-wrap p {
        text-align: center;
        padding-top: 30px;
    }


}

@media only screen and (min-width: 600px) {
    .fsp-home .row.services-wrap {
        /* margin-bottom: 130px !important; - bug - 52577*/
        margin-bottom: 30px !important;
    }
}