.offers-content-wrap .row {
  margin-bottom: 40px;
}

.offers-content-wrap .offers-wrap {
  text-align: center;
}

.offers-content-wrap .col-sd-12 {
  width: 100%;
  padding: 0 10px;
}

.offers-content-wrap .offers-wrap .offer {
  width: 100%;
  margin: 10px auto 30px;
  vertical-align: top;
}

.offers-content-wrap .offers-wrap .offers-module {
  z-index: 1;
  position: relative;
  margin: auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  border: 3px solid #8a8c8f;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-size: cover;
}

.offers-content-wrap .offers-wrap .offers-module .module-head {
  text-align: left !important;
}

.offers-content-wrap .offers-wrap .offers-module .module-head,
.offers-content-wrap .offers-wrap .offers-module .module-body {
  z-index: 1;
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.offers-content-wrap .offers-module .module-head {
  text-align: center;
}

.offers-content-wrap .offers-wrap .offers-module .module-head .offer-heading {
  font-family: "Roboto Slab", serif;
  padding: 0;
  padding-bottom: 35px;
  font-weight: 700;
  color: #000;
  border: 0;
  font-size: 1.75rem;
  line-height: 1.2142857142857142em;
}

.offers-content-wrap .offers-wrap .offers-module .module-body {
  padding-bottom: 50px;
  text-align: left !important;
}

.offers-content-wrap .offers-wrap .offers-module .module-head,
.offers-content-wrap .offers-wrap .offers-module .module-body {
  z-index: 1;
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.offers-content-wrap .offers-wrap .offers-module .module-body .offer-headline {
  padding: 0;
  margin: 0 0 10px;
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}

.offers-content-wrap
  .offers-wrap
  .offer#offer-wrap-1
  .offers-module
  .claim-button {
  padding: 15px 70px;
  font-size: 1rem;
  line-height: 1.25em;
}

.offers-content-wrap .offers-wrap .offers-module .module-body .claim-button {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 40px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  background: #d40029;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 0.9375rem;
  line-height: 1.3333333333333333em;
}

.yo-modal-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 996;
}

.yo-modal-container .modal {
  width: 100% !important;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 2%;
}

.yo-modal-container .yo-modal,
.yo-modal-container .yo-lightbox {
  position: relative;
  margin: 0 auto;
  z-index: 998;
}

.yo-modal-container .yo-lightbox-close {
  display: block;
  width: 26px;
  height: 26px;
  float: right;
  position: absolute;
  top: -13px;
  right: -13px;

  font-size: 1px;
  color: transparent;
}

.text-only.enhanced .yo-lightbox-close,
.text-and-image.enhanced .yo-lightbox-close {
  display: none;
}

.offer-preview-container {
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  font-family: "Roboto", sans-serif;

  background: #d8d9db;
}

.offer-preview-header {
  padding: 15px 40px 15px 0;
  overflow: auto;
}

.offer-preview-container .offer-preview-header a.close-button {
  padding: 10px 20px;
  margin-left: 10px;
}

.offer-preview-container .offer-preview-header a {
  margin: 0;
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border: 0;
  background: #404040;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-transition: background 0.3s ease;
  -webkit-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-transform: none;

  float: right;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.offer-preview-container .offer-preview-header a.close-button i {
  font-size: 0.875rem;
  line-height: 1.1428571428571428em;
  font-weight: bold;
  font-family: arial, sans-serif !important;
  color: #fff;
}

.offer-preview-container .offer-preview-header a.print-offer-button {
  margin: 0 1px 0 0;
}

.offer-preview-container .offer-preview-body {
  text-align: center;
}

.offer-preview-body {
  overflow: auto;
  background: #fff;
  padding: 15px;
}

.offer-preview-container .offer-preview-body .left,
.offer-preview-container .offer-preview-body .right {
  float: none;
  width: 100%;
}

.offer-preview-body .left {
  width: 58%;
  float: left;
}

.offer-preview-body .right {
  width: 40%;
}

.offer-preview-container .offer-preview-body .offer-heading {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  color: #000;
  font-style: normal;
  margin: 0.67em 0 0 !important;
}

h6 {
  font-size: 0.875rem;
  line-height: 1.4285714285714286em;

  color: #000;

  margin: 0 0 20px;
}

.offer-preview-container .offer-preview-body .offer-headline {
  margin: 0.67em 0 0 !important;
  color: #000;
  font-family: "Roboto Slab", serif;
}

.offer-preview-container .offer-preview-body p.expire-date {
  font-size: 10px;
  line-height: 1em;

  text-transform: uppercase;
}

.offer-preview-body .expire-date {
  font-family: "Helvetica";
  color: #4c4d4f;
  margin-bottom: 20px;
  margin-top: 10px;
}

.offer-preview-container .offer-preview-body .address-block p {
  margin: 0;
  line-height: 1.3em;
}

.offer-preview-body .address-block .business-name,
.offer-preview-body .address-block .phone-number {
  font-weight: bold;
}

.offer-preview-body .address-block p {
  font-family: "Helvetica";
  color: #4c4d4f;
  font-size: 12px;
}

.offer-preview-container .offer-preview-body .offer-image img {
  width: 100%;
  height: auto;
}

img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.offer-preview-container .offer-preview-terms-and-conditions {
  text-align: center;
}

.offer-preview-terms-and-conditions {
  padding: 15px;
  background: #ededed;
  clear: both;
}

.offer-preview-container .offer-preview-terms-and-conditions p {
  font-size: 0.6875rem;
  line-height: 1.4545454545454546em;
  margin: 20px;
}

.offer-preview-terms-and-conditions p {
  font-family: "Helvetica";

  color: #4c4d4f;
}

.offer-preview-container .offer-preview-footer {
  padding: 25px;
}

.offer-preview-footer {
  overflow: auto;
  position: relative;
}

form {
  position: relative;
}

.offer-preview-container .offer-preview-footer label {
  font-style: normal;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  font-size: 0.875rem;
  line-height: 1.2857142857142858em;
}

.offer-preview-footer label {
  color: #4c4d4f;
}

label {
  cursor: pointer;
}

.email-form .field-wrapper,
.email-form .field-wrapper:after,
.email-form .yv-formfield,
.email-form .yv-formfield .fieldCaption {
  transition: all 0.2s ease-in;
}

.offer-preview-container .offer-preview-footer .email-form input {
  border-radius: 0;
  background-clip: padding-box;
}

.offer-preview-footer input {
  box-shadow: none;
  border: none;
  padding-left: 10px;
  float: left;
}

.offer-preview-container .offer-preview-footer .old button {
  height: 50px;
  width: 41%;
  min-width: 110px;
}

.offer-preview-container .offer-preview-footer .email-form button {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5em;
  background: #d40029;
  text-transform: none;
  padding: 0;
  -moz-transition: background 0.3s ease;
  -webkit-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.offer-preview-footer button {
  float: right;
  border: 0;
  cursor: pointer;
}

.offer-preview-footer .offers-input-details {
  padding: 8px;
  overflow: auto;
  position: relative;
}

.offer-preview-footer .offers-input-details input {
  width: 128px;
  height: 40px;
  border-radius: 3px;
  box-shadow: none;
  border: none;
  padding-left: 10px;
  float: left;
  margin-right: 10px;
  margin-bottom: 3px;
  margin-top: 5px;
}

.offer-preview-footer .offers-input-details .email-input-customized input {
  width: 427px;
  height: 40px;
  border-radius: 3px;
  box-shadow: none;
  border: none;
  padding-left: 10px;
  float: left;
}

.offer-preview-container .offer-preview-footer .offers-input-details button {
  padding: 0 15px;
}

.offer-preview-footer .offers-input-details button {
  float: right;
  border: 0;
  font-weight: bold;
  height: 40px;
  cursor: pointer;
  margin-top: 3px;
}

.offer-preview-footer .offers-leads-details {
  padding: 8px;
  overflow: auto;
  position: relative;
}

.yo-modal-overlay {
  background: #262626;
  background: rgba(0, 0, 0, 0.85);
}

@media only screen and (min-width: 460px) {
  .offer-preview-container .offer-preview-body {
    text-align: left;
  }

  .offer-preview-container .offer-preview-body .left {
    width: 60%;
  }

  .offer-preview-container .offer-preview-body .left {
    float: left;
  }

  .offer-preview-container .offer-preview-body .right {
    display: block;
    width: 38%;
    margin: 0 auto;
  }

  .offer-preview-container .offer-preview-footer .old input {
    width: 70%;
  }

  .offer-preview-container .offer-preview-footer .old input {
    width: 59%;
    height: 50px;
  }

  .offer-preview-container .offer-preview-footer .old button {
    width: 30%;
  }
}

@media only screen and (min-width: 600px) {
  .offer-preview-header {
    padding: 15px 45px 15px 0;
  }

  .offer-preview-container .offer-preview-header a.close-button {
    margin-left: 35px;
  }

  .offers-content-wrap .right-col {
    padding-right: 30px;
  }

  .offers-content-wrap .col-md-6 {
    width: 50%;
  }

  img {
    width: auto;
  }
}

@media only screen and (min-width: 800px) {
  .offers-content-wrap .row {
    margin-bottom: 60px;
  }

  .offers-content-wrap .container {
    padding: 0 20px;
  }

  .offers-content-wrap .pad-right-lg {
    padding: 0 30px 0 0;
  }

  .offers-content-wrap .col-dd-8 {
    width: 66.66%;
  }

  .offers-content-wrap .offers-wrap .offer {
    display: inline-block;
    margin: 0 0 30px;
    vertical-align: top;
  }

  .offers-content-wrap .offers-wrap .offers-module {
    padding: 30px;
    /* added this to add more space under button */
    padding-bottom: 80px;

    border: 2px solid #8a8c8f;
  }

  .offers-content-wrap .offers-wrap .offer#offer-wrap-1 .offers-module {
    padding: 282px 50px 50px;
    /* added this to add more space under button for featured coupon */
    padding-bottom: 100px;

    border: 2px solid #8a8c8f;
  }

  /* .offers-content-wrap .offers-wrap .offer#offer-wrap-1 .offers-module .module-head:before */
  .offer-before {
    content: "";
    position: absolute;
    top: 110px;
    display: block;
    width: 228px;
    height: 155px;
    border-bottom: 1px solid #8a8c8f;
    /* *replacing with jsx */
    background-size: auto;
  }

  .offers-content-wrap
    .offers-wrap
    .offer#offer-wrap-1
    .offers-module
    .module-head
    .offer-heading {
    font-size: 2.625rem;
    line-height: 1.380952380952381em;
    padding-bottom: 3px;
  }

  .offers-content-wrap
    .offers-wrap
    .offer#offer-wrap-1
    .offers-module
    .module-body
    .offer-headline {
    margin: -6px 0 10px;
    padding-bottom: 8px;
    font-size: 1.375rem;
    line-height: 1.3636363636363635em;
  }

  .offers-content-wrap .offers-wrap .offers-module .module-body .claim-button {
    font-size: 0.9375rem;
    line-height: 1.2em;
    padding: 10px 40px;
    width: auto;
    /* added this to add more space above button */
    margin-bottom: -60px;
  }

  /* .offers-content-wrap .offers-wrap .offer#offer-wrap-1 .offers-module:after */
  .offer-after {
    content: "";
    z-index: -2;
    position: absolute;
    top: 0;
    right: -1px;
    display: block;
    width: 60%;
    height: 282px;
    background-size: 100% auto;
  }

  .offers-content-wrap .right-col {
    padding-right: 0;
  }

  .offers-content-wrap .col-dd-4 {
    width: 33.33%;
  }

  .offers-content-wrap .col-dd-6 {
    width: 50%;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.1111111111111112em;
  }
}
@media only screen and (max-width: 500px) {
  .offers-content-wrap .offers-wrap .offers-module {
    border: 2px solid #8a8c8f;
  }
}

@media only screen and (max-width: 390px) {
  .offer-preview-header .print-offer-button {
    padding: 10px 21px !important;
  }
}

@media only screen and (max-width: 420px) {
  .offer-preview-header .print-offer-button {
    margin-right: 59px !important;
    padding-left: 45px !important;
    padding-right: 45px !important;
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: 400px) {
  .offer-image {
    display: none;
  }

  .offer-preview-body .offer-headline,
  .expire-date,
  .address-block.business-info .business-name,
  .address-block.business-info .business-address,
  .address-block.business-info .phone-number {
    text-align: center;
  }

  .claim-button {
    margin-bottom: -60px;
  }

  .offers-module {
    padding-bottom: 70px !important;
  }
}
