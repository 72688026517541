.our-team-content-wrap .row {
  margin-bottom: 40px;
}

.our-team-content-wrap .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

.our-team-content-wrap .bios-wrap .bio {
  overflow: auto;
  padding: 30px 0;
  border-top: 1px solid #ccc;
}

.our-team-content-wrap .bios-wrap .bio p{
  padding: 0;
}

.our-team-content-wrap .bios-wrap .bio .full-bio p{
  padding: 0 20px;
}

.our-team-content-wrap .bios-wrap .bio .photo img {
  width: auto;
  max-width: 100%;
}

img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.our-team-content-wrap .bios-wrap .bio .title {
  /* padding: 20px 0 0 0; */
  padding: 20px 0 0 20px;
  font-weight: 700;
  line-height: 1.2;
}

.our-team-content-wrap .bios-wrap .bio .title .heading {
  font-size: 1.75rem;
  line-height: 1.3571428571428572em;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
}

.heading {
  color: #000;
}

p {
  margin: 0 0 20px;
}

.why-choose-us-franchise .whychooseusbanner-body-wrap #why-banner .header {
  padding-top: 70px !important;
}

@media only screen and (max-width: 799px) {
  .why-choose-us-franchise .whychooseusbanner-body-wrap #why-banner .header {
    padding-top: 40px !important;
  }
}

@media only screen and (min-width: 600px) {
  /* removed .twelve before the col */
  .our-team-content-wrap .col-md-3 {
    width: 25%;
  }

  img {
    width: auto;
  }

  .our-team-content-wrap .bios-wrap .bio .title {
    padding: 0 0 0 40px;
  }

  /* removed .twelve before the col */
  .our-team-content-wrap .col-md-9 {
    width: 75%;
  }

  .our-team-content-wrap .bios-wrap .bio .full-bio {
    padding: 40px 0 0 0;
  }
}

@media only screen and (min-width: 800px) {
  .our-team-content-wrap .row {
    margin-bottom: 60px;
  }

  .our-team-content-wrap .container {
    padding: 0 20px;
  }

  .our-team-content-wrap .bios-wrap .bio {
    padding: 60px 20px;
  }

  /* removed .twelve before the col */
  .our-team-content-wrap .col-dd-2 {
    width: 16.66%;
  }

  /* removed .twelve before the col */
  .our-team-content-wrap .col-dd-3 {
    width: 25%;
  }

  .our-team-content-wrap .bios-wrap .bio .full-bio {
    padding: 0 0 0 40px;
  }

  /* removed .twelve before the col */
  .our-team-content-wrap .col-dd-7 {
    width: 58.33%;
  }
}
