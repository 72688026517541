.body-wrap {
  background-color: #fff;
  background-repeat: repeat;
  background-position: top center;
}
#offices-banner {
  clear: both;
}
#offices-banner .banner-slide {
  background-position: center;
  background-size: cover;
  padding-top: 150px;
}
#state-list {
  /* border-bottom: 1px solid #8a8c8f; */
  margin-top: 0px;
  margin-bottom: 0px;
}
.offices-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  transition: max-width 0.3s ease;
}
#state-list .state-list {
  margin-left: 0 !important;
  padding: 0;
  list-style: none;
}
ul {
  margin: 20px 0 20px 20px !important;
  font-size: 1rem;
  line-height: 1.75em;
}
#state-list .state-list li {
  display: inline-block;
  width: 50%;
  padding: 0;
  list-style: none;
  font-size: 0.875rem;
  line-height: 2em;
}
#state-list .state-list li a {
  color: #d40029;
  cursor: pointer;
}
.content-section a:hover {
  text-decoration: underline;
}
.content-section a {
  font-weight: 700;
}
a {
  color: #d40029;
  text-decoration: none;
}
/* commented to reduce space above the icons in careers page */
/* .content-section .row {
    margin-bottom: 30px;
} */
.content-section > .row {
  margin-bottom: 40px;
}
.country {
  display: inline-block;
  width: 50%;
  /* border-top: 1px solid #8a8c8f; */
  /* max-width: 1200px;
  margin: 0 auto; */
}
.offices-header {
  border-bottom: 1px solid #8a8c8f;
}
.country .state .offices-header {
  padding: 10px 0 10px;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #000;
  font-size: 1.75rem;
  line-height: 1.5em;
}
.offices-container .legacy-container .legacy-header {
  margin: 0 0 30px;
  padding: 10px 0 30px;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #000;
  border-bottom: 1px solid #8a8c8f;
  font-size: 2.25rem;
  line-height: 1.5em;
  margin-bottom: 20px;
}
.offices-container .legacy-container .legacy-plumbing-content .legacy-services {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: -20px;
}
.offices-container
  .legacy-container
  .legacy-plumbing-content
  .legacy-services
  a {
  color: #d40029;
}
.offices-container
  .legacy-container
  .legacy-plumbing-content
  .legacy-services
  a:hover {
  text-decoration: underline;
}
.country .state .location {
  /* margin-top: -50px; */
  display: inline-block;
  float: none;
  padding-right: 20px;
  padding-bottom: 10px;
  font-size: 1rem;
  line-height: 1.75em;
  margin-left: 0;
  text-align: left;
}
.country .state .location .name {
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  font-size: 1.275rem;
  line-height: 1.2727272727272727em;
}
.country .state .location .service-areas {
  font-family: "Roboto Condensed", sans-serif;
}

.service-areas {
  color: #63666a;
}

.country .state .location .phone {
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #000;
  font-size: 1.375rem;
  line-height: 1.6363636363636365em;
  margin-top: 0;
}

a.phone{
  display: block;
}

.offices-container .legacy-container .legacy-plumbing-content .phone {
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #000;
  font-size: 1.375rem;
  line-height: 1.6363636363636365em;
}
.offices-container .legacy-container {
  margin-bottom: 50px;
  margin-top: -80px;
}

.country .state .location .phone svg {
  width: 16px;
  margin-right: 5px;
}

.fa-phone:before {
  content: "\f095";
  font-size: 18px;
}

.country .state .location .links {
  margin-top: 0;
}

.country .state .location .links a {
  color: #d40029;
  margin-right: 20px;
  white-space: pre;
  font-size: 1.1rem;
}
.offices-container .legacy-plumbing-content .legacy-links a {
  color: #d40029;
  margin-right: 20px;
  white-space: pre;
  font-size: 1.1rem;
}
.more-link {
  color: #d40029 !important;
}

.country .state .location .service-areas a {
  color: #d40029 !important;
}

.offices-container .offices-heading {
  margin-top: -25px;
}

@media only screen and (min-width: 600px) {
  #state-list .state-list li {
    width: 33.33%;
  }
}

@media only screen and (min-width: 800px) {
  .offices-container {
    padding: 0 20px;
  }
  .offices-container .offices-heading {
    margin-top: 35px;
  }
  #state-list .state-list li {
    width: 16.66%;
  }
  /* commented to reduce space above the icons in careers page */
  /* .content-section .row {
        margin-bottom: 50px !important;
    } */
  .content-section > .row {
    margin-bottom: 0px;
  }
  .country .state .offices-header {
    font-size: 2.25rem;
    line-height: 1.1666666666666667em;
  }
  .country .state .location {
    padding-right: 60px;
  }
  .twelve .col-sd-12.col-md-6.col-ddd-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 1300px) {
  .offices-container {
    max-width: 1240px;
  }
}

@media only screen and (max-width: 600px) {
  .country .state .location .phone {
    color: #d40029;
  }
  .country .state .location {
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 800px) {
.country {
    display: block;
    width: 100%;
  }
}
