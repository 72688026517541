.preview-card {
  min-width: 200px;
  min-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px;
}

.preview-card p a {
  color: #d40029 !important;
}

.preview-card .preview-title {
  font-size: 20px;
}

.preview-card .preview-title a {
  color: #000000;
}

.preview-card .preview-title a:hover {
  color: #d40029 !important;
  text-decoration: none;
}
