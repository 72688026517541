.currently-employed input,
.contact-current-employer input {
  width: auto !important;
  cursor: pointer;
  margin-right: 10px;
}

.file-upload {
  background: none !important;
  padding-left: 0 !important;
}
