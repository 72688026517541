body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.meet-the-team-content .content-section .row {
    margin-bottom: 40px;
}

.meet-the-team-content .team-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.meet-the-team-content .bios-wrap .bio {
    overflow: auto;
    padding: 30px 30px 30px 0;
    border-top: 1px solid #ccc;
}

.meet-the-team-content .bios-wrap .bio .photo img {
    max-width: 100%;
}

.meet-the-team-content img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.meet-the-team-content img, iframe, object {
    vertical-align: middle;
}

.meet-the-team-content .bios-wrap .bio .title {
    padding: 20px 0 0 0;
    font-weight: 700;
    line-height: 1.2;
}

.meet-the-team-content .bios-wrap .bio .title .heading {
    font-size: 1.75rem;
    line-height: 1.3571428571428572em;
    font-family: 'Roboto Slab',serif;
    color: #000;
}

.meet-the-team-content p {
    margin: 0 0 20px;
    padding: 0 !important;
}

@media only screen and (min-width: 600px){
    .meet-the-team-content .twelve .col-md-6 {
        width: 50%;
    }

    .meet-the-team-content img {
        width: auto !important;
    }

    .meet-the-team-content .bios-wrap .bio .title {
        padding: 0 0 0 40px;
    }

    .meet-the-team-content .bios-wrap .bio .full-bio {
        padding: 40px 0 0 0;
    }
}

@media only screen and (min-width: 1300px){
    .meet-the-team-content .team-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px){
    .meet-the-team-content .content-section .row {
        margin-bottom: 60px;
    }

    .meet-the-team-content .team-container {
        padding: 0 20px;
    }

    .meet-the-team-content .bios-wrap .bio {
        padding: 60px 0;
    }

    .meet-the-team-content .twelve .col-dd-2 {
        width: 16.66%;
    }

    .meet-the-team-content .twelve .col-ddd-3 {
        width: 20%;
    }

    .meet-the-team-content .bios-wrap .bio .full-bio {
        padding: 0 0 0 40px;
    }

    .meet-the-team-content .twelve .col-ddd-7 {
        width: 56.33%;
    }
}

@media only screen and (max-width: 600px){
    .bio {
        /* changed margin-right from 20px !important to 0px !important because
            there was too much whitespace on right
        */
        margin-right:0px !important;

        /* added this because there was still some whitespace on right even after
            eliminating margin-right       
        */
        padding:0 !important;
    }

    /* in mobile view, team photo looked small, so adding this */
    .meet-the-team-content .twelve .col-dd-2 {
        width: 100%;
    }
}