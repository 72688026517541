.no-results {
    z-index: 2;
    position: absolute;
    left: 0;
    display: none;
    padding: 20px 30px 30px;
    color: #fff;
    text-align: center;
    background: #000;
    font-size: 1rem;
    line-height: 2em;
    -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,0.2);
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}

.no-results .close {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    font-weight: 700;
    color: #fff;
    background: #d40029;
    font-size: 1rem;
    line-height: 2.5em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 3px rgba(0,0,0,0.2);
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}
.no-results .close .closeIcon{
    margin-top: 12px;
}
.no-results a {
    font-weight: bold;
    /* display: block; */
    /* background: #191919; */
    /* padding: 15px; */
    margin-top: 10px;
    color: #fff;
}
.no-results a {
   text-decoration: underline;
}

a {
    color: #d40029;
    text-decoration: none;
}