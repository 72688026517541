.home .most-popular-services,
.services .most-popular-services {
  position: relative;
}

.most-popular-services,
.services .most-popular-services {
  position: relative;
}

/* added for paintstroke background */
/* .most-popular-services .heading-text:before {
    content: '';
    position: absolute;
    top: 0;
    left: -60px;
    display: block;
    width: 60px;
    height: 72px;
    background: transparent url(https://www.acehandymanservices.com/offices/north-metro-denver/images/paintstroke-dg-endcap-left.png) no-repeat center center;
    background: transparent url(https://services.ingeniux.com/acehandymandss/Corporate%20Site/Textures/paintstroke-bl-endcap-left.png) no-repeat center center;
    background-size: 100% 100%;
} */

/* added for paintstroke background */
.most-popular-services .heading-text {
  position: relative;
  display: inline-block;
  padding: 45px 0 !important;
  /* padding: 20px 0; */
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #fff;
  vertical-align: middle;
  height: 72px;
  /* background: transparent url(https://www.acehandymanservices.com/offices/north-metro-denver/images/paintstroke-dg-middle.png) no-repeat center center;
    background: transparent url(https://services.ingeniux.com/acehandymandss/Corporate%20Site/Textures/paintstroke-bl-middle.png) no-repeat center center;
    background-size: 100% 100%; */
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;
}

/* added for paintstroke background */
/* .most-popular-services .heading-text:after {
    content: '';
    position: absolute;
    top: 0;
    right: -30px;
    display: block;
    width: 30px;
    height: 72px;
    background: transparent url(https://www.acehandymanservices.com/offices/north-metro-denver/images/paintstroke-dg-endcap-right.png) no-repeat center center;
    background: transparent url(https://services.ingeniux.com/acehandymandss/Corporate%20Site/Textures/paintstroke-bl-endcap-right.png) no-repeat center center;
    background-size: 100% 100%;
} */

.iphoneview {
  display: none;
}

@media only screen and (min-width: 600px) {
  .most-popular-services.row {
    display: block;
    padding: 20px 0 30px;
  }

  .most-popular-services.row .heading-text {
    /* commented for paintstroke background */
    /* height: auto;
        margin: 0 auto 40px; */
    padding: 0;
    font-size: 2.725rem !important;
    line-height: 1.4;
    color: #fff;
    background-size: 100% 100%;
  }

  /* .most-popular-services.row .heading-text:before {
        left: -95px !important;
    } */

  /* .most-popular-services.row .heading-text:before, .most-popular-services.row .heading-text:after {
        width: 75px !important;
    } */

  /* .most-popular-services.row .heading-text:after {
        right: -95px !important;
    } */

  .most-popular-services.row .services {
    width: 100%;
    /* display: block; */
    display: flex;
    justify-content: center;
    padding-bottom: 0 !important;
  }

  .most-popular-services.row .services .item {
    display: block;
    width: calc(95% / 7);
    padding: 0;
  }

  /* added for paintstroke background */
  .most-popular-services .heading-text:before {
    top: 0;
    left: -169px;
    width: 169px;
    height: 158px;
  }

  /* added for paintstroke background */
  .most-popular-services .heading-text {
    height: 158px;
    margin: 0 auto 10px;
    padding: 0;
    font-size: 2.125rem;
    line-height: 158px;
    color: #fff;
    background-size: 100% 100%;
  }

  /* added for paintstroke background */
  .most-popular-services .heading-text:after {
    top: 0;
    right: -136px;
    width: 136px;
    height: 158px;
  }
}

@media only screen and (min-width: 800px) {
  .most-popular-services .row {
    margin-bottom: 60px;
  }

  .most-popular-services .most-popular-services-container {
    padding: 0 20px;
  }

  .most-popular-services.row .heading-text {
    font-size: 2.625rem;
  }

  .most-popular-services.row .services .item {
    width: calc(97% / 7);
    font-size: 1.25rem !important;
    line-height: 1.6em !important;
  }

  .most-popular-services.row .services .item a img {
    height: 60px;
  }

  .collapsible .most-popular-services.row .services {
    display: none !important;
  }

  /* added for paintstroke background */
  .most-popular-services .heading-text {
    font-size: 2.625rem;
    line-height: 158px;
  }
}

@media only screen and (min-width: 1300px) {
  .most-popular-services-container {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 1000px) {
  .most-popular-services.row .services .item a img {
    height: 87px !important;
  }
}

.most-popular-services-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  transition: max-width 0.3s ease;
}

.most-popular-services.row {
  text-align: center;
  /* ?background: #d40029e6; */
  margin: 0;
  background: linear-gradient(to bottom, #d40029 0, #a1001f 100%);
  mix-blend-mode: multiply;
}

.most-popular-services .row {
  margin-bottom: 40px;
}

.most-popular-services div {
  padding-top: 0.001em;
}

.most-popular-services .most-popular-services-container,
.services .most-popular-services .most-popular-services-container {
  z-index: 2;
  position: relative;
}

.most-popular-services.row .heading-text {
  position: relative;
  display: inline-block;
  padding: 20px 0;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  color: #fff;
  vertical-align: middle;
  background-size: 100% 100%;
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;
}

.most-popular-services .heading-text {
  color: #000;
}

/* .most-popular-services.row .heading-text:before {
    left: -45px;
} */

/* .most-popular-services.row .heading-text:before, .most-popular-services.row .heading-text:after {
    width: 25px;
    height: 1px;
    content: '';
    background: #fff;
    position: absolute;
    top: 50%;
    z-index: 2;
} */

/* *, *:before, *:after {
    box-sizing: inherit;
} */

/* .most-popular-services.row .heading-text:after {
    right: -45px;
} */

.most-popular-services.row .arrow {
  display: none;
}

.most-popular-services .fa,
.most-popular-services .fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.most-popular-services .fa,
.most-popular-services .fas,
.most-popular-services .far,
.most-popular-services .fal,
.most-popular-services .fad,
.most-popular-services .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.most-popular-services.row .services {
  /* display: none; */
  overflow: auto;
  padding-bottom: 20px;
  margin: 0 auto;
}

.most-popular-services.row .services .item {
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  opacity: 1;
  text-align: center;
  font-size: 0.8125rem;
  line-height: 2.4615384615384617em;
  -moz-transition: all 0.3 ease;
  -webkit-transition: all 0.3 ease;
  -ms-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  transition: all 0.3 ease;
}

.most-popular-services .col-sd-6 {
  width: 50%;
}

.most-popular-services.row .services .item a {
  cursor: pointer;
  display: block;
  color: #fff;
  font-weight: 400;
}

.most-popular-services.row .services .item a:hover {
  text-decoration: underline;
}

.most-popular-services a {
  font-weight: 700;

  color: #d40029;
  text-decoration: none;
}

.most-popular-services.row .services .item a img {
  height: 70px;
  fill: #fff;
  margin-bottom: 10px;
}

.most-popular-services.row .services .item a span {
  display: block;
}

@media only screen and (max-width: 600px) {
  .most-popular-services.row {
    background: #d40029;
  }
  .most-popular-services .heading-text {
    font-size: 1.3rem !important;
  }
  .notiphone {
    display: none;
  }

  .iphoneview {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    position: relative;
  }
 .home-page .iphoneview {
     margin-left: 6px !important;
    }
  .most-popular-services.row .iphoneview .heading-text {
    margin-top: -15px;
    padding: 20px 0 !important;
  }

  .most-popular-services.row .services.content.iphoneview {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
