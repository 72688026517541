.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}
.services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}
.tabb-content-wrap .tab-content.current {
    display: block !important;
    margin-top: 40px;
}
.tabb-content-wrap .tab-content {
    font-size: 0 !important;
}
.tabb-content-wrap .tab-content {
    display: none;
    margin-bottom: 0 !important;
    border: 1px solid #efeff0;
    padding: 20px;
    font-size: 1.3rem;
}
.tabb-content-wrap .tab-content div {
    display: inline-block;
    float: none;
    margin: 2vw 0;
}
h4 {
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
    margin: 0 0 20px;
    margin-right: 37px;
    
}
.services-name {
    text-align: start;
}
.tabb-content-wrap .tab-content div h4 a {
    color: #000;
}
.tabb-content-wrap .tab-content div h4 a:hover {
    text-decoration: underline;
}
.content-section a {
    font-weight: 700;
}
#search-by-room ul.accent-sq, #search-by-area ul.accent-sq, #search-by-service ul.accent-sq {
    margin: 20px 0 !important;
}
ul.accent-sq, .accordion ul {
    margin: 20px 0;
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
}
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;
}
.services-container ul.accent-sq li, .accordion ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
    font-size: 16px;
    padding-right: 20px;
}
ul.accent-sq li::before, .accordion ul li::before {
    content: "● ";
    position: absolute;
    margin-left: -20px;
    color: rgb(212, 0, 41);
}
.tabb-content-wrap .tab-content div ul a {
    color: #000;
}
.tabb-content-wrap .tab-content div ul a:hover {
    text-decoration: underline;
}
.services-container #search-by-room h4 {
    text-align: left;
}

@media only screen and (min-width: 1300px) {
    .services-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .services-container ul.accent-sq li {
        padding-right: 60px;
    }
    .services-container {
        padding: 0 20px;
    }
    .tabb-content-wrap .tab-content {
        padding: 0 !important;
    }
    .twelve .colm-dd-4 {
        width: 33.33% !important;
    }
    h4, .h4 {
        font-size: 1.5rem;
        line-height: 1.1666666666666667em;
        margin-left: -100px;
    }
}

@media only screen and (min-width: 600px) {
    /* .services-container {
        margin-top: 45px;
        margin-left: 40px;
    } */
    .content-wrap>.row {
        margin-bottom: 40px;
    }
    .tabb-content-wrap .tab-content {
        padding: 20px 0 !important;
        border: 0 solid #efeff0;
    }
    .tabb-content-wrap .tab-content div {
        margin: 1vw 0;
        /* padding: 0 2vw 0 0; */
    }
    .tabb-content-wrap .tab-content.current {
        display: block;
        margin-top: 20px;
        text-align: start;
    }
}
