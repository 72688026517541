.footer-corporate{
  position:relative;
  z-index: 11;
}

@media only screen and (max-width: 820px) {
  footer .foot-wrap #foot-mid {
    color: #000;
    text-align: left;
    margin-top: 0;
  }
}
footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg {
  display: inline-block;
  width: 17px;
  fill: #d40029;
  margin-right: 5px;
}
footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg img {
  width: auto;
  fill: #d40029;
  margin-right: 5px;
}

footer .foot-wrap {
  padding: 40px 0 20px;
  background: #fff;
  box-shadow: 0 -2px 3px rgb(0 0 0 / 10%);
  position: relative;
  z-index: 1000;
}
footer .foot-wrap #foot-left {
  text-align: center;
}
footer .foot-wrap #foot-left a.logo {
  vertical-align: middle;
}
footer .foot-wrap #foot-left a.logo img {
  width: 35% !important;
}
a {
  color: #d40029;
  text-decoration: none;
}
footer .foot-wrap #foot-left .call-cta {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 25px;
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}
footer .foot-wrap #foot-left .call-cta span {
  font-weight: 700;
  color: #000;
}
footer .foot-wrap #foot-left .call-cta .footer-phone {
  font-size: 1.375rem;
  line-height: 1.2727272727272727em;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div {
  color: #d40029;
  font-weight: 700;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div span,
footer .foot-wrap #foot-left .call-cta .footer-phone > div a {
  color: #d40029;
}
footer .foot-wrap #foot-left .call-cta .footer-phone > div a:hover {
  text-decoration: underline;
}
footer .foot-wrap #foot-mid .resources:first-child {
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .resources1:first-child {
  /* To adjust footer mid content */
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .resources {
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .resources1 {
  /* To adjust footer mid content */
  margin-bottom: 25px;
}
footer .foot-wrap #foot-mid .heading {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  color: #000;
}
footer .foot-wrap #foot-right .back-to-top-wrap svg {
  width: 13px;
  height: 20px;
}
footer .foot-wrap .copy-wrap p {
  text-align: center;
  padding: 0 10px 0 0 !important;
}
footer .foot-wrap #foot-mid .resources ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}
footer .foot-wrap #foot-mid .resources ul li {
  list-style: none;
}
footer .foot-wrap #foot-mid .resources ul a {
  color: #000 !important;
  font-size: 1.1rem;
  line-height: 2.1em;
  font-weight: 400;
}
footer .foot-wrap #foot-mid .resources ul a:hover {
  text-decoration: underline;
}

footer .foot-wrap #foot-mid .resources1 ul {
  /* To adjust footer mid content */
  margin: 0 !important;
  margin-right: 75px !important;
  padding: 0;
  list-style: none;
}
footer .foot-wrap #foot-mid .resources1 ul li {
  /* To adjust footer mid content */
  list-style: none;
  margin-right: -62px;
}
footer .foot-wrap #foot-mid .resources1 ul a {
  /* To adjust footer mid content */
  color: #000 !important;
  font-size: 1.1rem;
  line-height: 2.1em;
  font-weight: 400;
}
footer .foot-wrap #foot-mid .resources1 ul a:hover {
  /* To adjust footer mid content */
  text-decoration: underline;
}
footer .foot-wrap #foot-right {
  text-align: center;
}
footer .foot-wrap #foot-right .back-to-top-wrap {
  display: inline-block;
  padding: 6px;
  color: #000;
  text-align: center;
  cursor: pointer;
  border: 1px solid #000;
}
footer .foot-wrap #foot-right .back-to-top-wrap:hover {
  border: 1px solid #d40029;
}
footer .foot-wrap #foot-right .back-to-top-wrap a:hover {
  color: #d40029;
}
footer .foot-wrap #foot-right .back-to-top-wrap a {
  color: #000;
}

footer .foot-wrap #foot-right .back-to-top-wrap span {
  display: block;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.5625rem;
  line-height: 1.3333333333333333em;
}
.twelve .col-sd-12 {
  width: 100%;
}
footer .foot-wrap .copy-wrap {
  margin-top: 20px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.7em;
}
footer .foot-wrap .social-wrap #social-wrap {
  line-height: 1;
  text-align: center;
}
#social-wrap ul.social-links {
  display: block;
  margin: 0 15px 0 0;
  list-style: none;
}
#social-wrap ul.social-links li.social {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  text-align: center;
}
#social-wrap ul.social-links li.social a {
  display: block;
  width: 30px;
  height: 30px;
  color: #b1b2b4;
  font-size: 0;
  transition: all 0.3s ease;
}
#social-wrap ul.social-links li.social a i {
  vertical-align: middle;
}
#social-wrap ul.social-links li.social a i:hover {
  color: #d40029;
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
.fab {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
#social-wrap ul.social-links li.social a i:before {
  /* changed font size from 1.5rem to 1.125rem !important */
  font-size: 1.125rem !important;
  line-height: 1em;
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-linkedin:before {
  content: "\f08c";
}

.footer-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}


footer .lf-cta-wrap {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #000;
}

footer .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

/* isolating commented elements */
/* footer .lf-cta-wrap .container */
.wrap-center {
  float: none;
  margin: 0 auto;
}

/* isolating commented elements */
/* footer .lf-cta-wrap .container  */
.wrap-center .cta-left {
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.8666666666666667em;
}

.wrap-center .cta-left p span {
  font-size: 0.9375rem;
  line-height: 1.8666666666666667em;
}

footer .twelve .col-sd-12 {
  width: 100%;
}
.wrap-center .col-sd-12 {
  width: 100%;
}


.wrap-center .cta-left span {
 
  font-size: 1.4375rem;
  line-height: 1.2173913043478262em;

  color: #fff;
}


.wrap-center .cta-left h3 span {
  display: block;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  font-size: 1.4375rem;
  line-height: 1.2173913043478262em;
}

.wrap-center .cta-left h3 {
  margin: 0;
}


.wrap-center .cta-left p {
  margin: 0;
}


.wrap-center .cta-right form {
  color: #fff;
  font-size: 0;

  position: relative;
}


.wrap-center .cta-right .input {
  display: inline-block;
  width: calc(100% - 115px);
  vertical-align: top;
}


.wrap-center .cta-right .input input {
  margin-top: 5px;
  font-size: 0.9375rem;
  line-height: 1.4666666666666666em;

  width: 100%;
  margin: 0 0 1px;
  padding: 15px;
  outline: 0;
  border: 0;
  background: #efeff0;
  color: #8a8c8f;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-style: normal;
  font-weight: normal;
 
  font-family: "Roboto Condensed", sans-serif;
}

.wrap-center .cta-right .error input {
  color: red !important;
  background: #fee !important;
}

.wrap-center .cta-right .search {
  display: inline-block;
  width: 90px;
  vertical-align: top;
}


.wrap-center .cta-right .search button {
  width: 100%;
  margin-top: 0;
  font-size: 0.9375rem;
  line-height: 1.4666666666666666em;
}

footer .submit {
 
  padding: 15px 20px;
 
}
.wrap-center .submit {
  padding: 15px 20px;
}

footer .btn {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  
  font-family: "Roboto", sans-serif;
 
  cursor: pointer;
 
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.privacychoices img {
  width: 22px !important;
}

.wrap-center .btn {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
 
  cursor: pointer;
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.wrap-center .cta-right .instructions {
  color: #d6d6d7;

  font-size: 0.75rem;
  line-height: 2.1666666666666665em;
  opacity: 1;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.yo-corporate.footer {
  text-align: center;
  font-size: 20px;
  width: 100%;
}

.yo-corporate.footer img {
  height: 18px;
}

footer .foot-wrap #foot-mid {
  text-align: center;
  margin-top: 25px;
}

footer .foot-wrap #foot-mid .heading {
  font-size: 1.2rem;
}

footer .foot-wrap #foot-left .call-cta .footer-phone .yo-local {
  display: none;
}

/* make HANDYMAN MATTERS text bold */
.cta-right p span p span:nth-of-type(2) {
  font-weight: bold;
}

@media only screen and (min-width: 1300px) {
  footer .container {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 800px) {
 
  footer .foot-wrap #foot-mid .resources1 {
    margin-left: -100px !important;
  }
  footer .foot-wrap #foot-left .call-cta .footer-phone .phone-svg img {
    height: 17px;
   
  }
  footer .foot-wrap {
    padding: 60px 0 80px;
  }
  footer .foot-wrap #foot-left {
    margin-bottom: 0;
  }
  .twelve .col-dd-5 {
    width: 41.66% !important;
  }
  footer .foot-wrap #foot-left a.logo {
    display: block;
  }
  footer .foot-wrap #foot-left a.logo img {
   
    width: 100% !important;
  }
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    border-top: 1px solid #000;
    border-left: 0 solid #000;
  }
  .twelve .colm-dd-6 {
    width: 50% !important;
  }
  .twelve .col-dd-1 {
    width: 8.33%;
  }
  footer .foot-wrap #foot-bottom {
    margin-top: 0px;
    display: flex;
    align-items: flex-end;
  }
  footer .foot-wrap .copy-wrap {
    position: relative;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1.5em;
  }
  .twelve .col-dd-9 {
    width: 75%;
  }
  footer .foot-wrap .copy-wrap p {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
  }
  footer .foot-wrap .copy-wrap span {
    display: inline-block;
  }
  footer .foot-wrap .copy-wrap span.divider {
    display: inline-block;
  }
  footer .foot-wrap .copy-wrap a {
    color: #63666a;
    font-weight: 100;
  }
  footer .foot-wrap .copy-wrap a:hover {
    text-decoration: underline;
  }
  footer .foot-wrap .copy-wrap p {
    text-align: left;
  }
  footer .foot-wrap .social-wrap {
    margin: 0;
    position: relative;
    text-align: right;
  }
  .twelve .col-dd-3 {
    width: 25%;
  }
  #social-wrap {
    text-align: right;
  }
  .ff-cta-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .footer-container {
    padding: 0 20px;
  }
  .twelve .col-dd-8 {
    width: 66.66%;
  }
  .wrap-center .col-dd-8 {
    width: 66.66%;
  }

  footer .foot-wrap #foot-mid .resources1 ul {
    margin-right: 0px !important;
  }

  footer .foot-wrap #foot-left {
    margin-left: -50px !important;
  }
 
  .twelve .col-dd-6 {
    width: 50% !important;
  }
  .wrap-center .col-dd-6 {
    width: 50% !important;
  }
 
  .twelve .colm-dd-6 {
    width: 180% !important;
    margin-top: -80px;
  }

  footer .lf-cta-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  footer .container {
    padding: 0 20px;
  }

  footer .twelve .col-dd-8 {
    width: 66.66%;
  }

 
  .wrap-center .cta-left {
  
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
  }


  .wrap-center .cta-left p span {
    padding-right: 20px;
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
  }


  .wrap-center .cta-left h3 span {
    font-size: 1.625rem;
    line-height: 1.3846153846153846em;
  }

 
  .wrap-center .cta-left h3 {
    margin: 0;
  }

  .wrap-center .cta-left p {
    margin: 0;
  }

  footer .twelve .col-dd-6 {
    width: 50%;
  }

  
  .wrap-center .cta-left span {
    font-size: 1.625rem;
    line-height: 1.3846153846153846em;
  }

  .links-line {
    display: flex;
    flex-direction: row;
    width: 110%;
  }
}

@media only screen and (max-width: 600px) {
  footer .foot-wrap #foot-right {
    position: absolute;
    right: 10%;
    text-align: right;
  }
}

@media only screen and (min-width: 600px) {
  .twelve .col-md-10 {
    width: 83.33%;
  }
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-left: 1px solid #000;
  }
  footer .foot-wrap #foot-left a.logo {
    display: inline-block;
  }
  footer .foot-wrap #foot-left {
    margin-bottom: 20px;
  }
  footer .foot-wrap #foot-left a.logo img {
    display: block;
    width: 136px;
    height: 95px;
  }
  footer .foot-wrap #foot-mid {
    color: #000;
    text-align: left;
    margin-top: 0;
  }
  footer .foot-wrap #foot-mid .resources {
    margin: 0;
    margin-left: 75px !important;
  }
  footer .foot-wrap #foot-mid .resources1 {
    margin: 0;
    margin-left: -75px;
  }
  footer .foot-wrap #foot-mid .heading {
    font-weight: 700;
    font-size: 1rem;
    line-height: 2em;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap {
    padding: 11px !important;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap svg {
    width: 20px;
    height: 26px;
  }
  footer .foot-wrap #foot-right .back-to-top-wrap span {
    font-size: 0.7rem;
    line-height: 1.3333333333333333em;
  }
}

@media only screen and (min-width: 980px) {
  footer .foot-wrap #foot-left .call-cta {
    display: inline-block;
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-top: 0 solid #000;
    border-left: 1px solid #000;
    text-align: left;
  }
  footer .foot-wrap #foot-left {
    text-align: center;
    margin-bottom: 20px;
  }
  footer .foot-wrap #foot-left a.logo {
    display: inline-block;
  }
}

@media only screen and (min-width: 1300px) {
  .footer-container {
    max-width: 1240px;
  }
}
