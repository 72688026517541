.mobile-nav-slide {
    z-index: 1000;
    /* position: fixed; */
    top: 0;
    right: -100%;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: right .3s ease-out;
}

.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
    content: "\f00d";
    font-size: 15px;
}

.franchise-home i.fa-bars:before {
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    /* font-family: 'Font Awesome 5 Free'; */
    font-weight: 900;
    content: "\f0c9";
    font-size: 1.625rem;
    line-height: 2em;
    width: 100%;
    height: 100%;
    color: #fff;
}

.mobile-nav-slide ul {
    margin: 0 !important;
    padding: 0;
}

.container {
    width: 100% !important;
    padding: 0 !important;
}

.mobile-nav-slide ul li.top,
.mobile-nav-slide ul li.back {
    display: flex;
    height: auto;
    line-height: 200%;
    background-color: #d40029;
}

.mobile-nav-slide ul li {
    position: relative;
    border-bottom: 1px solid #d5d5d7;
    list-style: none;
}

.mobile-nav-slide ul li.top a,
.mobile-nav-slide ul li.back a {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #efeff0;
    font-size: .8125rem;
    line-height: 1.3846153846153846em;
    align-self: flex-end;
}

.mobile-nav-slide ul li>a {
    /* display: block; */
    display: flex;
    padding: 15px;
    padding: 12px 0 12px 10%;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    line-height: 1em;
}

.mobile-nav-slide ul li.top a i,
.mobile-nav-slide ul li.back a i {
    margin-right: 5px;
    /* margin-top: -10px; */
    font-size: 1rem;
    line-height: 1em;
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fas {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

/* .mobile-nav-slide ul li>a:hover {
    color: #d40029;
} */
.mobile-nav-slide ul li.has-dropdown>i.fa-solid {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 31px;
    display: block;
    height: 70%;
    padding: 12px 15px 0;
    color: #63666a;
    background: #efeff0;
    font-size: 1.125rem;
    line-height: 1em;
}

.mobile-nav-slide ul li .dropdown-wrap {
    z-index: 10;
    position: fixed;
    top: 0;
    right: -100%;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: right .3s ease-out;
}

ul {
    list-style: none;
}

.mobile-nav-slide ul li .dropdown-wrap .back a {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .725rem;
    line-height: 1.2307692307692308em;
}

.mobile-nav-slide ul li .dropdown-wrap .category a {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.125em;
}

.mobile-nav-slide ul li .dropdown-wrap a {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-transform: none;
    font-size: .9375rem;
    line-height: 1em;
}

header {
    position: relative;
    background: #fff;
    width: 100%;
    z-index: 990 !important;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
}

header #alert-bar {
    background-color: #000;
    color: #fff;
    text-align: center;
}

header #alert-bar p {
    color: #fff;
    margin: 0;
    line-height: 1.4em;
    font-size: .6em;
}

header #alert-bar p center>* {
    color: #fff;
    font-weight: 400;
    display: block;
    padding: 15px 5px;
}

header #alert-bar p center>*:hover {
    text-decoration: underline;
}

strong {
    font-weight: bold;
}

header>.yo-local {
    display: none;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    background: #d40029;
    font-size: .8125rem;
    line-height: 1em;
}

header>.yo-local .topbar-wrap {
    padding: 10px 0;
}

header>.yo-local .name {
    display: inline-block;
}

header .franschise-head-wrap .logo {
    padding: 10px 0;
    text-align: left;
}

header .franschise-head-wrap .logo svg {
    max-width: 100%;
    width: 144px;
    height: 47px;
}

header .franschise-head-wrap .header-top-wrap {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 7px;
}

header .franschise-head-wrap .header-top-wrap .header-top {
    display: inline-block;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    text-align: right;
    text-decoration: none;
}

header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors a {
    margin-left: 20px;
    /* color: #000; */
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .785rem;
    line-height: 1em;

    /* font-size: 11px; */
    font-weight: 700;
}

header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors a:hover {
    /* color: #d40029; */
    text-decoration: underline;
}

header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors a img {
    width: 16px;
    height: 16px;
}

header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors a span {
    margin-left: 7px;
    position: relative;
    /* commented because underline was striking through text instead of under it */
    /* top: 2px; */
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone.franchise-home {
    font-size: .1em;
    display: inline-block;
    /* margin-left: 20px; */
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone.franchise-home div img {
    height: 11px;
    width: auto;
    fill: #fff;
    margin-right: 5px;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone>div {
    display: inline-block;
    font-size: 0.9rem;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone.franchise-home a {
    color: #fff;
    top: 3px;
    position: relative;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone a:hover {
    text-decoration: underline;
}

.mobile-nav-slide .first-menu {
    margin: 0 !important;
}

#mobile-nav {
    cursor: pointer;
    display: block;
    margin-left: 20px;
    text-align: left;
    transition: background .3s ease;
}

.mobile-nav-slide .first-menu {
    margin: 0 !important;
}

.franchise-topnav {
    z-index: 1000;
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    min-width: 150px;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: left;
    background: #d40029;
    box-shadow: 0 5px 5px rgb(0 0 0 / 25%);
}

.franchise-topnav ul {
    position: static;
    margin: 0 !important;
    padding: 0;
    border: 0;
    background: 0;
}

.franchise-topnav ul li {
    position: static;
    display: block;
    margin: 0;
    font-weight: 400;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1em;
}

.franchise-topnav ul li a {
    display: block;
    margin: 0;
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #000;
    transition: all .3s ease;
    font-weight: bold;
}

.franchise-topnav .dropdown {
    display: block;
    background: rgba(0, 0, 0, 0.95);
}

.franchise-topnav .dropdown .home-container {
    padding: 0;
    max-width: 100%;
}

.franchise-topnav .dropdown .dropdown-nav {
    float: none;
    width: 100%;
}

.franchise-topnav ul li ul li {
    font-weight: 400;
    font-size: .8125rem;
    line-height: 1em;
    width: 25%;
}

.franchise-topnav ul li ul li a {
    padding: 10px 0 10px 30px;
    font-family: 'Roboto', sans-serif;
    text-transform: none;
}

ul li {
    list-style: disc outside;
}

.franchise-topnav ul li a:hover {
    color: #fff;
    text-decoration: none;
}

.mobile-nav-slide.active {
    right: 0;
}

#content-wrapper {
    position: relative;
    justify-self: flex-end;
    background-color: white;
}

#page-wrapper {
    position: relative;
    /* display: flex; */
    width: auto;
    justify-content: space-between;
}

.home-container {
    /* width: 100%; */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

header>.yo-local .local-nav {
    display: none;
}

header .franschise-head-wrap .logo img {
    max-width: 100%;
    width: 144px !important;
    height: 47px;
}

header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors {
    display: none;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone .yo-local {
    display: none;
}

.franchise-topnav .dropdown .header {
    display: none;
}

.sticky-wrapper {
    /* height: 124px !important; */
    height: 100px !important;
}

.sticky-wrapper.is-sticky {
    width: 100%;
    position: fixed;
    z-index: 1000;
}

.yo-corporate {
    width: 125px;
    font-size: 10px;
    font-weight: 800;
    white-space: nowrap;
}

.yo-corporate img {
    height: 10px;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone.franchise-home div svg {
    height: 12px;
    width: auto;
    fill: #fff;
    margin-right: 5px;
}

header .mobile-ctas {
    border-bottom: 1px solid #fff;
}

header .mobile-ctas a {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    /* vertical-align: middle; */
    text-align: center;
    display: block;
    padding: 10px 0;
    color: #fff;
    /* font-size: .8125rem; */
    font-size: 0.765rem;
    line-height: 1em;
}

header .mobile-ctas a img {
    width: 16px;
    height: 16px;
    fill: #fff;
}

header .mobile-ctas a span {
    margin-left: 7px;
    position: relative;
    /* top: -2px; */
    top: 2px;
}

header .franschise-head-wrap .header-top-wrap .header-top .header-phone div svg {
    margin-bottom: -5px;
}

/* .franschise-head-wrap {
    margin-top:-100px;
} */

@media only screen and (min-width: 800px) {
    header .mobile-ctas {
        display: none !important;
    }

    header .franschise-head-wrap .logo {
        padding: 15px 0 0 !important;
    }

    header .franschise-head-wrap .header-top-wrap {
        margin-top: 0 !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone>div {
        font-size: 1.3rem !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone.franchise-home div svg {
        height: 17px;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone a {
        top: 2px;
    }

    header .yo-local {
        text-align: right !important;
    }

    header .yo-local .name {
        margin-right: 0;
        padding-right: 15px;
        border-right: 1px solid #fff;
    }

    header .yo-local .local-nav {
        display: inline-block;
        color: #fff;
        text-decoration: none;
    }

    header .franschise-head-wrap .header-top-wrap {
        display: block !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top {
        border-bottom: 1px solid #efeff0 !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top {
        /* padding: 10px 0 6px !important; */
        padding: 10px 11px 6px 0 !important;
        width: 520px !important;
        height: 51.02px !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors {
        display: inline-block !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone {
        font-size: 1.375rem !important;
        line-height: 1em !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone div img {
        height: 17px;
        margin-bottom: -2px;
    }

    #mobile-nav {
        padding: 8px 10px !important;
        margin: 0 !important;
        margin-right: 0px !important;
        text-align: right !important;
    }

    .twelve .col-dd-3 {
        width: 25% !important;
    }

    .twelve .col-dd-9 {
        width: 75% !important;
    }

    .home-container {
        padding: 0 9px !important;
    }

    header #alert-bar p {
        font-size: .9em;
    }

    header .yo-local {
        text-align: right;
    }

    header .franschise-head-wrap .logo img {
        /* width: 170px !important; */
        /* height: 83px; */
        width: 100% !important;
        height: 100% !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone div svg {
        height: 17px;
        margin-bottom: -5px;
    }
}

@media only screen and (max-width: 799px) {
    header .franschise-head-wrap .logo img {
        width: 144px !important;
        height: 100%;
    }
    .yo-corporate {
        width: auto;
        margin-right: 35px;
    }
}

@media only screen and (min-width: 600px) {
    .home-container {
        padding: 0 9px;
    }

    header .mobile-ctas {
        display: none;
    }

    header .franschise-head-wrap .logo {
        padding: 15px 0 0;
    }

    .twelve .col-dd-3 {
        width: 25% !important;
    }

    header .franschise-head-wrap .logo img {
        width: 100% !important;
        height: 100%;
    }

    header .franschise-head-wrap .header-top-wrap {
        margin-top: 0;
        display: block;
    }

    .twelve .col-dd-9 {
        width: 75% !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top {
        /* padding: 10px 0 6px; */
        padding: 10px 10px 6px 0;
        width: 520px;
        height: 51.02px;
        border-bottom: 1px solid #efeff0;
        text-align: end !important;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .franschise-anchors {
        display: inline-block;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone {
        font-size: 1.375rem !important;
        line-height: 1em;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone>div {
        font-size: 1.3rem;
        margin-right: 0;
    }

    header .yo-local {
        text-align: right;
    }

    #mobile-nav {
        padding: 8px 10px;
        margin: 0;
        margin-right: 0px;
        text-align: right;
    }

    .mobile-nav-slide {
        right: -300px;
        width: 300px;
    }

    .mobile-nav-slide ul li .dropdown-wrap {
        right: -300px;
        width: 300px;
    }

    .twelve .col-md-12 {
        width: 100%;
    }

    header #alert-bar p {
        font-size: .8em;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone {
        font-size: 1em;
        margin-left: 20px;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone div img {
        height: 14px;
    }

    .sticky-wrapper {
        height: 168px;
    }

    .sticky-wrapper.is-sticky {
        width: 100%;
        position: fixed;
        z-index: 1000;
    }

    .yo-corporate {
        width: 100% !important;
        font-size: 20px;
        font-weight: 700;
    }

    .yo-corporate img {
        height: 17px;
    }

    .mobile-nav-slide ul li.has-dropdown>i.fa-solid {
        right: 100px;
    }

    header .franschise-head-wrap .header-top-wrap .header-top .header-phone div svg {
        height: 14px;
    }
}

@media only screen and (min-width: 1040px) {
    .mobile-nav-slide {
        display: none;
    }

    header #alert-bar p {
        font-size: 1em;
    }

    header .franschise-head-wrap .logo img {
        /* width: 203px; */
        /* width: 270px !important; */
        width: 100% !important;
        height: 110px;

        margin-bottom: -100px;
        margin-top: -13px;
        vertical-align: top;
    }

    #mobile-nav {
        display: none;
    }

    .franchise-topnav {
        position: static;
        display: inline-block !important;
        width: auto !important;
        max-height: 60px;
        margin-top: 10px;
        padding: 0;
        background: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .franchise-topnav ul {
        margin: 0 !important;
        padding-left: 10px;
        text-align: left;
    }

    .franchise-topnav ul li {
        display: inline-block;
        margin: 0;
        margin-right: -4px;
        font-weight: 400;
        border: 0;
        background: 0;
        font-size: 0.95rem;
        line-height: 1em;
        -moz-transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
    }

    .franchise-topnav ul li a {
        display: block;
        padding: 20px 15px;
        color: #fff;
        border-bottom: 0;
    }

    .franchise-topnav ul li .dropdown {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        padding: 1vw 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.85);
        transition: opacity .3s ease;
    }

    .franchise-topnav ul li .dropdown .home-container {
        display: flex;
        align-items: center;
        max-width: 1240px;
    }

    .franchise-topnav ul li .dropdown .header {
        display: block;
        font-family: 'Roboto Slab', serif;
        font-size: 1.75rem;
        line-height: 1em;
    }

    .franchise-topnav ul li .dropdown .header a {
        border-bottom: 0;
        background: 0;
    }

    .franchise-topnav ul li ul {
        z-index: 996;
        width: auto;
        margin: 0;
        padding: 0;
        text-align: left !important;
        overflow: auto;
    }

    .franchise-topnav ul li ul li {
        display: inline-block;
        /* float: left; */
        height: auto;
        margin: 0;
        padding: 0 !important;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-size: .9375rem;
        line-height: 1em;
    }

    .franchise-topnav ul li .dropdown .dropdown-nav a {
        text-align: left;
        border-bottom: 0;
        background: 0;
    }

    .franchise-topnav ul li ul li a {
        height: auto;
        margin: 0;
        padding: 10px 30px !important;
        color: #fff;
        text-transform: none;
        background: 0;
        position: relative;
    }

    .franchise-topnav ul li .dropdown .header a:hover {
        color: #fff;
        text-decoration: underline;
    }

    .franchise-topnav ul li .dropdown .header a {
        border-bottom: 0;
        background: 0;
    }

    .franchise-topnav ul li a {
        display: block;
        padding: 20px 15px;
        border-bottom: 0;
    }

    .franchise-topnav ul li:hover .dropdown {
        top: 100%;
        opacity: 1;
    }

    .franchise-topnav ul li .dropdown {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        padding: 1vw 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.85);
        -moz-transition: opacity .3s ease;
        -webkit-transition: opacity .3s ease;
        -ms-transition: opacity .3s ease;
        -o-transition: opacity .3s ease;
        transition: opacity .3s ease;
    }

    .franchise-topnav ul li:hover a {
        color: #fff;
        background: #000;
    }

    .franchise-topnav ul li .dropdown .dropdown-nav a:hover {
        color: #fff;
        text-decoration: underline;
    }
}

@media only screen and (min-width: 1300px) {
    .home-container {
        max-width: 1240px;
    }
}

@media only screen and (max-width: 800px) {
    /* .yo-corporate {
        width: auto !important;
        margin-right: 35px;
    } */

    .yo-corporate svg {
        height: 17px !important;
    }
    .yo-corporate a {
        font-size: 1.2rem;
    }
}

/* commented to fix misalignment of alert above corporate header*/
/* @media only screen and (min-width: 800px){
    .home-container {
        display: flex;
    }
} */



.franchise-header {
    background-color: #d40029
}