a {
  color: #63666a;
  text-decoration: none;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6;
}
blockquote > :last-child {
  margin-bottom: 0 !important;
}
blockquote p {
  margin-bottom: 1.4736842105em;
  text-align: center;
}
blockquote {
  color: #d40029;
  font-weight: 400;
  font-family: "ACE Medium", serif;
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;
  text-align: center;
  margin: 30px 0;
  position: relative;
}
.wp-block-quote {
  overflow-wrap: break-word;
}
.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin-bottom: 1em;
  padding: 0 1em;
}
.entry-content:before {
  content: "";
  display: table;
}
.blog-entry-header .entry-title {
  font-family: "Roboto Slab", serif;
}
.blog-entry-header .entry-title {
  hyphens: auto;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 1em;
}
h2 {
  color: #000;
  font-family: "Roboto Slab", serif;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
}
.blog-entry-header .entry-title a {
  color: #000;
}
.blog-entry-header .entry-title a:hover {
  color: #d40029;
  text-decoration: none;
}

.entry-content {
  border-color: #d1d1d1;
}
p {
  font-size: 18px;
  line-height: 1.7;
  overflow-wrap: break-word;
}
.has-inline-color.has-vivid-red-color {
  color: #cf2e2e;
  font-weight: 100;
}
.entry-content em {
  font-style: normal !important;
}
.entry-content h2 {
  font-weight: 900;
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 1.2173913043;
  margin-top: 2.4347826087em;
  margin-bottom: 1.2173913043em;
}
.entry-content h3 {
  font-weight: 900;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.1052631579;
  margin-top: 2.9473684211em;
  margin-bottom: 1.4736842105em;
}
h3 {
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
}
.entry-content h5 {
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3125;
  margin-top: 3.5em;
  margin-bottom: 1.75em;
}
h5 {
  font-family: "Roboto Condensed", sans-serif;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
  color: #63666a;
}
p > iframe {
  margin-bottom: 0;
  vertical-align: middle;
  width: 100%;
}
em {
  font-style: normal;
}
.entry-content {
  margin-right: 7.6923%;
  margin-left: 7.6923%;
}
.entry-content > :last-child {
  margin-bottom: 0;
}
.entry-content a {
  box-shadow: 0 1px 0 0 currentColor;
}
.entry-content a img {
  display: block;
  /* width: 22% !important; */

  /* commenting this because it is overriding image size set in cms */
  /* width:180px !important;
    height: 48px; */
}
.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}
a img {
  border: 0;
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.entry-content:after {
  clear: both;
  content: "";
  display: table;
}

.booktodaybtn {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: 700;
  /* font-size: .9375rem;
    line-height: 1.3333333333333333em; */
  font-family: "Roboto", sans-serif;
  /* padding: 15px 30px; */
  cursor: pointer;
  /* margin-top: 10px; */
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.bookbtn {
  display: inline-block;
  text-align: center;
  /* background: #d40029; */
  color: #fff;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.3333333333333333em;
  font-family: "Roboto", sans-serif;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 10px;
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* added this because numbered list items in some blog contents were being clipped*/
.blogContent li {
  margin-left: 20px;
}

/* Image Max Height */
.blogContent img {
  max-height: 400px;
  width: auto !important;
  /* display:block;
  margin:auto */
}

@media screen and (min-width: 600px) {
  .blogContent img {
    width: auto!important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
  }
}

@media screen and (min-width: 56px) {
  .blog-entry-header .entry-title {
    padding: 0 20px !important;
  }
}

@media screen and (min-width: 800px) {
  .blog-entry-header .entry-title {
    padding: 0 !important;
  }
}

@media screen and (min-width: 44.375em) {
  .blog-entry-header .entry-title {
    font-size: 33px;
    font-size: 2.0625rem;
    line-height: 1.2727272727;
    margin-bottom: 0.8484848485em;
  }
  .entry-content blockquote:not(.alignleft):not(.alignright) {
    margin-left: -1.473684211em;
  }
}

@media screen and (min-width: 56.875em) {
  .blog-entry-header,
  .entry-content {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
