.detail-page-banner {
  position: relative;
  background-color: #63666a;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
#top {
  margin-bottom: 90px !important ;
}
@media only screen and (min-width: 800px) {
  #detail-banner {
    margin-top: 0 !important;
  }
  .container {
    padding: 0 20px;
  }
  .detail-page-banner .container {
    /* changed padding-top and bottom both from 50px to 0px and added !important
            because it wasn't working otherwise. this makes the banners shorter
        */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .banner-heading-container {
    text-align: center;
    max-width: max-content;
  }
  .banner-heading-container .header {
    padding: 10px 20px 90px 275px;
    color: #fff;
    background-repeat: repeat-x;
    font-size: 2.375rem !important;
  }
  .banner-heading-container .paintstroke-right {
    width: 71px;
    height: 212px;
    /* height: 100px; */
  }
}

@media only screen and (max-width: 800px) {
  #detail-banner {
    /* changed margin to from 4.2rem to 11.2rem !important for mobile view
        and for image not to get clipped on top
        */
    margin-top: 7.4rem !important;
  }

  .container {
    padding: 0 20px;
  }

  .detail-page-banner .container {
    /* changed padding top and bottom both from 50px to 0px !important
            so that the banner image is shorter for mobile view
        */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  
  .banner-heading-container {
    display: flex;
    max-width: max-content;
    position: relative;
    z-index: 2;
  }

  .banner-heading-container .header {
    background-image: none !important;
    color: #fff;
    font-size: 1.75rem !important;
    margin-top: 140px;
    margin-bottom: -30px;
    margin-left: 50px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 799px) {
  #detail-banner {
    margin-top: 0rem !important;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}
