#careers-culture-content .careers-culture {
    /* background: #efeff0 url(https://www.acehandymanservices.com/images/banners/careers-culture-xl.jpg) no-repeat top right -275px; */
    padding: 50px 0;
    margin-bottom: 0 !important;
    padding-right: 28px;
}

#careers-culture-content .content-section>.row {
    margin-bottom: 40px;
}

#careers-culture-content .content-section .row {
    margin-bottom: 40px;
}

#careers-culture-content .careers-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

#careers-culture-content .col-sd-12 {
    width: 100%;
}

#careers-culture-content .careers-culture .culture-text {
    background: rgba(255,255,255,0.8);
    padding: 20px 20px 10px 20px;
    margin-bottom: 30px;
}

#careers-culture-content h3 {
    color: #000;
    font-weight: bold;

    font-family: 'Roboto Slab',serif;
    /* font-weight: 700; */
}

#careers-culture-content h3, #careers-culture-content p {
    margin: 0 0 20px;
}

#careers-culture-content h3 span {
    font-family: 'Roboto',sans-serif;
    color: #d40029;
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

#careers-culture-content .careers-culture .col-dd-4 .career-vid {
    margin: 20px 0;
}

#careers-culture-content .video-responsive {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

#careers-culture-content .video-responsive::before {
    display: block;
    content: "";
    padding-top: 56.25%;
}

#careers-culture-content .video-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

#careers-culture-content img, iframe, object {
    vertical-align: middle;
}

@media only screen and (min-width: 1300px){
    #careers-culture-content .careers-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px){
    /* #careers-culture-content .careers-culture {
        background: #efeff0 url(https://www.acehandymanservices.com/images/banners/careers-culture-xl.jpg) no-repeat top center;
    } */

    #careers-culture-content .content-section>.row {
        margin-bottom: 80px;
    }

    #careers-culture-content .content-section .row {
        margin-bottom: 60px;
    }

    #careers-culture-content .careers-container {
        padding: 0 20px;
    }

    #careers-culture-content .careers-culture .culture-text {
        max-width: 800px;
    }

    #careers-culture-content h3 {
        font-size: 2.5rem;
        line-height: 1.25em;
    }

    #careers-culture-content h3 span {
        font-size: 1.625rem;
        line-height: 1.3846153846153846em;
    }

    #careers-culture-content .careers-culture .col-dd-4 {
        padding: 0 10px;
    }

    #careers-culture-content .col-dd-4 {
        /* changed from width: 33.33%; */
        width: 31.33%;
    }
}

@media only screen and (max-width: 800px){
#careers-culture-content .careers-culture {
    padding-right: 0;
}
}