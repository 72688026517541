.campaign-corporate {
    width: 125px;
    font-size: 10px;
    font-weight: 800;
    white-space: nowrap;
}
.campaign-corporate img {
    height: 10px;
}
@media only screen and (max-width: 799px) {
    .campaign-corporate {
        width: auto;
        margin-right: 35px;
    }
}
@media only screen and (min-width: 600px) {
    header .corporate-local {
        text-align: right;
    }
    .campaign-corporate {
        width: 100% !important;
        font-size: 16px;
        font-weight: 700;
        position: absolute;
        top: 10%;
        left: 72%
    }
    .campaign-corporate img {
        height: 17px;
    }  
}
@media only screen and (max-width: 600px){
    .campaign-corporate {
        width: 100% !important;
        font-size: 16px;
        font-weight: 700;
        position: absolute;
        top: 40%;
        left: 12%
    }
}
@media only screen and (max-width: 800px) {
    .campaign-corporate svg {
        height: 17px !important;
    }
    .campaign-corporate a {
        font-size: 1.2rem;
    }
}
