.promise-wrap {
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    background-color: transparent;
}

/* .promise-wrap {
    position: relative;
    padding: 40px 0;
    background: #fff;
    outline: 1px solid #000;
    z-index: 1;
    text-align: center;
} */

.promise-wrap div {
    padding-top: 0.001em;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.promise-wrap>.container {
    padding: 0;
}

.promise-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.row:before, .row:after {
    content: "";
    display: table;
    clear: both;
}

[class*='col-'] {
    float: left;
    vertical-align: top;
    width: 100%;
    min-height: 1px;
}

.promise-wrap .promise-slider-wrap {
    text-align: center;
    background: #fff;
}

.promise-wrap .single-border {
    background: #FFF;
    position: relative;
    border: 1px solid #000;
    z-index: 1;
    color: #63666a;
}

/* added this to center the text in the box. otherwise it is close to the right */
.single-border .promise-slider ul li {
    padding-left:0;
}

.promise-wrap .promise-slider-wrap .container {
    padding: 40px 10%;
}

.promise-wrap .promise-slider-wrap .heading {
    margin-bottom: 20px;
    font-weight: 700;
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-size: 2rem;
    line-height: 1.1875em;
}

.promise-wrap .heading {
    font-size: 1.75rem;
    line-height: 1.3571428571428572em;
    color: #000;
    margin-bottom: 20px;
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

.promise-wrap .heading {
    color: #000;
}

.promise-wrap p, .promise-wrap ul, .promise-wrap ol, .promise-wrap blockquote, .promise-wrap table {
    margin: 0 0 20px;

    
}

/* added this to make text center aligned */
.promise-wrap p {
    text-align: center;
}

.promise-wrap strong {
    font-weight: bold;
}

.promise-wrap .promise-slider .slide .more {
    display: block;
    margin: 20px auto;
    text-align: center;
}

.promise-wrap .promise-slider .slide a, .promise-wrap .promise-slider .slide .more {
    font-weight: 700;
}

.promise-wrap a {
    color: #d40029 !important;

    text-decoration: none;

    text-decoration: none;
}

.promise-slider ul li {
    list-style: none;
}

.promise-slider .slide {
    padding: 0 20px;

    /* added this to add space above the squares at the bottom */
    padding-top: 70px;
    padding-bottom: 0px;
}

/* added this to center the text in the box otherwise it shifts to the right */
.promise-slider .react-multi-carousel-list ul {
    margin:0 !important;
}

/* added to change the carousel dot styling and behavior */
.promise-slider .react-multi-carousel-dot button {
    border-radius:0px;
    background: #becccf !important;
    border-color: transparent;
}
.promise-slider .react-multi-carousel-dot button:hover {
    background: transparent !important;
}
.promise-slider .react-multi-carousel-dot--active button {
    background: black !important;
}
.promise-slider .react-multi-carousel-dot--active button:hover {
    background: black !important;
}
.promise-wrap>.container {
    padding: 0;
}

@media only screen and (min-width: 1140px) {
    .lazy-bg {
        background-position: -280px bottom;
    }
}

@media only screen and (min-width: 800px){
    .promise-wrap .container {
        padding: 0 20px;
    }

    .promise-wrap .twelve .col-dd-6 {
        width: 50%;
    }

    .promise-wrap .promise-slider-wrap {
        left: -5%;
    }

    .promise-wrap .promise-slider-wrap .container {
        padding: 60px;
    }

    .promise-wrap .promise-slider-wrap .heading {
        font-size: 2.625rem;
        line-height: 1.1428571428571428em;
    }

    /* added .promise-wrap to reduce space around by area by room by service section */
    .promise-wrap.lazy-bg {
        border-top: 1px solid #d6d6d7;
        padding: 110px 0;
        background-position: -300px bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
}

@media only screen and (min-width: 1920px) {
    .promise-wrap.lazy-bg {
        /* so image doesn't get clipped on left side */
        background-position: 0px bottom;
    }
    .promise-wrap .container.row{
        /* margin-right:11%; */
        /* added for the 1920 * 1080 allignment issue */
        margin-right: 18%; 
    }
}

@media only screen and (min-width: 1300px) {
    .promise-wrap .container {
        max-width: 1240px;
    }
}

/* added .promise-wrap to make reviews carousel visible in mobile */
@media only screen and (max-width: 800px){
    .promise-wrap.lazy-bg {
        background: none !important;
    }
}