@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
.franchisehomepage-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    transition: max-width .3s ease;
    margin-bottom: 0px;
}

#news-feed {
    background: #000;
}

#news-feed p {
    text-align: center;
    color: #d6d6d7;
    margin: 0;
    line-height: 1.5em;
    padding: 10px 10%;
    font-size: 20px;
    font-weight: 700;
}

@media only screen and (min-width: 1300px) {
    .franchisehomepage-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .franchisehomepage-container {
        padding: 0 20px;
        margin-bottom: -75px;
    }

    #news-feed p {
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 600px) {
    #news-feed {
        margin-top: 0 !important;
    }
}