.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

.tabb-line {
    margin: 0;
    text-align: center;
    /* background: #e2e2e4; */
    box-shadow: 0 -10px 5px -5px rgb(0 0 0 / 10%);
}

.tabb-line .tab-selector-container {
    padding: 0;
    position: relative;
}

small {
    font-size: .625rem;
    line-height: 1.2em;
}

@media only screen and (min-width: 800px) {
    .tabb-line .package-container {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 600px) {
    .content-wrap>.row {
        margin-bottom: 40px !important;
    }
}

/* @media only screen and (min-width: 1300px){
    .package-container {
        max-width: 1240px;
    }
} */

.tab-selector-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    transition: max-width .3s ease;
}

.tab-option .tab-underline {
    width: 100px;
    color: #fff;
    background: #000;
    /* changed from padding: 42px 23px 42px 0px; to fix extra space on right*/
    padding: 42px 7px 42px 0px;
    font-size: 10px !important;
    margin: 0;
}

.tab-option .tabs-underline {
    width: 100px;
    color: #000;
    background: #fff;
    /* changed from padding: 42px 23px 42px 0px; to fix extra space on right*/
    padding: 42px 7px 42px 0px;
    font-size: 10px !important;
    margin: 0;
}

.tabb-line .tab-selector-container .tab-options {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.tabb-line .tab-selector-container .tab-options .tab-option {
    border-left: 1px solid #e2e2e4;
    padding: 0px 0px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #e2e2e4;
    border-top: 0;
    background: #fff !important;
    width: 25%;
}

.container {
    width: 90%;
    /* max-width: 1080px; */
    padding: 0 15px;
    margin: 0 auto;
    transition: max-width .3s ease;
}

@media only screen and (min-width: 950px) {
    .tabb-line .tab-selector-container .tab-options .tab-option {
        font-size: 1.25rem;
        line-height: 105px;
    }
}

@media only screen and (min-width: 1300px) {
    .tab-selector-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 800px) {
    .tabb-line .tab-selector-container .tab-options .tab-option {
        width: auto;
        padding: 0px 0px;
        font-size: 20px;
        line-height: 1em;
    }

    .tabb-line .tab-selector-container {
        padding: 0 20px;

        position: relative;
    }
}

@media only screen and (min-width: 600px) {
    .tab-option .tab-underline {
        width: 180px;
        color: #fff;
        background: #000;
        padding: 40px 30px 35px 30px;
        font-size: 20px !important;
        margin: 0;
    }

    .tab-option .tabs-underline {
        width: 180px;
        color: #000;
        background: #fff;
        padding: 40px 30px 35px 30px;
        font-size: 20px !important;
        margin: 0;
    }

    .tabb-line .tab-selector-container .tab-options {
        margin: 0;
        padding: 0;
        font-size: 0;
        list-style: none;
    }

    .container {
        width: 100%;
        /* max-width: 1080px; */
        padding: 0 15px;
        margin: 0 auto;
        transition: max-width .3s ease;
    }
}

@media only screen and (max-width: 400px) {
    .tab-option .tabs-underline {
        width: 100%;
        padding: 22px 7px 22px 0px;
    }

    .tab-option .tab-underline {
        width: 100%;
        padding: 22px 7px 22px 0px;
    }

    .tabb-line .tab-selector-container .tab-options .tab-option {
        width: 100%;
    }

    .tabb-line .tab-selector-container .tab-options {
        display: flex;
        flex-direction: column;
    }
}