section.changing {
    position: relative;
    padding: 20px 0
}

section.changing .left {
    padding-bottom: 15px
}

section.changing .right img {
    height: 400px;
    width: 100%;
    object-fit: fill;
    /* background-size: cover; */
    /* object-position: -109px 70% */
}

.bg-grey {
    background: #d8d8da
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease
}

@media only screen and (min-width: 800px) {
    .container {
        padding:0 20px
    }
    .mb-0 {
        display: flex;
    }
}

@media only screen and (min-width: 1300px) {
    .container {
        max-width:1240px
    }
}

.mb-0 {
    /* display: flex; */
    margin-bottom: 0
}

/* commented to reduce space above the icons in careers page */
/* .content-section .row {
    margin-bottom: 40px
} */

@media only screen and (min-width: 800px) {
    /* commented to reduce space above the icons in careers page */
    /* .content-section .row {
        margin-bottom:60px
    } */
}

@media only screen and (min-width: 600px) {
    #content .pad-right {
        padding-right:60px
    }

    #content .pad-left {
        padding-left: 60px
    }
}

@media only screen and (min-width: 800px) {
    #content .pad-right-lg {
        padding-right:60px
    }

    #content .pad-left-lg {
        padding-left: 60px
    }
}

@media only screen and (min-width: 800px) {
    .hidemobile {
        position:static!important
    }

    .hidedesktop {
        position: fixed!important
    }
}

.row:before,.row:after {
    content: "";
    display: table;
    clear: both
}

[class*='col-'] {
    float: left;
    vertical-align: top;
    width: 100%;
    min-height: 1px
}

@media only screen and (min-width: 800px) {
    section.changing {
        padding:0
    }

    section.changing .left {
        padding: 40px;
        padding-left: 0
    }

    section.changing .right {
        /* margin-right: -55px; */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        /* width: 35%; */
    }

    section.changing .right img {
        height: 100%;
        /* width: 100% !important */
    }
}



@media only screen and (max-width: 600px) {
    section.changing {
        text-align: center;
    }

    section.changing ul {
        text-align: left;
    }
}