.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}
.tabb-line {
    /* text-align: center; */
    background: #efeff0;
    box-shadow: 0 -10px 5px -5px rgb(0 0 0 / 10%);
}
.services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    transition: max-width .3s ease;
}
small {
    font-size: .625rem;
    line-height: 1.2em;
}

@media only screen and (min-width: 800px) {
    .tabb-line .container {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 600px) {
    .content-wrap>.row {
        margin-bottom: 40px !important;
    }
}

@media only screen and (min-width: 1300px){
    .tab-selector-container {
        max-width: 1240px;
    }
}

/* tab  */
.tab-selector-container {
    position: relative;
    width: 100%;
    max-width: 1350px;
    margin-bottom: 0px !important;
    box-sizing: border-box;
    /* background: #e2e2e4; */
}
.tab-option .tabb-underline {
    /* width: 135px; */
    color: #fff;
    background: #000;
    /* padding: 29px 28px 29px 28px; */
    padding: 40px 10px;
    font-size: 13px !important;
    margin: 0;
}
.tab-option .tabbs-underline {
    /* width: 135px; */
    color: #000;
    background: #fff;
    /* padding: 29px 28px 29px 28px; */
    padding: 30px 10px;
    font-size: 13px !important;
    margin: 0;
}
.tab-selector-container .tab-options {
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content; 
}
.tab-selector-container .tab-options .tab-option span {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375em;
   }
.tab-selector-container .tab-options .tab-option {
  border-left: 1px solid #e2e2e4;
  padding: 30px 10px;
  font-weight: 700;
  font-family: 'Roboto Condensed',sans-serif;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #e2e2e4;
  border-top: 0;
  background: #fff;

  width: 32%;
}
@media only screen and (min-width: 950px){
    .tab-selector-container .tab-options .tab-option {
        font-size: 1.25rem;
        line-height: 105px;
    }
}

@media only screen and (min-width: 800px){
  .tab-selector-container .tab-options .tab-option  {
    width: auto;
    padding: 30px 0px;
    font-size: 20px;
    line-height: 1em;
}
}

@media only screen and (min-width: 600px) {
    .tab-option .tabbs-underline {
        width: 235px;
        color: #000;
        background: #fff;
        padding: 24px 49px 24px 49px;
        font-size: 20px !important;
        margin: 0;
    }
    .tab-option .tabb-underline{
        width: 235px;
        color: #fff;
        background: #000;
        padding: 26px 49px 26px 49px;
        font-size: 18px !important;
      }
}