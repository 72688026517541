body {
  /* margin: 0; */
  left: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  position: relative;
  font-size: 1em;
  line-height: inherit;
  color: #63666a;
  background-color: #fff;
  transition: left .3s ease-out;
}

html, body, div {
  padding-top: 0.001em;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  /* font-size: 100%; */
  /* font: inherit; */
}

h1,h2,h3,h4,.h1,.h2,.h3,.h4 {
  font-family: 'Roboto Slab',serif;
  font-weight: 700
}

h5,.h5 {
  font-family: 'Roboto Condensed',sans-serif;
  font-weight: 700
}

h6 {
  font-style: italic
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  color: #000
}

h1.primary,.h1.primary,h2.primary,.h2.primary,h3.primary,.h3.primary,h4.primary,.h4.primary,h5.primary,.h5.primary,h6.primary,.h6.primary {
  color: #d40029
}

h1.bold,.h1.bold,h2.bold,.h2.bold,h3.bold,.h3.bold,h4.bold,.h4.bold,h5.bold,.h5.bold,h6.bold,.h6.bold {
  font-weight: 700
}

h1.light,.h1.light,h2.light,.h2.light,h3.light,.h3.light,h4.light,.h4.light,h5.light,.h5.light,h6.light,.h6.light {
  font-weight: 300
}

h1.white,.h1.white,h2.white,.h2.white,h3.white,.h3.white,h4.white,.h4.white,h5.white,.h5.white,h6.white,.h6.white {
  color: #FFF
}

h1.shadow,.h1.shadow,h2.shadow,.h2.shadow,h3.shadow,.h3.shadow,h4.shadow,.h4.shadow,h5.shadow,.h5.shadow,h6.shadow,.h6.shadow {
  -webkit-text-shadow: 0 0 5px rgba(0,0,0,0.5);
  -moz-text-shadow: 0 0 5px rgba(0,0,0,0.5);
  text-shadow: 0 0 5px rgba(0,0,0,0.5)
}

.twelve .col-sd-1 {
  width: 8.33%
}

.twelve .col-sd-2 {
  width: 16.66%
}

.twelve .col-sd-3 {
  width: 25%
}

.twelve .col-sd-4 {
  width: 33.33%
}

.twelve .col-sd-5 {
  width: 41.66%
}

.twelve .col-sd-6 {
  width: 50%
}

.twelve .col-sd-7 {
  width: 58.33%
}

.twelve .col-sd-8 {
  width: 66.66%
}

.twelve .col-sd-9 {
  width: 75%
}

.twelve .col-sd-10 {
  width: 83.33%
}

.twelve .col-sd-11 {
  width: 91.66%
}

.twelve .col-sd-12 {
  width: 100%
}

.home .App {
  background: #fff;
}

.h1 {
  margin-bottom: 20px;
  line-height: 1.33em;
}


@media only screen and (min-width: 600px) {
  .twelve .col-md-1 {
      width:8.33%
  }

  .twelve .col-md-2 {
      width: 16.66%
  }

  .twelve .col-md-3 {
      width: 25%
  }

  .twelve .col-md-4 {
      width: 33.33%
  }

  .twelve .col-md-5 {
      width: 41.66%
  }

  .twelve .col-md-6 {
      width: 50%
  }

  .twelve .col-md-7 {
      width: 58.33%
  }

  .twelve .col-md-8 {
      width: 66.66%
  }

  .twelve .col-md-9 {
      width: 75%
  }

  .twelve .col-md-10 {
      width: 83.33%
  }

  .twelve .col-md-11 {
      width: 91.66%
  }

  .twelve .col-md-12 {
      width: 100%
  }
}

@media only screen and (min-width: 800px) {
  .twelve .col-dd-1 {
      width:8.33%
  }

  .twelve .col-dd-2 {
      width: 16.66%
  }

  .twelve .col-dd-3 {
      width: 25%
  }

  .twelve .col-dd-4 {
      /* width: 34.33% */
    
      width: 33.33%;
  }

  .twelve .col-dd-5 {
      width: 41.66%
  }

  .twelve .col-dd-6 {
      width: 51%
  }

  .twelve .franchise-home-right-col {
    width: 58.3% !important;
    padding-left: 8%;
  }

  .twelve .col-dd-7 {
      width: 58.33%
  }

  .twelve .col-dd-8 {
      width: 66.66%
  }

  .twelve .col-dd-9 {
      width: 75%
  }

  .twelve .col-dd-10 {
      width: 83.33%
  }

  .twelve .col-dd-11 {
      width: 91.66%
  }

  .twelve .col-dd-12 {
      width: 100%
  }
}

@media only screen and (min-width: 1366px) {
  .twelve .col-ld-1 {
      width:8.33%
  }

  .twelve .col-ld-2 {
      width: 16.66%
  }

  .twelve .col-ld-3 {
      width: 25%
  }

  .twelve .col-ld-4 {
      width: 33.33%
  }

  .twelve .col-ld-5 {
      width: 41.66%
  }

  .twelve .col-ld-6 {
      width: 50%
  }

  .twelve .col-ld-7 {
      width: 58.33%
  }

  .twelve .col-ld-8 {
      width: 66.66%
  }

  .twelve .col-ld-9 {
      width: 75%
  }

  .twelve .col-ld-10 {
      width: 83.33%
  }

  .twelve .col-ld-11 {
      width: 91.66%
  }

  .twelve .col-ld-12 {
      width: 100%
  }
}

@media only screen and (min-width: 1921px) {
  .twelve .col-xd-1 {
      width:8.33%
  }

  .twelve .col-xd-2 {
      width: 16.66%
  }

  .twelve .col-xd-3 {
      width: 25%
  }

  .twelve .col-xd-4 {
      width: 33.33%
  }

  .twelve .col-xd-5 {
      width: 41.66%
  }

  .twelve .col-xd-6 {
      width: 50%
  }

  .twelve .col-xd-7 {
      width: 58.33%
  }

  .twelve .col-xd-8 {
      width: 66.66%
  }

  .twelve .col-xd-9 {
      width: 75%
  }

  .twelve .col-xd-10 {
      width: 83.33%
  }

  .twelve .col-xd-11 {
      width: 91.66%
  }

  .twelve .col-xd-12 {
      width: 100%
  }
}

strong {
  font-weight: bold
}

ul,ol {
  margin: 20px 0 20px 20px;
  font-size: 1rem;
  line-height: 1.75em
}

html.lte8 ul,html.lte8 ol {
  font-size: 16px
}

ul li ul,ol li ul,ul li ol,ol li ol {
  margin: 0 0 20px 20px
}

ul li {
  list-style: disc outside
}

ul li ul li {
  list-style: disc outside
}

#root {
	position: absolute;
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;
}

body {
  left: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  margin: 0;
  position: relative;
  font-size: 1em;
  line-height: inherit;
  color: #63666a;
  background-color: #fff;
  -moz-transition: left .3s ease-out;
  -webkit-transition: left .3s ease-out;
  -ms-transition: left .3s ease-out;
  -o-transition: left .3s ease-out;
  transition: left .3s ease-out
}

html {
  box-sizing: border-box;
  font:normal 16px / 32px 'Roboto',sans-serif;
}

ol li {
  list-style: decimal outside
}

table {
  border: 1px solid #f2f2f2;
  border-collapse: collapse;
  width: 100%;
}

th, td {
border: 1px solid #f2f2f2;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {background-color: #f2f2f2;}

ol li ol li {
  list-style: lower-alpha outside
}

ol.indent,ul.indent {
  padding-left: 30px
}


[class*='col-'] {
  float: left;
  vertical-align: top;
  width: 100%;
  min-height: 1px
}

ol,ul {
  list-style: none
}

blockquote,q {
  quotes: none
}

blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

a {
  text-decoration: none
}

a img {
  border: 0
}

img,iframe,object {
  vertical-align: middle
}

.hidemobile,.hidedesktop {
  position: fixed!important;
  left: -9999px;
  top: 0
}

.hidedesktop {
  position: static!important
}

@media only screen and (min-width: 800px) {
  .hidemobile {
      position:static!important
  }

  .hidedesktop {
      position: fixed!important
  }
}

.row:before,.row:after {
  content: "";
  display: table;
  clear: both
}

/* #arrowUp .st0 {
  clip-path: url(#arrowUpclip);
  fill: currentColor;
} */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{
  display: block;
}

input, textarea, select {
  width: 100%;
  margin: 0 0 1px;
  padding: 15px;
  outline: 0;
  border: 0;
  background: #efeff0;
  color: #8a8c8f;
  transition: all .3s ease;
  font-style: normal;
  font-weight: normal;
  font-size: .9375rem;
  line-height: 2.13333333333333333em;
  font-family: 'Roboto Condensed' ,sans-serif;
}

.st0 {
  fill: currentColor;
}

[class*='col-'] {
  float: left;
  vertical-align: top;
  width: 100%;
  min-height: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,.h1 {
  font-size: 2rem;
  line-height: 1.5em
}

html.lte8 h1,html.lte8 .h1 {
  font-size: 32px
}

h2,.h2 {
  font-size: 1.75rem;
  line-height: 1.5em
}

html.lte8 h2,html.lte8 .h2 {
  font-size: 28px
}

h3,.h3 {
  font-size: 1.375rem;
  line-height: 1.4545454545454546em
}

html.lte8 h3,html.lte8 .h3 {
  font-size: 22px
}

h4,.h4 {
  font-size: 1.125rem;
  line-height: 1.3888888888888888em
}

html.lte8 h4,html.lte8 .h4 {
  font-size: 18px
}

h5,.h5 {
  font-size: 1.125rem;
  line-height: 1.2222222222222223em
}

html.lte8 h5,html.lte8 .h5 {
  font-size: 18px
}

h6,.h6 {
  font-size: .875rem;
  line-height: 1.4285714285714286em
}

html.lte8 h6,html.lte8 .h6 {
  font-size: 14px
}

@media only screen and (min-width: 800px) {
  h1,.h1 {
      font-size:3rem;
      line-height: 1.3333333333333333em
  }

  html.lte8 h1,html.lte8 .h1 {
      font-size: 48px
  }

  h2,.h2 {
      font-size: 2.625rem;
      line-height: 1.4285714285714286em
  }

  html.lte8 h2,html.lte8 .h2 {
      font-size: 42px
  }

  h3,.h3 {
      font-size: 1.75rem;
      line-height: 1.5em
  }

  html.lte8 h3,html.lte8 .h3 {
      font-size: 28px
  }

  h4,.h4 {
      font-size: 1.5rem;
      line-height: 1.1666666666666667em
  }

  html.lte8 h4,html.lte8 .h4 {
      font-size: 24px
  }

  h5,.h5 {
      font-size: 1.25rem;
      line-height: 1.3em
  }

  html.lte8 h5,html.lte8 .h5 {
      font-size: 20px
  }

  h6,.h6 {
      font-size: 1.125rem;
      line-height: 1.1111111111111112em
  }

  html.lte8 h6,html.lte8 .h6 {
      font-size: 18px
  }
  p {
    padding: 0 !important ;
  }
}

h1,h2,h3,h4,h5,h6,p,ul,ol,blockquote,table {
  margin: 0 0 20px;
}

p {
  text-align: left;
  padding: 0 20px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .whychooseusbanner-body-wrap {
    padding: 11px;
  }

  .whychooseuslife-body-wrap {
    padding: 11px;
  }

  .whyChooseUs-main section.img-copy-tray .tray-text-left {
    padding: 11px;
  }

}
