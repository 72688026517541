.custom-video-container {
  display: flex;
  flex-direction: column;
  align-items:center;
}

.single-video-text {
width:80% !important;
}

.video-display-container {
  display: flex;
  min-height: 400px;
}

.single-video-container-width{
 width:80%;
}

.multi-video-container-width{
 width:100%;
}

.multi-videos{
 justify-content: space-between;
}

.video-display-container iframe {
  width: 100%;
  height: inherit;
  margin:20px;
}
