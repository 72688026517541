#careers-brand-content .careers-brand {
    padding-top: 50px;
    border-bottom: 1px solid #efeff0;
    margin-bottom: 0 !important;
}

#careers-brand-content .content-section>.row {
    margin-bottom: 40px;
}

/* commented to reduce space above the icons but didn't seem to do anything */
/* #careers-brand-content .content-section .row {
    margin-bottom: 40px;
} */

.parent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* commented to reduce space above the icons */
    /* height: 200px; */
}

.children-1 {
    width: 40%;
}

.children-2 {
    /* width: 66.6%; */
    width: 90%;
    /* overflow-y: hidden; */
}

#careers-brand-content .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

#careers-brand-content .col-sd-12 {
    width: 100%;
}

#careers-brand-content .careers-brand h3 {
    margin-bottom: 50px;
    margin-left: 10px;
}

#careers-brand-content h3 {
    color: #000;
    font-weight: bold !important;
}

/* h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
} */

#careers-brand-content .icon-wrap h3 span {
    font-family: 'Roboto Slab', serif;
    /* font-weight: 700;

    margin: 0 0 20px; */

    font-size: 1.375rem;
    line-height: 1.2em;
    font-weight: bold;
    text-transform: none;
}

#careers-brand-content h3 span {
    font-family: 'Roboto', sans-serif;
    color: #d40029;
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

#careers-brand-content .careers-brand .icon-wrap {
    text-align: center;
    max-width: 300px;
    margin: 0 auto 20px auto;
}

/* #careers-brand-content .careers-brand .icon-wrap > span:first-of-type {
border: 1px solid red;
display: inline-block;
margin: -30px 0 0 0;
} */
.p-t-100 {
    padding-top: 90px
}

.p-t-80 {
    padding-top: 60px
}

.p-t-60 {
    padding-top: 30px
}

#careers-brand-content .careers-path .headline svg {
    width: 150px;
    height: auto;
    left: -20px;
    position: relative;
}

#careers-brand-content svg {
    max-height: 160px;
    width: 100px;
    height: 100px;
}

#careers-brand-content img {
    max-height: 160px;
}

#careers-brand-content .careers-brand .icon-wrap.row1 h3 {
    margin-top: -20px;
}

#careers-brand-content .careers-brand .icon-wrap h3 {
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.2em;
    font-size: 1.375rem;
    font-weight: bold;
    color: #d40029;
}

#careers-brand-content .careers-brand h3 {
    margin-bottom: 50px;
}

.flex-and-wrap {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (min-width: 600px) {
    #careers-brand-content .careers-path .headline svg {
        position: absolute;
        left: -18px;
        top: 0;
    }

    #careers-brand-content .col-md-6 {
        width: 50%;
    }

    #careers-brand-content .careers-brand .icon-wrap {
        min-height: 270px;
    }
}

@media only screen and (min-width: 800px) {
    .children-1 {
        width: 12%;
    }

    #careers-brand-content .content-section>.row {
        margin-bottom: 80px;
    }

    /* commented to reduce space above the icons but didn't seem to do anything */
    /* #careers-brand-content .content-section .row {
        margin-bottom: 60px;
    } */

    #careers-brand-content .container {
        padding: 0 20px;
    }

    #careers-brand-content h3 {
        font-size: 2.5rem;
        line-height: 1.25em;
    }

    /* h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    } */

    #careers-brand-content h3 span {
        font-size: 1.625rem;
        line-height: 1.3846153846153846em;
    }

    #careers-brand-content .col-dd-4 {
        width: 33.33%;
    }

    .parent {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        /* commented to reduce space above the icons */
        /* height: 200px; */
    }
}

@media only screen and (min-width: 1300px) {
    #careers-brand-content .container {
        max-width: 1240px;
    }
}

#careers-brand-content .st1 {
    fill: #ce2046;
}

@media only screen and (max-width: 600px) {
   
    .children-1 {
        margin: auto;
    }

    .icon-padding-sm {
        padding-top: 0 !important;
    }
}
@media only screen and (max-width: 800px) {
    #join-banner .careers {
        background-size: cover !important;
    }
    #join-banner, #join-banner .careers-banner-text {
        display: flex;
    }
    #join-banner .careers-banner-text .careers-bg-wrap {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}