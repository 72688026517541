.franchise-why-choose-us-content-wrap .top-content {
    border-bottom: 1px solid #8a8c8f;
    background: #fff;
}

.franchise-why-choose-us-content-wrap .row {
    margin-bottom: 40px;
}

.franchise-why-choose-us-content-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.franchise-why-choose-us-content-wrap .top-content .left-col {
    margin-bottom: 30px;
}

.franchise-why-choose-us-content-wrap .col-sd-12 {
    width: 100%;
}

.franchise-why-choose-us-content-wrap .top-content .right-col {
    margin-bottom: 30px;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .accreditations-wrap {
    position: relative;
    margin: 20px 0;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .accreditations-wrap figure {
    display: inline-block;
    width: 33.3%;
    padding: 10px;
    text-align: center;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .accreditations-wrap figure img {
    width: auto;
    max-width: 100%;
    max-height: 140px;
}

img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

img, iframe, object {
    vertical-align: middle;
}

.clearfix:after {
    content: '';
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .splash {
    /* display: none; */
    margin-bottom: 30px;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .splash figure {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
}

.franchise-why-choose-us-content-wrap .top-content .right-col .splash img {
    max-width: 100%;
}

.franchise-why-choose-us-content-wrap .why-different {
    padding: 40px 0;
    background: #efeff0;
    box-shadow: 0 0 13px rgb(0 0 0 / 40%);
}

.franchise-why-choose-us-content-wrap .why-different .splash {
    margin-bottom: 30px;
    text-align: center;
}

.franchise-why-choose-us-content-wrap .why-different .splash img {
    max-width: 100%;
}

.franchise-why-choose-us-content-wrap img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.franchise-why-choose-us-content-wrap a {
    font-weight: 600;
}

.franchise-why-choose-us-content-wrap a:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 1300px) {
    .franchise-why-choose-us-content-wrap .container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 600px){
    .franchise-why-choose-us-content-wrap .pad-right {
        padding-right: 60px;
    }

    img {
        width: auto;
    }

    .franchise-why-choose-us-content-wrap img {
        width: auto !important;
    }
}


@media only screen and (min-width: 800px){
    .franchise-why-choose-us-content-wrap .row {
        margin-bottom: 60px;
    }

    .franchise-why-choose-us-content-wrap .container {
        padding: 0 20px;
    }

    .franchise-why-choose-us-content-wrap .col-dd-8 {
        width: 66.66%;
    }

    .franchise-why-choose-us-content-wrap .col-dd-4 {
        width: 33.33%;
    }

    .franchise-why-choose-us-content-wrap .top-content .right-col .splash {
        position: relative;
        display: block;
        overflow: hidden;
    }
    .franchise-why-choose-us-content-wrap .why-different {
        padding: 80px 0;
    }
    .franchise-why-choose-us-content-wrap .pad-right-lg {
        padding-right: 60px;
    }
    .franchise-why-choose-us-content-wrap .col-dd-7 {
        width: 58.33%;
    }
    .franchise-why-choose-us-content-wrap .col-dd-5 {
        width: 41.66%;
    }
    .franchise-why-choose-us-content-wrap .why-different .splash {
        margin-bottom: 60px;
    }
}
