.body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

#join-banner {
    clear: both;
    /* changed padding-top from 140px to 120px so that the gap above banner gets reduced */
    padding-top: 150px;
    /* padding-top: 120px; */
}

#banner-without-recentFranchise {
    clear: both;
    /* changed padding-top from 140px to 120px so that the gap above banner gets reduced */
    /* padding-top: 150px; */
    padding-top: 117px;
}

#join-banner .careers {
    overflow: visible;
    width: 100%;
    vertical-align: middle;

    /* background-size: cover; */

    background-position: center;
    background-size: cover;
}

#banner-without-recentFranchise .careers {
    overflow: visible;
    width: 100%;
    vertical-align: middle;

    /* background-size: cover; */

    background-position: center;
    background-size: cover;
}

#join-banner .careers-banner-text {
    padding: 60px 20px;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    text-align: center;
}

#join-banner .careers-banner-text .careers-bg-wrap .header {
    padding: 0;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    font-size: .8125rem;
    line-height: 1.7692307692307692em;
}

#join-banner .header {
    margin: 0;
    /* padding: 40px 0 0; */
}

#join-banner .careers-banner-text .careers-bg-wrap .header .headline {
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 1.4642857142857142em;
}

#join-banner h1 {
    font-size: 2rem;
    line-height: 1.5em;
}


#join-banner .careers-banner-text .careers-bg-wrap .header .headline span, 
#join-banner .careers-banner-text .careers-bg-wrap .copy-text span {
    font-family: 'Roboto Slab', serif;
}

#join-banner .careers-banner-text .careers-bg-wrap .header .headline {
    margin-bottom: 1rem;
}

#join-banner .careers-banner-text .careers-bg-wrap .header .headline span {
    font-family: 'Roboto Slab', serif;
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 2.375rem;
    /* line-height: 1.3421052631578947em; */
    line-height: 1.1em;
}

#join-banner .careers-banner-text .careers-bg-wrap h2 span {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    /* font-size: 3.25rem; */
    font-size: 3rem;
    /* line-height: 1.1923076923076923em; */
    line-height: 1em;
}

#join-banner .careers-banner-text .careers-bg-wrap p {
    text-align: center !important;
}

#join-banner .careers-banner-text .careers-bg-wrap .copy-text span {
    color: #fff;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
}

#join-banner .careers h3 {
    color: #000;
    font-weight: bold;
}

#join-banner .btn.careers-btn {
    font-size: 1.625rem;
    line-height: 1em;
}

#join-banner .btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto', sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
}

#join-banner a {
    color: #d40029;

    text-decoration: none;
}

#join-banner .careers-banner-text .banner-careers-wrap h2 {
    line-height: 0.5em;
}

#join-banner .careers-banner-text .banner-careers-wrap p {
    line-height: 0.5em;
}

#banner-without-recentFranchise .careers-banner-text {
    padding: 60px 20px;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    text-align: center;
    display: flex;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header {
    padding: 0;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    font-size: .8125rem;
    line-height: 1.7692307692307692em;
}

#banner-without-recentFranchise .header {
    margin: 0;
    /* padding: 40px 0 0; */
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline {
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 1.4642857142857142em;
}

#banner-without-recentFranchise h1 {
    font-size: 2rem;
    line-height: 1.5em;
}


#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline span, 
#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .copy-text span {
    font-family: 'Roboto Slab', serif;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline {
    margin-bottom: 1rem;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline span {
    font-family: 'Roboto Slab', serif;
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 2.375rem;
    /* line-height: 1.3421052631578947em; */
    line-height: 1.1em;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap h2 span {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    /* font-size: 3.25rem; */
    font-size: 3rem;
    /* line-height: 1.1923076923076923em; */
    line-height: 1em;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap p {
    text-align: center !important;
}

#banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .copy-text span {
    color: #fff;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
}

#banner-without-recentFranchise .careers h3 {
    color: #000;
    font-weight: bold;
}

#banner-without-recentFranchise .btn.careers-btn {
    font-size: 1.625rem;
    line-height: 1em;
}

#banner-without-recentFranchise .btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto', sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
}

#banner-without-recentFranchise a {
    color: #d40029;

    text-decoration: none;
}

#banner-without-recentFranchise .careers-banner-text .banner-careers-wrap h2 {
    line-height: 0.5em;
}

#banner-without-recentFranchise .careers-banner-text .banner-careers-wrap p {
    line-height: 0.5em;
}

@media only screen and (min-width: 800px) {
    #join-banner .careers-banner-text {
        background: transparent;
        display: table;
        width: 100%;
        margin: 0;
        /* padding: 55px 0; */
        min-height: 404px;
    }

    #join-banner .careers-banner-text .banner-careers-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    #join-banner .careers-banner-text .careers-bg-wrap {
        background: rgba(0, 0, 0, 0.8);
        /* width: 800px; */
        width: 660px;
        margin: 0 auto;
        padding: 20px 0 30px 0;

        /* added this to move text overlay higher so the cta can be seen on initial load */
        /* this is in conjunction with margin-top: 60px and background-size:contain !Important in #join-banner .careers */
        /* margin-top: -40px; */
    }

    #join-banner .header {
        padding: 90px 0 0;
    }

    #join-banner h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }

    #join-banner h1,
    #join-banner h3 {
        color: #000;

        font-family: 'Roboto Slab', serif;
        font-weight: 700;

        margin: 0 0 20px;
    }

    #join-banner .careers h3 {
        font-size: 2.5rem;
        line-height: 1.25em;
    }

    #join-banner h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }

    #banner-without-recentFranchise .careers-banner-text {
        background: transparent;
        display: table;
        width: 100%;
        margin: 0;
        /* padding: 55px 0; */
        min-height: 404px;
    }

    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap {
        background: rgba(0, 0, 0, 0.8);
        /* width: 800px; */
        width: 660px;
        margin: 0 auto;
        padding: 20px 0 30px 0;

        /* added this to move text overlay higher so the cta can be seen on initial load */
        /* this is in conjunction with margin-top: 60px and background-size:contain !Important in #banner-without-recentFranchise .careers */
        /* margin-top: -40px; */
    }

    #banner-without-recentFranchise .header {
        padding: 90px 0 0;
    }

    #banner-without-recentFranchise h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }

    #banner-without-recentFranchise h1,
    #banner-without-recentFranchise h3 {
        color: #000;

        font-family: 'Roboto Slab', serif;
        font-weight: 700;

        margin: 0 0 20px;
    }

    #banner-without-recentFranchise .careers h3 {
        font-size: 2.5rem;
        line-height: 1.25em;
    }

    #banner-without-recentFranchise h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }
}

@media only screen and (max-width: 600px) {
    #join-banner {
        padding-top: 69px;
    }

    #join-banner .careers-banner-text .careers-bg-wrap {
        padding: 20px 0 30px 0;
    }

    /* shifting elements up */
    #join-banner .careers-banner-text .banner-careers-wrap h1, 
    #join-banner .careers-banner-text .banner-careers-wrap h2, 
    #join-banner .careers-banner-text .banner-careers-wrap p {
        margin:0;
    }
    #join-banner .careers {
        /* margin-top:-60px; */
        margin-top: 0px;
    }
    #join-banner .careers-banner-text .careers-bg-wrap .header .headline span {
        font-size: 1.75rem;
    }
    #join-banner .careers-banner-text .careers-bg-wrap h2 span {
        font-size: 2.625rem;
    }

    #banner-without-recentFranchise {
        padding-top: 69px;
        display: flex;
    }

    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap {
        padding: 20px 0 30px 0;
        background-color: rgba(0, 0, 0, 0) !important;
    }

    /* shifting elements up */
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap h1, 
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap h2, 
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap p {
        margin:0;
    }
    #banner-without-recentFranchise .careers {
        /* margin-top:-60px; */
        margin-top: 0px;
    }
    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline span {
        font-size: 1.75rem;
    }
    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap h2 span {
        font-size: 2.625rem;
    }
}

@media only screen and (min-width: 600px) {
    #join-banner .careers-banner-text .careers-bg-wrap {
        padding: 30px 70px 20px;
        /* padding: 75px 20px; */
    }

    #join-banner .careers-banner-text .careers-bg-wrap .header .headline {
        font-size: 2.375rem !important;
        line-height: 1.3421052631578947em;
    }
    #join-banner .careers-banner-text .careers-bg-wrap .header .headline.p span p span{
        font-size: 1.275rem !important;
        font-weight: 500;
    }

    /* shifting elements up */
    #join-banner .careers-banner-text .banner-careers-wrap h1, 
    #join-banner .careers-banner-text .banner-careers-wrap h2, 
    #join-banner .careers-banner-text .banner-careers-wrap p {
        margin:0;
    }
    #join-banner .careers {
        /* changed margin-top from -70px to 60px so that the top of banner image at least appears */
        /* and added background-size: contain !important for the full image to appear instead
        of appearing cropped */
        /* this is in conjunction with  margin-top:-180px in #join-banner .careers-banner-text .careers-bg-wrap */
        /* margin-top: 60px; */

        /*the size of the image is smaller, hence set te bgsize as cover. originally it was contain*/
        background-size: cover !important;
    }

    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap {
        /* padding: 20px; */
        /* padding: 75px 20px; */
        padding: 30px 70px 20px;
    }

    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline {
        font-size: 2.375rem !important;
        line-height: 1.3421052631578947em;
    }
    #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap .header .headline.p span p span{
        font-size: 1.275rem !important;
        font-weight: 500;
    }

    /* shifting elements up */
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap h1, 
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap h2, 
    #banner-without-recentFranchise .careers-banner-text .banner-careers-wrap p {
        margin:0;
    }
    #banner-without-recentFranchise .careers {
        /* changed margin-top from -70px to 60px so that the top of banner image at least appears */
        /* and added background-size: contain !important for the full image to appear instead
        of appearing cropped */
        /* this is in conjunction with  margin-top:-180px in #banner-without-recentFranchise .careers-banner-text .careers-bg-wrap */
        /* margin-top: 60px; */

        /*the size of the image is smaller, hence set te bgsize as cover. originally it was contain*/
        background-size: cover !important;
    }
}