.work-authorization input,
.age-limit input,
.crime-conviction input {
  width: auto !important;
  cursor: pointer;
  margin-right: 10px;
}

#conviction-explaination:disabled {
  background-color: #ddd;
}
