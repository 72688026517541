section.changing {
    position: relative;
    padding: 20px 0
}

section.changing .left {
    padding-bottom: 15px
}

section.changing .right img {
    height: 400px;
    width: 100%;
    /* replaced fill with cover because image was being stretched */
    /* and then replaced cover with contain because image was being clipped */
    /* then replaced contain with cover because image was having gap around it */
    object-fit: cover;
    /* background-size: cover; */
    /* object-position: -109px 70% */
    object-position: center;
}

/* Video customization */
.image-copy-Tray{
    width:100%;
    height:100%;
}

.bg-grey {
    background: #d8d8da
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease
}

a {
    font-weight: 700;

    color: #d40029;

    text-decoration: none;
}

.btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: 10px;
    font-style: normal;
    border: 0;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

@media only screen and (min-width: 800px) {
    .container {
        padding:0 20px
    }
    .mb-0 {
        display: flex;
    }
}

@media only screen and (min-width: 1300px) {
    .container {
        max-width:1240px
    }
}

.mb-0 {
    /* display: flex; */
    margin-bottom: 0
}

/* commented to reduce space above the icons in careers page */
/* .content-section .row {
    margin-bottom: 40px
} */

@media only screen and (min-width: 800px) {
    /* commented to reduce space above the icons in careers page */
    /* .content-section .row {
        margin-bottom:60px
    } */
}

@media only screen and (min-width: 600px) {
    #content .pad-right {
        padding-right:60px
    }

    #content .pad-left {
        padding-left: 60px
    }
}

@media only screen and (min-width: 800px) {
    #content .pad-right-lg {
        padding-right:60px
    }

    #content .pad-left-lg {
        padding-left: 60px
    }
}

@media only screen and (min-width: 800px) {
    .hidemobile {
        position:static!important
    }

    .hidedesktop {
        position: fixed!important
    }
}

.row:before,.row:after {
    content: "";
    display: table;
    clear: both
}

[class*='col-'] {
    float: left;
    vertical-align: top;
    width: 100%;
    min-height: 1px
}

@media only screen and (min-width: 800px) {
    section.changing {
        /* padding:0 */
        padding: 0 20px !important;
    }

    section.changing .left {
        padding: 40px;
        padding-left: 0
    }

    section.changing .right {
        /* margin-right: -55px; */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        /* width: 35%; */
    }

    section.changing .right img {
        height: 100%;
        /* width: 100% !important */
    }

    .franchise-why-choose-us-content-wrap ~ section.changing .right img {
        height: auto;
        margin-top: 17px;
        margin-top: 27px;
    }

    .franchise-why-choose-us-content-wrap ~ section.changing .right{
        width: 46% !important;
    }

    .franchise-why-choose-us-content-wrap ~ section.changing .left{
        width: 57%;
    }

}

@media only screen and (max-width: 800px) {
    .franchise-why-choose-us-content-wrap ~ section.changing .right {
        width: 100%;    
    }

    .franchise-why-choose-us-content-wrap ~ section.changing .right img {
        height: auto;
        margin-top: 17px;
    }
}


@media only screen and (max-width: 600px) {
    section.changing {
        text-align: center;
    }

    section.changing ul {
        text-align: left;
    }
}