.accreditations-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.accreditations-title {
    text-align: center;
  }

.accreditations-banner figure {
  width: 200px;
  height: 100px;
  margin: 10px;
}

.accreditations-banner figure img {
  height: 100%;
}

