.detail-page-banner {
  position: relative;
  background-color: #63666a;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

#top {
  margin-bottom: 90px !important ;
}

@media only screen and (min-width: 800px) {
  #detail-banner {
    /* changed margin-top from 7rem to 12rem so that there isn't whitespace above banner */
    /* added !important because same #detailBanner id is used in BathroomRRBanner.css and
        also has margin top css
        */
    margin-top: 7.2rem !important;
  }

  .container {
    padding: 0 20px;
  }

  .detail-page-banner .container {
    /* changed padding-top and bottom both from 50px to 0px and added !important
            because it wasn't working otherwise. this makes the banners shorter
        */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .banner-heading-container {
    display: flex;
    position: relative;
    left: -30px;
    max-width: max-content;
  }

  .banner-heading-container .paintstroke-left {
    width: 81px;
    height: 212px;
    /* height: 100px; */
  }

  .banner-heading-container .header {
    display: flex;
    align-items: center;
    padding: 40px 40px 40px 0;
    color: #fff;
    background-repeat: repeat-x;
    font-size: 2.375rem !important;
  }

  .banner-heading-container .paintstroke-right {
    width: 71px;
    height: 212px;
    /* height: 100px; */
  }
}

@media only screen and (max-width: 800px) {
  #detail-banner {
    /* changed margin to from 4.2rem to 11.2rem !important for mobile view
        and for image not to get clipped on top
        */
    margin-top: 7.4rem !important;
  }

  .container {
    padding: 0 20px;
  }

  .detail-page-banner .container {
    /* changed padding top and bottom both from 50px to 0px !important
            so that the banner image is shorter for mobile view
        */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .detail-page-banner::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(59, 61, 64, 0.3);
    background: svg-gradient(
      to bottom,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
    background: -moz-linear-gradient(
      top,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(59, 61, 64, 0.3)),
      color-stop(100%, rgba(59, 61, 64, 0.9))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(59, 61, 64, 0.3) 0,
      rgba(59, 61, 64, 0.9) 100%
    );
  }

  .banner-heading-container {
    display: flex;
    max-width: max-content;
    position: relative;
    z-index: 2;
  }

  .banner-heading-container .paintstroke-left {
    background-image: none;
  }

  .banner-heading-container .header {
    background-image: none !important;
    color: #fff;
    font-size: 1.75rem !important;
    margin-top: 80px;
    margin-bottom: -30px;
    margin-left: 15px;
  }

  .banner-heading-container .paintstroke-right {
    background-image: none;
  }
}

@media only screen and (max-width: 799px) {
  #detail-banner {
    margin-top: 5.8rem !important;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}
