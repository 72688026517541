/* .whychooseuspromise-body-wrap {
    background: #fff url(https://www.acehandymanservices.com/images/bg-pattern-triangles.png) repeat top center;
} */

.whychooseuspromise-body-wrap .content-section .promise-wrap {
    position: relative;
    margin: 40px 0;
}

.whychooseuspromise-body-wrap .content-section .row {
    margin-bottom: 40px;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap figure {
    position: absolute;
    display: none;
}

.whychooseuspromise-body-wrap img {
    width: 100%;
    height: auto;
    margin: 0 auto;

    vertical-align: middle;
}

.whychooseuspromise-body-wrap .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right {
    padding: 30px 40px 40px;
    width: 70%;
}

.whychooseuspromise-body-wrap .twelve .col-sd-12 {
    width: 100%;
}

.whychooseuspromise-body-wrap .single-border {
    background: #FFF;
    position: relative;
    border: 1px solid #000;
    z-index: 1;
    color: #63666a;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .heading {
    font-weight: 700;
    color: #000;
    font-size: 1.75rem;
    line-height: 1.3571428571428572em;
}

.whychooseuspromise-body-wrap .heading {
    color: #000;
}

.whychooseuspromise-body-wrap .lg-swoosh {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: 100% 100%;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 158px;
    position: relative;
    margin: 0 0 10px 0;
    font-family: 'Roboto Slab',serif;
}

.whychooseuspromise-body-wrap .lg-swoosh:before {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    display: block;
    width: 169px;
    height: 158px;
    content: '';
    position: absolute;
    left: -169px;
    top: 0;
}

.whychooseuspromise-body-wrap .lg-swoosh:after {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    display: block;
    width: 136px;
    height: 158px;
    content: '';
    position: absolute;
    right: -136px;
    top: 0;
}

.whychooseuspromise-body-wrap p, .whychooseuspromise-body-wrap ul {
    margin: 0 0 20px;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list {
    margin: 30px 0;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list .item {
    position: relative;
    margin-bottom: 20px;
    color: #000;
    padding-left: 30px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    counter-increment: step-counter;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list .item::before {
    content: counter(step-counter) ".";
    position: absolute;
    left: 0;
    color: #000;
    font-family: 'Roboto Slab',serif;
    font-size: 1.75rem;
    line-height: 1.3571428571428572em;
}

.whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list .item ul {
    color: #63666a;
    font-weight: normal;
}

.whychooseuspromise-body-wrap .promises-list ul, .whychooseuspromise-body-wrap .accordion ul {
    margin: 20px 0 !important;
    padding: 0;
    overflow: auto;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    list-style: none;
}

.whychooseuspromise-body-wrap ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;
}

.whychooseuspromise-body-wrap .promises-list ul li, .whychooseuspromise-body-wrap .accordion ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 3px;
    list-style: none;
}

.whychooseuspromise-body-wrap .promises-list ul li:before, .whychooseuspromise-body-wrap .accordion ul li:before {
    /* content: 'â— '; */
    content: "● ";
    position: absolute;
    margin-left: -20px;
    color: #d40029;
}

.whychooseuspromise-body-wrap .btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff !important;
    font-weight: 400;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
}

@media only screen and (min-width: 600px){
    .whychooseuspromise-body-wrap img {
        width: auto !important;
    }
}

@media only screen and (min-width: 800px){
    .whychooseuspromise-body-wrap .content-section .promise-wrap {
        margin: 40px 0 100px;
    }

    .whychooseuspromise-body-wrap .content-section .row {
        margin-bottom: 95px;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap:before {
        content: '';
        z-index: 0;
        position: absolute;
        right: 0;
        display: block;
        width: 50%;
        height: 100%;
        background: transparent url(https://www.acehandymanservices.com/images/aboutus-bg-wood.jpg) no-repeat center center;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap figure {
        display: block;
    }

    .whychooseuspromise-body-wrap .container {
        padding: 0 20px;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right {
        float: right;
        width: 60%;
        padding: 30px 80px 60px;
    }

    .whychooseuspromise-body-wrap .twelve .col-dd-10 {
        width: 83.33%;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap .heading {
        font-size: 2.375rem;
        line-height: 1.263157894736842em;
        margin-bottom: 30px;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list .item {
        margin-bottom: 40px;
        padding-left: 60px;
    }

    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right .promises-list .item:before {
        font-size: 2.625rem;
        line-height: .9047619047619048em;
    }
}

@media only screen and (min-width: 1000px){
    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right {
        width: 65%;
    }
}

@media only screen and (min-width: 1300px){
    .whychooseuspromise-body-wrap .container {
        max-width: 1240px;
    }
}

@media only screen and (max-width: 400px){
    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right {
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .whychooseuspromise-body-wrap .content-section .promise-wrap .offset-right {
        width: 75%;
        padding: 30px 100px 60px;
    }    
}
