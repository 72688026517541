.newbodycopy-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}
/* .newbodycopy-container p {
    text-align: center;
} */
.newbodycopy-container p img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}
.newbodycopy-container p a {
    font-weight: 700;
    color: #d40029;
    text-decoration: none;
}
.newbodycopy-container p a:hover {
    text-decoration: underline;
}

.bodycopytray-cta {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff !important;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
    transition: all .3s ease;

    font-size: 1.625rem;
    line-height: 1em;

    font-weight: 400;

    text-decoration: none !important;
}

@media only screen and (min-width: 600px) {
    .newbodycopy-container p img {
        width: auto !important;
    }
}

@media only screen and (min-width: 800px) {
    .newbodycopy-container {
        /* replaced 20px with 40px */
        padding: 0 40px;
    }   
    .newbodycopy-container .col-dd-10 {
        width: 83.33%;
    } 
}

@media only screen and (min-width: 1300px) {
    .newbodycopy-container {
        max-width: 1240px;
    }
}
