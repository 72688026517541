body {
  left: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  margin: 0;
  position: relative;
  font-size: 1em;
  line-height: inherit;
  color: #63666a;
  background-color: #fff;
  -moz-transition: left 0.3s ease-out;
  -webkit-transition: left 0.3s ease-out;
  -ms-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.philanthropy-contents figure,
.philanthropy-contents section {
  display: block;
}

body,
.philanthropy-contents div,
.philanthropy-contents p,
.philanthropy-contents img,
.philanthropy-contents figure,
.philanthropy-contents section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.philanthropy-contents .body-wrap{
  background-image: url("https://aceplumbing-dev-dss.igxdev.com/Images/Corporate%20Site/Textures/bg-pattern-triangles.png");
  margin-left: 10px;
}

/* article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
} */

.philanthropy-contents .content-section .row {
  margin-bottom: 40px;
}

.philanthropy-contents .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

.philanthropy-contents p {
  margin: 0 0 20px;
}

.philanthropy-contents .col-dd-8.pad-right-lg h1 span {
  color: #111 !important;
  text-decoration: none !important;
}

.philanthropy-contents img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.philanthropy-contents img {
  vertical-align: middle;
}

.philanthropy-contents ul {
  margin-bottom: 0 !important;
  line-height: 1.8em;
}

@media only screen and (min-width: 600px) {
  .philanthropy-contents img {
    width: auto;
  }
}

@media only screen and (min-width: 800px) {
  .philanthropy-contents .content-section .row {
    margin-bottom: 60px;
  }

  .philanthropy-contents .container {
    padding: 0 20px;
  }

  .philanthropy-contents #content .pad-right-lg {
    padding-right: 60px;
  }

  .philanthropy-contents .twelve .col-dd-8 {
    /* changed from width: 66.66% */
    /* width: 113%; */
    width: 66.66%;
  }

  .philanthropy-contents .twelve .col-dd-4 {
    width: 33.33%;
  }

  .philanthropy-contents .col-dd-8.pad-right-lg h1 span {
    font-size: 1.75rem !important;
  }
}

@media only screen and (max-width: 799px) {
  .philanthropy-contents .col-dd-8.pad-right-lg h1 span {
    font-size: 1.375rem !important;
  }
}

@media only screen and (min-width: 1300px) {
  .philanthropy-contents .container {
    padding-left: 0px !important;
  }
}
