.franchise-detailpage-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* changed padding from 0 15px to 40px 15px for mobile */
  /* padding: 40px 15px; */
  padding: 0 15px;
  padding-bottom: 0px !important;
  transition: max-width 0.3s ease;
}

.franchise-detailpage-container #pro-services-content .main-content {
  margin: 10px 0 0 0;
}

.franchise-detailpage-container + section.content-section .main-content {
  margin-top: 20px !important;
}

@media only screen and (min-width: 1300px) {
  .franchise-detailpage-container {
    max-width: 1240px;
  }
}

@media only screen and (min-width: 800px) {
  .franchise-detailpage-container {
    /* changed padding from 0 20px to 40px 15px for desktop*/
    /* padding: 30px 20px; */
    padding: 0 20px;
    padding-bottom: 0px;
  }
}
