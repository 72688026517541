.image-gallery-column-container {
  display: flex;
  justify-content: center;
}

.image-gallery-column-item {
  margin: 20px;
  color: white;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
}

.image-gallery-column-item .item-text {
  background-color: #111;
  flex: 1 0 auto;
  padding: 15px;
}

.image-gallery-column-item .item-text span {
  font-size: 1.125rem;
  font-weight: 500 !important;
}

.image-gallery-column-item .item-text p {
  text-align: center;
  font-size: 22px;
}

/* single column display for mobile devices */
@media only screen and (max-width:500px) {
  .image-gallery-column-container {
    flex-direction: column;
  }
}

/* two column display for Tablets/ipads */

@media only screen and (min-width:801px) and (max-width:1100px){
    .image-gallery-column-container{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .image-gallery-column-item {
        flex-basis: 40% !important;
      }
}

@media only screen and (min-width:600px) and (max-width:800px) {
  .image-gallery-column-container{
      flex-wrap: wrap;
  }
  .image-gallery-column-item {
    flex-basis: 60% !important;
  }
}
