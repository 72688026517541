div {
    padding-top: 0.001em;
}
img{
    width: 100%;
    height: auto;
    margin: 0 auto;
    vertical-align: middle;
}
.tab-content-wrap .toggle-heading {
    width: 100%;
    margin: 10px 0 0;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Roboto Condensed' ,sans-serif;
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 1.1em;
}
.btn {
    text-align: center;
    background: #d40029;
    color: #fff;
    padding: 15px 30px;
    cursor: pointer;
    font-style: normal;
    border: 0;
    transition: all .3s ease;
}
.tab-content-wrap .toggle-heading svg {
    height: 20px;
    margin-right: 5px;
}
.tab-content-wrap .tab-content {
    margin-bottom: 0;

    display: none;
    border: 1px solid #efeff0;
    padding: 20px;
}
.tab-content-wrap .splash-image {
    overflow: hidden;
    position: relative;

    /* display: none;
    overflow: visible; */
}
.tab-content-wrap #book-online .splash-image img {
    float: right;
}
.tab-content-wrap .splash-image img {
    max-width: 130%;
    max-height: 100%;
}
img {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
}
.get-estimate-container p {
    color: #63666a;
}

#book-online h3 {
    font-size: 25px;
    text-align: center;
}

#book-online .get-estimate-container p span {
    text-align: center;
}

/* .get-estimate-container .content {
    margin-top: 75px;
} */

@media only screen and (min-width: 600px) {
    .tab-content-wrap .container {
        padding: 0;
    }
    .tab-content-wrap .toggle-heading {
        display: none;
    }
    .tab-content-wrap .tab-content.current {
        display: block;
    }
    .tab-content-wrap .tab-content {
        border: 0 solid #efeff0;

        padding: 20px 0;
    }
    img {
        width: auto;
    }

    .tab-content-wrap #book-online {
        padding: 50px 10% 60px;

        background: #fff;
    }

    .tab-content-wrap .tab-content {
        padding: 20px 0;
        border: 0 solid #efeff0;
    }

    
}


@media only screen and (min-width: 800px) {
    .tab-content-wrap #book-online {
        padding: 0 40px 0 70px;
        background: #fff;
        /* margin-top: -10px; */
    }
    .tab-content-wrap .splash-image {
        display: block;
    }
    .col-dd-6 {
        width: 50%;
    }
    /* .tab-content-wrap #book-online .content {
        padding: 80px 20px; */
        /* margin-left: 35px; */
    /* } */
    #book-online h3 {
        font-size: 29px;
        text-align: left;
    }
    #book-online .get-estimate-container p span {
        text-align: left;
    }
}



@media only screen and (max-width: 600px){
    .splash-image img {
        display:none;
    }

    .tab-content-wrap #book-online {
        padding: 20px !important;
    }
}