.bathroomrrservicespecific-body-wrap {
    background-color: #fff;
    background-repeat: repeat;
    background-position: top center;
}

/* findanoffice css for service detail page */
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-left p {
    margin: 0;
    color: #000;

    padding-right: 20px;
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-right .input input {
    background: #fff;

    /* to make text inside input bar fit */
    font-size:0.8rem;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-right .instructions {
    color: #63666a;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-right p {
    /* color: #63666a; */
    color:#000;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container .wrap-center {
    margin-bottom:0px;
}




.bathroomrrservicespecific {
    position: relative;
    margin-top: -2px;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

.bathroomrrservicespecific-body-wrap .service-specific {
    background: #efeff0;

    display: flex;
}

.Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
  }

.bathroomrrservicespecific-body-wrap .service-specific>.row {
    margin-bottom: 0;
}

.bathroomrrservicespecific-body-wrap .content-section .row {
    margin-bottom: 40px;
}

.bathroomrrservicespecific-body-wrap .br-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.bathroomrrservicespecific-body-wrap .twelve .col-sd-12 {
    width: 100%;
}

.bathroomrrservicespecific-body-wrap .heading {
    color: #000;
}

.bathroomrrservicespecific-body-wrap h2 {
    font-size: 1.75rem;
    line-height: 1.5em;

    color: #000;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

.bathroomrrservicespecific-body-wrap h2, .bathroomrrservicespecific-body-wrap p {
    margin: 0 0 20px;
}

/* commented this so the image displays in mobile view */
/* .bathroomrrservicespecific-body-wrap .service-specific .splash {
    display: none;
} */

.bathroomrrservicespecific-body-wrap .service-specific .splash img.frame {
    z-index: 2;
    position: relative;
}

.bathroomrrservicespecific-body-wrap .service-specific .splash img {
    width: 100%;
}

.bathroomrrservicespecific-body-wrap img {
    width: 100%;
    height: auto;
    margin: 0 auto;

    vertical-align: middle;
}

.bathroomrrservicespecific-body-wrap .service-specific .splash figure {
    z-index: 1;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: auto;
}

.bathroomrrservicespecific-body-wrap .service-specific .splash img {
    width: 100%;
}

.bathroomrrservicespecific-body-wrap .service-specific .lf-wrap {
    margin: 40px 0;
}

.bathroomrrservicespecific-body-wrap .service-specific .lf-wrap .cta-left p {
    color: #000;
}

.bathroomrrservicespecific-body-wrap .service-specific .lf-wrap .cta-left p>span {
    display: block;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    font-size: 1.625rem;
    line-height: 1.3076923076923077em;
}

.bathroomrrservicespecific-body-wrap .nobr {
    white-space: pre-line;
}

@media only screen and (min-width: 600px){
    .bathroomrrservicespecific-body-wrap .service-specific>.row {
        margin-bottom: 40px;
    }

    .bathroomrrservicespecific-body-wrap #content .pad-right {
        padding-right: 60px;
    }

    .bathroomrrservicespecific-body-wrap .service-specific .splash {
        position: relative;
        display: block;
        overflow: hidden;
        margin-bottom: 30px;
    }

    .bathroomrrservicespecific-body-wrap img {
        width: auto;
    }

    .bathroomrrservicespecific-body-wrap .content-section .row {
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 800px){
    .bathroomrrservicespecific {
        margin-top: -20px;
        position: relative;
    }

    .bathroomrrservicespecific-body-wrap .content-section .row {
        margin-bottom: 80px;
    }

    .bathroomrrservicespecific-body-wrap .br-container {
        padding: 0 20px;
    }

    .bathroomrrservicespecific-body-wrap .twelve .col-dd-7 {
        /* changed from 58.33% */
        width: 53.33%;
    }

    .bathroomrrservicespecific-body-wrap h2 {
        font-size: 2.625rem;
        line-height: 1.4285714285714286em;
    }

    .bathroomrrservicespecific-body-wrap .twelve .col-dd-5 {
        /* this doesn't seem to have an effect for some reason */
        width: 41.66%;
    }

    .bathroomrrservicespecific-body-wrap .content-section .row {
        margin-bottom: 60px;
    }

    .bathroomrrservicespecific-body-wrap .twelve .col-dd-4 {
        width: 33.33%;
    }
}

@media only screen and (min-width: 1300px) {
    .bathroomrrservicespecific-body-wrap .br-container {
        max-width: 1240px;
    }
}

/* added this so the image displays in mobile view */
@media only screen and (max-width: 600px) {
    .bathroomrrservicespecific-body-wrap .service-specific .splash img.frame {
        z-index: 2;
        position: absolute;
    }

    .bathroomrrservicespecific-body-wrap .service-specific .splash figure {
        z-index: 1;
        position: relative;
        top: 0%;
        left: 0%;
        width: 100%;
        height: auto;
    }

    /* findanoffice mobile view */
    .bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center {
    margin-top: 20px;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-left span h3 {
    display: block;
    font-weight: 700;
    font-family: 'Roboto Slab',serif;
    font-size: 1.625rem;
    line-height: 1.3076923076923077em;
}
.bathroomrrservicespecific .bathroomrrservicespecific-body-wrap #content .service-specific .br-container
.wrap-center .cta-left p {
    margin-bottom: 20px;
}

}