body {
    left: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    font-size: 1em;
    line-height: inherit;
    color: #63666a;
    background-color: #fff;
    -moz-transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    -ms-transition: left .3s ease-out;
    -o-transition: left .3s ease-out;
    transition: left .3s ease-out;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.philanthropy-breadcrumbs #content .breadcrumbs {
    display: block;
    position: relative;
    z-index: 1;
    margin: 10px 0 50px;
}

.philanthropy-breadcrumbs .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.philanthropy-breadcrumbs #content .breadcrumbs ul {
    margin: 0 !important;
    padding: 0;
    line-height: 0;
}

/* added to see if breadcrumbs get fixed */
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.65em;
    list-style: none;
}

/* commented to see if breadcrumbs get fixed */
/* .philanthropy-breadcrumbs ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;

    list-style: none;

    margin: 0 0 20px;
} */

.philanthropy-breadcrumbs #content .breadcrumbs ul li {
    display: inline-block;
    font-family: 'Roboto',sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .875rem;
    /* uncommented to see if breadcrumbs get fixed */
    line-height: 2em;

    /* added to see if breadcrumbs get fixed */
    padding-right: 5px;
}

/* commented to see if breadcrumbs get fixed */
/* .philanthropy-breadcrumbs ul li {
    list-style: disc outside;
} */

/* added to see if breadcrumbs get fixed */
ul li {
    list-style: disc outside;
}

.philanthropy-breadcrumbs #content .breadcrumbs ul li.home:before {
    content: '';
    margin: 0;
}

/* .philanthropy-breadcrumbs #content .breadcrumbs ul li:before { */
    /* content: '\07C'; */
    /* commented to see if breadcrumbs get fixed */
    /* content: "";
    margin: 0 2px; */
/* } */

.philanthropy-breadcrumbs #content .breadcrumbs ul li a {
    font-weight: 400;
    color: #63666a;
    text-decoration: none;
}

.philanthropy-breadcrumbs #content .breadcrumbs ul li a:after {
    content: "";
    margin-right: 4px;
}

@media only screen and (min-width: 800px){
    .philanthropy-breadcrumbs .container {
        padding: 0 20px;
    }
    .philanthropy-breadcrumbs #content .breadcrumbs ul li {
        line-height: 2em;
    }
}