.location-info {
  margin-bottom: 30px;
  /* commented this to move the franchise name more upwards */
  /* margin-top: 20px; */
}
.location-info .heading {
  margin-bottom: 15px;
  font-size: 2.25rem !important;
  line-height: 1em;
}
.heading {
  color: #000;
}
.location-info .heading span {
  display: block;
  font-size: 1.3125rem;
  line-height: 1.3333333333333333em;
  font-family: "Roboto Condensed", sans-serif;
}
.location-info .mini-reviews {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6d6d7;
}
.mini-reviews .stats {
  margin-right: 15px;
}
.mini-reviews .stats,
.mini-reviews .recommend,
.mini-reviews .read-reviews {
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
}
.mini-reviews .stats .rating .rating-group {
  margin: 0 !important;
  padding: 0;
}
.mini-reviews .stats .rating .rating-group,
.mini-reviews .stats .rating .count {
  display: inline-block;
  vertical-align: top;
}
.mini-reviews .stats .rating .rating-group li:first-child {
  margin-left: 0;
}
.mini-reviews .stats .rating .rating-group li {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
}
.mini-reviews .stats .rating .rating-group li span.r-star {
  overflow: hidden;
}
.mini-reviews .stats .rating .rating-group li span {
  display: block;
}
.mini-reviews .stats .rating .rating-group li span.bg:before {
  content: "" !important;
  background: transparent
    url("../FranchiseCommon/images/reviews-star-empty.png") no-repeat center
    center !important;
  background-size: contain !important;
}
.mini-reviews .stats .rating .rating-group li span.r-star:before {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 26px;
  background: transparent url("../FranchiseCommon/images/reviews-star-full.png")
    no-repeat center center;
  background-size: contain;
}
.mini-reviews .stats .rating .rating-group li span.active {
  position: absolute;
  top: 0;
  left: 0;
}
.mini-reviews .recommend {
  margin-right: 10px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1em;
}
.mini-reviews .recommend:after {
  content: "|";
  margin-left: 10px;
}
.mini-reviews .read-reviews {
  font-size: 0.9375rem;
  line-height: 1em;
  line-height: 1.5em;
  text-align: center;
}
.mini-reviews .read-reviews a {
  font-weight: 500;
  color: #63666a;
  text-decoration: underline;
  text-transform: none;
}
.location-info .service-areas {
  max-width: 500px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.4;
  margin-bottom: 0px;
}

.location-info .service-areas a {
  color: #222;
  font-size: 16px;
}

.service-areas .service-area .city-names:before {
  content: '● ';
  position: absolute;
  margin-left: -20px;
  color: #d40029;
}

.service-areas .city-names a {
  font-weight: 700;
}

@-moz-document url-prefix() {
  .service-areas .city-names a,
  .more-link {
    font-weight: 500 !important;
  }
}

.location-info .area-map {
  cursor: pointer;
  color: #d40029;
  margin-top: 10px;
  /* width: 150% */
  width: 145%;
}

.location-info .area-map .area-container {
  display: flex;
}
.location-info .area-map .area-container .map-area {
  width: 100%;
}
.location-info .area-map .area-container .service-area {
  width: 100%;
}

.location-info .area-map i {
  margin-right: 5px;
}
.location-info i {
  width: 15px;
}
html .fa {
  color: inherit;
}
.fa.fa-map-marker:before {
  content: "\f3c5";
}
.location-info .phone {
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  color: #d40029;
  font-size: 2rem;
  line-height: 1.875em;
}
.hours-wrap {
  margin-top: 20px;
}
.hours-wrap table#hours {
  width: 100%;
  margin: 0 0 20px 0;
  border: 0;
  border-bottom: 1px solid #d6d6d7;
  font-size: 0.875rem;
  line-height: 2.2857142857142856em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.hours-wrap table#hours td:first-child {
  font-weight: 700;
  padding-left: 0;
  width: 120px;
}
.hours-wrap table#hours td {
  padding: 10px;
  border: 0;
  border-bottom: 1px solid #d6d6d7;
}
table th,
table td {
  padding: 5px;
  line-height: 1.2em;
  border: 1px solid #ddd;
}
.book247-button {
  display: inline-block;
  margin: 20px 0;
}

.content-section .btn {
  font-weight: 400;
}
.book247-button a {
  color: #fff;
  padding: 15px 20px;
  margin-top: 0;
  font-weight: 700;
  text-decoration: none;
}
.book247-button a:hover {
  background-color: #ab0021;
  text-decoration: none !important;
}
#newsweek {
  display: flex;
  margin-right: 0px;
  margin-bottom: 20px;
  /* commented this to reduce space above this element and make it even with franchise name heading */
  /* margin-top: 20px; */
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #efeff0;
}
#newsweek .text {
  font-weight: 700;
  color: #000;
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.5714285714285714em;
}
.reasons-heading {
  font-size: 1.75rem;
  line-height: 1.5em;
}

.home .reasons {
  margin: 0 0 30px !important;
  padding: 0;
  list-style: none;
  overflow: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6d6d7;
}

.home .reasons li img {
  max-width: 40px;
  height: 44px;
  margin: 15px 20px 15px 0;
}

.twelve .col-sd-12 {
  width: 100%;
}
.home .reasons li {
  display: table-cell;
  margin: 5px 0;
  padding: 0 20px 0 0;
  vertical-align: middle;
  list-style: none;
  color: #000;
}

.home .reasons li span {
  display: inline-block;
  width: calc(84% - 60px);
  /* width: calc(90% - 60px); */
  font-weight: 700;
  vertical-align: middle;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5714285714285714em;
}
.vid-wrap {
  z-index: 1;
  position: relative;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%;
  background: #000;
}
.vid-wrap iframe {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe {
  vertical-align: middle;
}
.phone .phone-svg {
  display: inline-block;
}
.phone .phone-svg svg {
  width: 21px;
  fill: #d40029;
  margin-right: 5px;
}
.area-map .location-svg {
  display: inline-block;
  /* fill: #d40029; */
}
.area-map .location-svg svg {
  width: auto;
  fill: #d40029;
  margin-right: 5px;
}

/*newly added styles for Franchise home*/
.color-primary {
  color: #d40029 !important;
}

.license-numbers svg {
  fill: #d40029;
}

.serviceCityModalcontainer {
  padding: 10px;
}

.serviceCityModalcontainer .citylist {
  display: flex;
  flex-wrap: wrap;
}

.serviceCityModalcontainer .citylist li {
  flex: 50%;
}

.serviceCityModalcontainer .citylist li a {
  color: #d40029;
}

.serviceCityModalcontainer .citylist li span {
  color: #63666a;
}

.breadcrumb-franchise-home {
  padding: 0 15px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  /* height: 70% !important; */
  width: 45% !important;
  position: fixed !important;
}

.service-areas .service-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: -15px;
}

.service-areas .service-area .city-names {
  width: 100%;
  padding: 5px 35px;
  /* float: left; */
}

@media only screen and (max-width: 800px) {
  .homedetails-container {
    padding: 0 20px;
  }
  /* commented to reduce space above the icons in careers page */
  /* .content-section .row {
        margin-bottom: 60px;
    } */
  .twelve .col-dd-5 {
    width: 41.66%;
  }
  .hours-wrap h3 {
    font-size: 1.5rem;
    line-height: 1.3333333333333333em;
  }
  .twelve .col-dd-2 {
    width: 16.66%;
  }
  h3,
  .h3 {
    font-size: 1.75rem;
    line-height: 1.5em;
  }
  .serviceCityModalcontainer .citylist li {
    flex: 100%;
  }

  .servicing-button {
    margin-top: 5px;
    margin-right: 0;
    width: 100%;
  }
  .servicing-button a {
      margin-left: 0;
  }
  .location-info .area-map .area-container {
      display: inline-block;
  }
  .location-info .area-map .area-container .map-area {
      width: 100%;
      margin-top: 7px
  }
  .location-info .area-map .area-container .service-area {
    width: 100%;
    margin-top: 7px
}
  .location-info .service-areas {
    margin-bottom: -40px;
  }
  
}
@media only screen and (min-width: 600px) {
  #newsweek {
    margin-right: -25px;
  }
  #newsweek .text {
    /* font-size: .875rem; */
    line-height: 1.5555555555555556em;
  }
  .home .reasons li span {
    /* font-size: 1.125rem; */
    line-height: 1.5555555555555556em;
  }
  .reasons-heading {
    font-size: 1.375rem;
  }

  /* added these below to make corp homepage hero logo lower and findanoffice heading higher */
  #banner .banner-slide.home .banner-text img {
    margin-top: 50px;
  }
  #banner .banner-slide.home .banner-text .header .headline {
    margin-bottom: 0px;
  }
  .content #banner {
    margin-bottom: 180px;
  }

  .aceplusphone {
    display: flex;
    flex-direction: row;
    /* changed width from 130% to 120% to account for new font change */
    width: 120%;
  }
  .aceplusphone .phone {
    /* width:36%; */
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 5px;
    padding-left: 20px;
  }
  .aceplusphone .phone-svg {
    margin-top: -11px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 85% !important;
    /* overflow-y: visible !important; */
}
}

@media only screen and (max-width: 600px) {
  #newsweek .text {
    font-size: 0.875rem;
    line-height: 1.5555555555555556em;
  }
  .home .reasons li span {
    /* whychoose us span text size */
    width: calc(90% - 60px);
    font-size: 0.875rem;
    line-height: 1.5555555555555556em;
  }
  .reasons-heading {
    font-size: 1.375rem;
  }
  .content-wrap {
    margin-right: 0 !important;
  }
  .mini-reviews .stats {
    display: block;
  }

  .aceplusphone .phone {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    /* padding-left: 20px; */
    /* width:100%; */
  }
  .aceplusphone .phone-svg {
    margin-top: -11px;
  }

  /* map modal mobile view */
  .ReactModal__Content.ReactModal__Content--after-open {
    /* height: 70% !important; */
    width: 80% !important;
    position: fixed !important;
  }
}

@media only screen and (min-width: 800px) {
  .breadcrumb-franchise-home {
    padding: 0;
  }

  .location-info .area-map .area-container {
    display: inline-block;
    /* text-align: center;
    justify-content: flex-start; */
}

.location-info .area-map .area-container .map-area {
  /* width: 40%; */
  width: 100%;
}

.location-info .area-map .area-container .service-area {
  /* width: 40%; */
  width: 100%;
}

  .service-areas .service-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -15px;
    /* margin-right: -165px; */
    margin-right: -85px;
  }
  .service-areas .service-area .city-names {
    width: 33.33%;
    padding: 6px 15px;
    /* float: left; */
  }

  /* to make space above and below breadcrumbs less so that the phone number appears next to franchise heading */
  .breadcrumb-franchise-home .breadcrumb {
    padding: 0 !important;
  }

  /* to account for new font change */
  .location-info .heading span {
    width: 195px;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    width: 85% !important;
    /* overflow-y: visible !important; */
}
}

@media only screen and (min-width: 1300px) {
  .breadcrumb-franchise-home {
    padding: 0;
  }

  .location-info .area-map .area-container .map-area {
    width: 100%;
  }
  
  .location-info .area-map .area-container .service-area {
    width: 100%;
  }

  .location-info .area-map .area-container {
    display: flex;
    text-align: start;
    justify-content: flex-start;
}
.service-areas .service-area {
  margin-right: -165px;
}
}
