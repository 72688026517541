.careers-form-container {
  width: 90%;
  margin: 20px auto;
  padding: 15px;
  margin-top: 100px !important;
}

.career-header {
  margin-bottom: -78px;
  margin-top: 0px;
}

.step-info {
  margin-top: 40px;
  margin-bottom: -5px;
  color: #000;
  text-align: end;
}

input {
  background: #efeff0;
  color: #8a8c8f;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 2.1333333333333333em;
  font-family: "Roboto Condensed", sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
}

.form-row .first-field,
.second-field {
  margin-right: 15px;
}

.form-field-separator {
  border-bottom: 1px solid #ccc;
  margin-top: -7px;
}

.form-navigation-container {
  display: flex;
  justify-content: space-between;
}

.form-navigation-container button {
  padding: 1rem 3rem !important;
  font-weight: bold;
}

.careers-form-container .breadcrumbs .container {
  padding-left: 0 !important;
  position: absolute;
}

.careers-form-container .breadcrumbs li a:hover {
  text-decoration: underline !important;
}

@media only screen and (min-width: 600px) {
  .career-header {
    margin-bottom: -83px;
    margin-top: 10px;
  }

  .step-info {
    margin-bottom: 0px;
  }

  .form-field-separator {
    margin-top: -8px;
  }
}

input[type="date"] {
  display:block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-width: 49%;
  line-height: 60px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #999;
  font-size: 16px;
}