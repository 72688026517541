.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  -moz-transition: max-width 0.3s ease;
  -webkit-transition: max-width 0.3s ease;
  -ms-transition: max-width 0.3s ease;
  -o-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

/* .get-estimate-container {
    width: 100%;
    max-width: 1080px;
    padding: 0 15px;
    margin: 0 auto;
    transition: max-width .3s ease;
} */

.tab-content-wrap .tab-content {
  display: none;
  margin-bottom: 0;
  border: 1px solid #efeff0;
  padding: 20px;
}

.tab-content-wrap #get-a-quote .content {
  padding: 0;
}

#get-a-quote h3 {
  font-size: 25px;
  text-align: center;
}

h3 {
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;

  color: #000;

  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

h3,
p,
ul {
  margin: 0 0 20px;
}

form {
  position: relative;
}

.get-quote-wrap input,
.get-quote-wrap textarea,
.get-quote-wrap select {
  padding: 10px 6px;
}

input,
textarea,
select {
  width: 100%;
  margin: 0 0 1px;
  padding: 15px;
  outline: 0;
  border: 0;
  background: #efeff0;
  color: #8a8c8f;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 2.1333333333333333em;
  font-family: "Roboto Condensed", sans-serif;
}

.jotform-form.photo-quote-form ul {
  margin: 0 !important;
  list-style: none;
}

ul {
  margin: 20px 0 20px 20px;
  font-size: 1rem;
  line-height: 1.75em;

  list-style: none;
}

.twelve .col-sd-12 {
  width: 100%;
}

.get-quote-wrap form .form-field {
  padding: 0 0 15px;
}

.jotform-form.photo-quote-form ul li {
  list-style: none;
}

ul li {
  list-style: disc outside;
}

.jotform-form.photo-quote-form label.form-label-hide {
  position: absolute;
  top: -9999px;
  left: -9999px;
  font-size: 0;
}

/* JotForm input focus  */
.jotform-form input[type="text"]:focus,
.jotform-form textarea:focus,
.jotform-form input[type="text"]:hover,
.jotform-form textarea:hover,
.jotform-form.photo-quote-form .form-upload:hover + label,
.jotform-form.photo-quote-form .form-upload:focus + label {
  outline: 0;
  box-shadow: inset 0 0 3px #adadb2;
}

label {
  cursor: pointer;
  font-style: italic;
  color: #666;
  font-size: 0.625rem;
  line-height: 1em;
}

img {
  width: 100%;
  height: auto;
  margin: 0 auto;

  vertical-align: middle;
}

.tab-content-wrap #get-a-quote .splash-image {
  overflow: visible;
  /* padding-left: 40px; */
  padding-left: 98px;
  /* padding-top: 170px; */
}

.tab-content-wrap #get-a-quote .splash-image1 {
  overflow: visible;
  /* padding-left: 40px; */
  /* padding-left: 98px; */
  /* padding-top: 280px; */
}

.tab-content-wrap .splash-image {
  position: relative;
  display: none;
  overflow: visible;
}

.tab-content-wrap .splash-image img {
  max-width: 130%;
  max-height: 100%;
}

.tab-content-wrap .splash-image1 {
  position: relative;
  display: none;
  overflow: visible;
}

.tab-content-wrap .splash-image1 img {
  max-width: 130%;
  max-height: 100%;
}

img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.jotform-form.photo-quote-form {
  position: relative;
}

.jotform-form.photo-quote-form .no-margin {
  margin: 0;
}

.jotform-form.photo-quote-form #id_8,
.jotform-form.photo-quote-form #id_10,
.jotform-form.photo-quote-form #id_11 {
  padding: 10px;
}

.jotform-form.photo-quote-form .form-upload {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
}

.jotform-form.photo-quote-form .form-upload + label {
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  line-height: 3rem;
  color: #b8b8b8;
  background-color: #efeff0;
  transition: background-color 0.3s;
  text-align: center;
  width: 80%;
  height: 70px;
  margin-left: 20px;
  /* padding: 10px; */
}

.jotform-form.photo-quote-form .form-upload + label span.icon {
  padding: 0 0 10px;
}

.jotform-form.photo-quote-form .form-upload + label * {
  pointer-events: none;
}

.jotform-form.photo-quote-form .form-upload + label .fa {
  color: #b8b8b8;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-camera:before {
  content: "\f030";
}

.jotform-form.photo-quote-form .form-upload + label span.photo-text {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5714285714285714em;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  padding: 0;
}

.jotform-form.photo-quote-form .form-buttons-wrapper button {
  height: auto;
}

.get-quote-wrap button {
  width: 100%;
  margin-top: 0;
}

.submit {
  width: 100%;
  padding: 15px 20px;
  font-size: 0.875rem;
  line-height: 1em;
}

.btn {
  display: inline-block;
  text-align: center;
  background: #d40029;
  color: #fff;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.3333333333333333em;
  font-family: "Roboto", sans-serif;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 10px;
  font-style: normal;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

textarea {
  height: 75px;
}

#id_3 .error input {
  color: red !important;
  background: #fee !important;
  /* font-size:.85rem; */
}

#id_4 .error input {
  color: red !important;
  background: #fee !important;
  /* font-size:.70rem; */
}

#id_5 .error input {
  color: red !important;
  background: #fee !important;
}

#id_6 .error input {
  color: red !important;
  background: #fee !important;
  /* font-size:.5rem; */
}

#id_7 .error textarea {
  color: red !important;
  background: #fee !important;
}

#id_city .error input {
  color: red !important;
  background: #fee !important;
}

#id_state .error input {
  color: red !important;
  background: #fee !important;
}

#id_a1 .error textarea {
  color: red !important;
  background: #fee !important;
}

#id_ln .error input {
  color: red !important;
  background: #fee !important;
}

.hideField {
  display: none;
}

.jotform-img-container {
  position: relative;
}

.jotform-img-container .form-upload {
  width: 100% !important;
  height: 100% !important;
  clip: auto !important;
  cursor: pointer;
  opacity: 0;
}

.jotform-img-container .img-preview {
  height: inherit !important;
  width: auto !important;
}

/* make HANDYMAN MATTERS text bold */
.tab-content-wrap
  .get-estimate-container
  #get-a-quote
  .content
  .get-quote-wrap
  .form
  #field-wrap
  p
  span
  p
  span:nth-of-type(2) {
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  .tab-content-wrap .tab-content.current {
    display: block;
  }

  .tab-content-wrap .tab-content {
    padding: 20px 0;
    border: 0 solid #efeff0;
  }

  .twelve .col-md-12 {
    width: 100%;
  }

  .twelve .col-md-6 {
    width: 50%;
  }

  .twelve .col-md-9 {
    width: 75% !important;
  }

  .twelve .col-md-3 {
    width: 25% !important;
  }

  .get-quote-wrap form span {
    padding: 0 15px 15px 0;
  }

  img {
    width: auto;
  }

  .twelve .col-md-4 {
    width: 33.33%;
  }

  .get-quote-wrap button {
    height: 95px;
  }

  .tab-content-wrap #get-a-quote {
    padding: 50px 10%;

    background: #fff;
  }
}

/* @media only screen and (min-width: 1300px) {
    .get-estimate-container {
        max-width: 1240px;
    }
} */

@media only screen and (min-width: 800px) {
  /* .get-estimate-container {
        padding: 0 20px;
    } */
  .tab-content-wrap #get-a-quote {
    padding: 0 40px 0 70px;
    background: #fff;
    /* margin-top: -10px; */
  }

  .container {
    padding: 0 20px;
  }

  .tab-content-wrap .tab-content {
    padding: 0;
    position: relative;
  }

  .tab-content-wrap #get-a-quote .content {
    padding: 40px 0 40px 0;
  }

  #get-a-quote .col-dd-6 {
    /* width: 45% !important; */
    width: 50%;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.5em;
  }

  .twelve .col-dd-12 {
    width: 100%;
  }

  .tab-content-wrap .splash-image {
    display: block;
 
  }

  .tab-content-wrap .splash-image1 {
    display: block;
    position: absolute !important;
    right:0;
    bottom:55px;
  }

  #get-a-quote h3 {
    font-size: 29px;
    text-align: left;
    /* margin-bottom: -25px !important; */
    margin-bottom: -10px;
    margin-top: -20px !important;
  }

  .get-quote-wrap input,
  .get-quote-wrap textarea,
  .get-quote-wrap select {
    padding: 5px 15px;
  }
}

.loadergif {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d40029;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

.loading-text {
  margin: 0; /* Remove any default margins */
  color:#fff !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* form {
    position: relative;
}
h3{
    font-size: 30px;
}
p{
    font-size: 19px;
    line-height: 1.5;
}
ul {
    margin: 20px 0 20px 20px;
    font-size: 1rem;
    line-height: 1.75em;
}
label {
    cursor: pointer;
    font-style: italic;
    color: #666;
    font-size: .625rem;
    line-height: 1em;
}
.get-quote-wrap input, .get-quote-wrap textarea, .get-quote-wrap select {
    padding: 10px 15px;
}
.jotform-form.photo-quote-form ul {
    margin: 0;
    list-style: none;
}
.jotform-form.photo-quote-form ul li {
    list-style: none;
}
.jotform-form.photo-quote-form label.form-label-hide {
    position: absolute;
    top: -9999px;
    left: -9999px;
    font-size: 0;
}
.jotform-form.photo-quote-form #cid_6 {
    position: relative;
}
.get-quote-wrap textarea {
    display: block;
    height: 95px;
}
.jotform-form.photo-quote-form .no-margin {
    margin: 0;
}
.jotform-form.photo-quote-form #id_8, .jotform-form.photo-quote-form #id_10, .jotform-form.photo-quote-form #id_11 {
    padding: 10px;
    margin-right: 15px;
}
.jotform-form.photo-quote-form .form-upload {
    position: absolute !important;
    overflow: hidden;
    clip: rect(0,0,0,0);
    width: 1px;
    height: 1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
}
.jotform-form.photo-quote-form .form-upload+label {
    cursor: pointer;
    display: inline-block;
    font-size: 2rem;
    line-height: 4rem;
    color: #b8b8b8;
    background-color: #efeff0;
    transition: background-color 3s;
    text-align: center;
    width: 100%;
    padding: 10px;
}
.jotform-form.photo-quote-form .form-upload+label span.icon {
    padding: 0 0 10px;
}
.jotform-form.photo-quote-form .form-upload+label * {
    pointer-events: none;
}
.jotform-form.photo-quote-form .form-upload+label .fa {
    color: #b8b8b8;
}
.fa {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.jotform-form.photo-quote-form .form-upload+label span.photo-text {
    display: block;
    font-size: .875rem;
    line-height: 1.5714285714285714em;
    font-style: normal;
    font-family: 'Roboto',sans-serif;
    padding: 0;
}
.jotform-form.photo-quote-form .form-buttons-wrapper button {
    height: auto;
}
.btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: 10px;
    font-style: normal;
    border: 0;    
    transition: all .3s ease;
}
.get-quote-wrap button {
    width: 100%;
    margin-top: 0;
}
.submit {
    width: 100%;
    padding: 15px 20px;
    font-size: .875rem;
    line-height: 1em;
}
.btn {
    font-weight: 400;
}
.tabbed-top-wrap .tabbed-content-container .tab-content-wrap .splash-image {
    overflow: hidden !important;
}
.tabbed-top-wrap {
    padding: 0;
    position: relative;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 30%);
}

@media only screen and (min-width: 800px){
    .tabbed-top-wrap .tabbed-content-container .tab-content-wrap #get-a-quote {
        padding: 0 0 0 40px;
    }
    .tabbed-top-wrap .tabbed-content-container .tab-content-wrap #get-a-quote .content {
        padding: 40px 0;
    }
    .twelve .col-dd-12 {
        width: 100% !important;
    }
    .twelve .col-dd-6 {
        width: 50%;
    }
}

@media only screen and (min-width: 600px) {
    .tabbed-top-wrap .container {
        padding: 0;
    }
    .twelve .col-sd-12 {
        width: 50%;
    }
    .get-quote-wrap form span {
        padding: 0 15px 15px 0;
    }
    .twelve .col-md-6 {
        width: 50% !important;
    }
    .twelve .col-md-9 {
        width: 75% !important;
    }
    .twelve .col-md-3 {
        width: 25% !important;
    }
    .twelve .col-md-4 {
        width: 33.33% !important;
    }
    .tabbed-top-wrap {
        padding: 0;
    }
} */
