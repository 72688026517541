#corp-estimate-submission-banner {
    clear: both;
}

#corp-estimate-submission-banner .banner-slide {
    background-position: center;
    background-size: cover;
}

#corp-estimate-submission-banner .banner-slide .container .banner-text h1 {
    font-size: 2rem;
    line-height: 1.5em;

    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

#corp-estimate-submission-banner .header {
    padding: 40px 0 0;
}

@media only screen and (min-width: 800px){
    #corp-estimate-submission-banner .banner-slide .container {
        padding: 0 20px;
    }

    #corp-estimate-submission-banner .header {
        padding: 90px 0 0;
    }

    #corp-estimate-submission-banner {
        padding-top: 0;
        
        margin-top:12rem;
    }

    #corp-estimate-submission-banner .banner-slide .container .banner-text h1 {
        font-size: 3rem;
        line-height: 1.3333333333333333em;
    }
}
