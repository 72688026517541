.home-content .tabbed-content-container {
    margin: 0;
    padding: 0 0 30px;
    background: #fff;
    /* added this to add space under schedule it/get estimate */
    /* margin-bottom: 40px; */
    margin-bottom: 0px;
}

.home-content .tabbed-content-container .hide-md {
    padding: 20px 0 10px;
}

.home-content .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    -moz-transition: max-width .3s ease;
    -webkit-transition: max-width .3s ease;
    -ms-transition: max-width .3s ease;
    -o-transition: max-width .3s ease;
    transition: max-width .3s ease;
}

.home-content .tabbed-content-container .hide-md .heading {
    font-family: 'Roboto Condensed', sans-serif;
    color: #707070;
    font-weight: 700;
    text-align: center;
    font-size: 1rem;
    line-height: 1.625em;
    padding-bottom: 10px;
}

.home-content .tabbed-content-container .tab-line {
    display: none;
    margin-bottom: 0;
    border-bottom: 1px solid #d3d3d3;
    background: #efeff0;
    -webkit-box-shadow: 0 -7px 7px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 -7px 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -7px 7px rgb(0 0 0 / 10%);
}

.home-content .twelve .col-sd-12 {
    width: 100%;
}

.home-content .tabbed-content-container .tab-line .tagline {
    display: none;
    padding: 10px 0 0 0;
    color: #707070;
    text-align: right;
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
}

.home-content .tabbed-content-container .tab-line .tagline .phone {
    display: inline-block;
    margin-left: 5px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    color: #000;
    font-size: 1.75rem;
    line-height: .9285714285714286em;
}

/* .home-content .tabbed-content-container .tab-line .tagline .phone:before {
    content: "\f095";
    margin-right: 5px;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 80%;
} */

.home-content .tabbed-content-container .tab-line .tagline .phone a {
    color: #000;
}

.home-content .tabbed-content-container .tab-line .tagline .phone a:hover {
    text-decoration: underline;
}

.home-content .tabbed-content-container .tab-line .tagline .heading {
    font-family: 'Roboto Condensed', sans-serif;
    color: #707070;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.2222222222222223em;
}

.home-tab-selector-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    /* margin-bottom: 10px; */
    box-sizing: border-box;
}

.home-tab-selector-container .tab-options {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    margin-left: 70px;
}

.home-tab-selector-container .tab-options .tab-option {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    height: 100px;
    width: 240px;
    justify-content: center;
    /* border: 1px solid #d3d3d3; */
    /* box-shadow: 0 0 1px 10px rgb(0 0 0 / 20%); */

}

.home-tab-selector-container .tab-options .tabs-option {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    height: 100px;
    width: 240px;
    justify-content: center;
    background: white !important;
    color: black;
    /* border: 1px solid #d3d3d3; */
    /* box-shadow: 0 0 0px 10px rgb(0 0 0 / 20%); */
}

.home-tab-selector-container .tab-options .tab-option svg {
    position: relative;
    top: -3px;
    display: inline;
    width: 26px;
    height: 26px;
    margin-right: 7px;
}

.home-tab-selector-container .tab-options .tabs-option svg {
    position: relative;
    top: -3px;
    display: inline;
    width: 26px;
    height: 26px;
    margin-right: 7px;
}

.home-tab-selector-container .tab-options .tab-option h2 {
    font-size: 16px;
    margin: 0;
    box-sizing: border-box;
    /* padding-bottom: 10px; */
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

.home-tab-selector-container .tab-options .tabs-option h2 {
    font-size: 16px;
    margin: 0;
    box-sizing: border-box;
    /* padding-bottom: 10px; */
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.home-tab-selector-container .tab-options .tab-option div {
    margin-left: 10px;
    padding: 2px;
    box-sizing: border-box;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.home-tab-selector-container .tab-options .tabs-option div {
    margin-left: 10px;
    padding: 2px;
    box-sizing: border-box;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.home-content .tabbed-content-container .toggle-heading {
    width: 100%;
    margin: 10px 0 0;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 1.1em;
}

.home-content .tabbed-content-container .btn:hover,
.home-content .tabbed-content-container .btn:focus {
    background: #ab0021;
    text-decoration: none;
}

.home-content .Collapsible {
    /* changed padding: 10px 0 to 0 because there was a bit too much space between the collapsibles */
    padding: 0;
}

/* .get-estimate-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
} */

#id_3 .error input {
    color: red !important;
    background: #fee !important;
    /* font-size:.85rem; */
}

#id_4 .error input {
    color: red !important;
    background: #fee !important;
    /* font-size:.70rem; */
}

#id_5 .error input {
    color: red !important;
    background: #fee !important;
}

#id_6 .error input {
    color: red !important;
    background: #fee !important;
    /* font-size:.5rem; */
}

#id_7 .error textarea {
    color: red !important;
    background: #fee !important;
}

.hideField {
    display: none;
}

@media only screen and (min-width: 600px) {
    .home-content .tabbed-content-container {
        padding: 0;
    }

    .home-content .tabbed-content-container .hide-md {
        display: none;
    }

    .home-content .tabbed-content-container .tab-line {
        display: block;
    }

    .home-content .tagline .phone svg {
        height: 21px;
        width: auto;
        margin-right: 5px;
        vertical-align: middle;
    }
}

@media only screen and (min-width: 800px) {
    .home-content .container {
        padding: 0 20px;
    }

    .home-content .twelve .col-dd-7 {
        width: 58.33%;
    }

    .home-content .tabbed-content-container .tab-line .tagline {
        padding: 10px 0 0 0;
        display: flex;
        /* margin-right: -50px; */
        margin-right: 0;
        margin-top: -80px !important;
    }

    .home-content .twelve .col-dd-5 {
        width: 41.66%;
    }

    /* .get-estimate-container {
        padding: 0 20px;
    } */
}

@media only screen and (min-width: 1024px) {
    .home-content .tabbed-content-container .tab-line .tagline {
        padding: 10px 0 0 0;
        display: flex;
        margin-right: -90px;
        margin-top: -90px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .home-content .tabbed-content-container .tab-line .tagline {
        padding: 10px 0 0 0;
        display: flex;
        margin-right: -90px;
    }
}

@media only screen and (min-width: 1500px) {
    .home-content .tabbed-content-container .tab-line .tagline {
        padding: 10px 0 0 0;
        display: flex;
        margin-right: -50px;
    }
}

/* @media only screen and (min-width: 1300px) {
    .get-estimate-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 900px) {
    .home-content .tabbed-content-container .tab-line .tagline {
        padding: 27px 0 0 0;
    }
} */



@media only screen and (max-width: 900px) {
    .responsive-tagline {
        display: block;
        padding: 0 10px;
        margin-right: 0;
        margin-top: -20px;
    }

    .home-tab-selector-container .tab-options {
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .home-content .tabbed-content-container .toggle-heading {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media only screen and (min-width: 1360px) {

    .home-tab-selector-container {
        margin: auto;
        min-width: 1360px;
    }
}

@media only screen and (max-width: 400px) {
    .home-content .tabbed-content-container {
        padding: 0;
        margin-bottom: 0;
    }
}