.franchise-estimate-submission-address-wrap {
    margin: 60px 0 30px;
}

.franchise-estimate-submission-address-wrap h3 {
    font-family: 'Roboto',sans-serif;
}

.franchise-estimate-submission-address-wrap h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

.franchise-estimate-submission-address-wrap .phone-number {
    font-weight: 700;
    font-family: 'Roboto Condensed',sans-serif;
    font-size: 2rem;
    line-height: 1.5em;
}

.franchise-estimate-submission-address-wrap .phone-number:before {
    color: #d40029;
    content: "\f095";
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    margin-right: 5px;
    font-size: 80%;
}

@media only screen and (min-width: 800px){
    .franchise-estimate-submission-address-wrap h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    }
    .franchise-estimate-submission-address-wrap {
        padding: 0 40px;
    }
}
