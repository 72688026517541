#careers-collage-content .content-section > .row {
  margin-bottom: 40px;
}

#careers-collage-content .content-section .row {
  margin-bottom: 40px;
}

#careers-collage-content .careers-collage .carrier {
  display: grid;
  list-style-type: none;
  /*grid-template-columns: 1fr 1fr;
  gap: 0;
  padding: 0; */
  margin: 0 !important;
  grid-template-columns: repeat(4, minmax(195px, 1fr));
  /* grid-template-columns: repeat(3, 1fr); Set 3 columns of equal width */
  /* grid-gap: 10px; Add some space between grid cells */
  /* padding: 0; Remove default padding */
  /* padding-left: 40px; */
}

#careers-collage-content .carrier {
  margin: 20px 0 20px 20px;
  font-size: 1rem;
  line-height: 1.75em;

  margin: 0 0 20px;

  list-style: none;
}

#careers-collage-content .careers-collage .list {
  aspect-ratio: 1.3333333333333333;
}

#careers-collage-content .home-gallery .list {
  width: 25vw;
}

#careers-collage-content .home-gallery-half-width .list {
  width: 50vw;
}

#careers-collage-content .carrier .list {
  list-style: disc outside;
}

#careers-collage-content .careers-collage img {
  display: block;
  width: 100% !important;
  height: 100%;
  object-fit: contain;
  transition: 0.25s ease all;
  padding-right: 22px;
  /* padding-left: 10px; */
  padding-left: 22px;
}

/* #careers-collage-content .careers-collage img:hover {
  transform: scale(1.2);
  -webkit-box-shadow: 0 0 20px #333;
  -moz-box-shadow: 0 0 20px #333;
  box-shadow: 0 0 20px #333;
} */

#careers-collage-content img {
  width: 100%;
  height: auto;
  margin: 0 auto;

  vertical-align: middle;
}

#careers-collage-content .careers-collage .category .add-caption {
  position: relative;
  width: 100%;
  padding: 5px 10px;
  background-color: #000;
}

#careers-collage-content .careers-collage .category .no-caption span {
  margin: 0;
}

#careers-collage-content .careers-collage .category .add-caption span,
#careers-collage-content .careers-collage .category .add-caption a {
  font-weight: 600;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.7777777777777777em;
}

#careers-collage-content .careers-collage .category .add-caption span p {
  text-align: center !important;
  font-size: 22px !important;
}

#careers-collage-content .careers-collage .carrier .category.space,
#careers-collage-content .careers-collage .carrier .category.non-space {
  position: relative;
  /* padding-bottom: 30px; */
  font-size: 0;
  line-height: 1;
}

@media only screen and (min-width: 800px) {
  #careers-collage-content .content-section > .row {
    margin-bottom: 80px;
  }

  #careers-collage-content .content-section .row {
    margin-bottom: 60px;
  }

  #careers-collage-content .careers-collage .carrier {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }

  #careers-collage-content .careers-collage .carrier .category.space,
  #careers-collage-content .careers-collage .carrier .category.non-space {
    /* position: relative; */
    padding-bottom: 20px;
    font-size: 0;
    line-height: 1;
  }

  .space-even {
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .space-even .add-caption span {
    font-weight: 500 !important;
  }

  #careers-collage-content .careers-collage.home-gallery-half-width .carrier {
    grid-template-columns: 1fr 1fr;
  }

  #careers-collage-content .careers-collage .category .add-caption {
    padding: 15px;
  }

  #careers-collage-content
    .careers-collage
    .carrier
    .category.space:nth-child(odd),
  #careers-collage-content
    .careers-collage
    .carrier
    .category.space:nth-child(even) {
    /* padding-right: 15px; */
    padding-left: 10px;
  }

  #careers-collage-content
    .careers-collage
    .carrier
    .category.non-space:nth-child(odd),
  #careers-collage-content
    .careers-collage
    .carrier
    .category.non-space:nth-child(even) {
    padding-right: 0px;
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 800px) {
  /* #careers-collage-content .careers-collage .category .add-caption {
    height: 247%;
  } */
}
@media only screen and (min-width: 501px) and (max-width: 600px) {
  #careers-collage-content .home-gallery .list {
    width: 40vw !important;
  }
  #careers-collage-content .careers-collage.home-gallery-half-width .carrier {
    grid-template-columns: 1fr;
  }

  #careers-collage-content .home-gallery-half-width .list {
    width: 100vw;
  }
}

@media only screen and (min-width: 600px) {
  #careers-collage-content .careers-collage img {
    padding-left: 12px;
  }

  #careers-collage-content img {
    width: auto;
  }

  #careers-collage-content
    .careers-collage
    .category.category.non-space
    .add-caption {
    position: absolute;
    bottom: 0;
    padding: 5px 15px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  #careers-collage-content
    .careers-collage
    .category.non-space
    .add-caption
    span {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
  }

  #careers-collage-content .home-gallery-half-width .list {
    width: 45vw;
  }

  #careers-collage-content .careers-collage.home-gallery-half-width .carrier {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  #careers-collage-content .home-gallery .carrier {
    grid-template-columns: 1fr !important;
    padding: 0;
  }

  #careers-collage-content .careers-collage .carrier .category.space,
  #careers-collage-content .careers-collage .carrier .category.non-space {
    position: relative;
    padding-bottom: 20px;
    font-size: 0;
    line-height: 1;
  }

  #careers-collage-content .home-gallery .list {
    width: 100vw !important;
  }

  #careers-collage-content .careers-collage.home-gallery-half-width .carrier {
    grid-template-columns: 1fr;
  }

  #careers-collage-content .home-gallery-half-width .list {
    width: 100vw;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 740px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 4) {
  #careers-collage-content .careers-collage img {
    display: block;
    width: 100% !important;
    height: 120%;
    object-fit: contain;
    transition: 0.25s ease all;
  }

  #careers-collage-content .careers-collage .carrier {
    display: grid;
    list-style-type: none;
    margin: 0 !important;
    grid-template-columns: repeat(3, 1fr); /* Set 3 columns of equal width */
    grid-gap: 10px; /* Add some space between grid cells */
    padding-left: 43px; /* Remove default padding */
  }
}
