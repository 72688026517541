main {
  display: block;
}
.site {
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
.site-inner {
  margin: 0 auto;
  max-width: 1320px;
  position: relative;
}
.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgb(0 0 0 / 20%);
  color: #21759b;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}
a {
  color: #63666a;
  text-decoration: none;
}
.says,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.site-content {
  word-wrap: break-word;
}
#blog-content .breadcrumbs {
  margin-bottom: 10px;
}
.site-main > article {
  padding-bottom: 3.5em;
  margin-bottom: 3.5em;
  border-bottom: 2px solid #d3d3d3;
  position: relative;
}
.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6;
}
blockquote > :last-child {
  margin-bottom: 0 !important;
}
.site blockquote p {
  margin-bottom: 1.4736842105em;
  text-align: center;
}
.site blockquote {
  color: #d40029;
  font-weight: 400;
  font-family: "ACE Medium", serif;
  font-size: 1.375rem;
  line-height: 1.4545454545454546em;
  text-align: center;
  margin: 30px 0;
  position: relative;
}
.wp-block-quote {
  overflow-wrap: break-word;
}
.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin-bottom: 1em;
  padding: 0 1em;
}

.site-main > article:before,
.entry-content:before,
.site-content:before {
  content: "";
  display: table;
}

#page .entry-title {
  font-family: "Roboto Slab", serif;
}
.entry-title {
  hyphens: auto;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 1em;
}
h2 {
  color: #000;
  font-family: "Roboto Slab", serif;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
}
#page .entry-title a {
  color: #000;
}
#page .entry-title a:hover {
  color: #d40029;
  text-decoration: none;
}
.site-main > article a {
  font-weight: 700;
  color: #63666a;
}
.entry-content,
.entry-summary {
  border-color: #d1d1d1;
}
p {
  font-size: 18px;
  line-height: 1.7;
  overflow-wrap: break-word;
}
.has-inline-color.has-vivid-red-color {
  color: #cf2e2e;
  font-weight: 100;
}
.entry-content em {
  font-style: normal !important;
}
.entry-content h2 {
  font-weight: 900;
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 1.2173913043;
  margin-top: 2.4347826087em;
  margin-bottom: 1.2173913043em;
}
.entry-content h3 {
  font-weight: 900;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.1052631579;
  margin-top: 2.9473684211em;
  margin-bottom: 1.4736842105em;
}
h3 {
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
}
.entry-content h5 {
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3125;
  margin-top: 3.5em;
  margin-bottom: 1.75em;
}
h5 {
  font-family: "Roboto Condensed", sans-serif;
  clear: both;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
  color: #63666a;
}
p > iframe {
  margin-bottom: 0;
  vertical-align: middle;
  width: 100%;
}
em {
  font-style: normal;
}
.entry-content {
  margin-right: 7.6923%;
  margin-left: 7.6923%;
}
.entry-content > :last-child {
  margin-bottom: 0;
}
.entry-content a {
  box-shadow: 0 1px 0 0 currentColor;
}
.entry-content a img {
  display: block;
}
.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}
a img {
  border: 0;
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.entry-content:after,
.site-main > article:after {
  clear: both;
  content: "";
  display: table;
}
.site-main .entry-footer {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.125rem;
  line-height: 1.7777777777777777em;
}
.entry-footer {
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-top: 2.1538461538em;
}
footer {
  display: block;
}
.byline {
  display: inline;
}
.site-main .entry-footer .author {
  font-size: 1.25rem;
  line-height: 1.6em;
}
.site .avatar {
  border-radius: 50%;
}
.entry-footer .avatar {
  height: 21px;
  margin: -0.1538461538em 0.5384615385em 0 0;
  width: 21px !important;
}
.site-main .entry-footer .author a {
  color: #000;
}
.site-main .entry-footer .author a:hover {
  text-decoration: underline;
}
.site-main .entry-footer .author:after {
  content: "";
}
.entry-footer > span:not(:last-child):after {
  content: "\002f";
  display: inline-block;
  opacity: 0.7;
  padding: 0 0.538461538em;
}
.updated:not(.published) {
  display: none;
}
.entry-footer a:hover,
.entry-footer a:focus {
  color: #007acc;
  text-decoration: underline;
}

.page-header {
  border-top: 4px solid #1a1a1a;
  margin: 0 7.6923% 3.5em;
  padding-top: 1.75em;
}

.page-title {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 1.4375rem !important;
  line-height: 1.2173913043;
}

/* pagination css */
.pagination {
  list-style: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}
.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}
.pagination .page-num:hover {
  background-color: black !important;
  color: #fff;
}
.pagination .active {
  background-color: black !important;
}
.pagination li {
  list-style: none !important;
}

/* Preview CARD CSS */
.preview-cards-container {
  display: flex;
  width: 100%;
  background-color: transparent;
  flex-wrap: wrap;
}

.preview-cards-container .blogContent {
  width: 80%;
  padding-right: 0px;
  padding-bottom: 20px;
}

.preview-cards-container .blogContent .preview-card {
  width: 100%;
  height: 100%;
}

.preview-page-title {
  margin-bottom: 10px;
  margin-top: -30px;

  padding-top: 140px !important;
}
.preview-page-title h2 {
  margin-bottom: 0 !important;
}

.mrgntop {
  margin-top: 30px;
}

#post-9251 a {
  color: #d40029;
}

@media screen and (min-width: 44.375em) {
  .site {
    margin: 0 40px 40px 40px;
  }
  .entry-title {
    font-size: 33px;
    font-size: 2.0625rem;
    line-height: 1.2727272727;
    margin-bottom: 0.8484848485em;
  }
  .entry-content blockquote:not(.alignleft):not(.alignright),
  .entry-summary blockquote,
  .comment-content blockquote {
    margin-left: -1.473684211em;
  }
}

@media screen and (min-width: 56.875em) {
  .site {
    padding-top: 140px;
  }
  .preview-page-title {
    padding-top: 0 !important;
  }
  .site-content {
    padding: 0 4.5455%;
  }
  .content-area {
    width: 100%;
    margin-right: 0%;
  }
  .entry-header,
  .entry-content,
  .entry-footer,
  .pagination {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .page-header {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 800px) {
  .preview-page-title {
    margin-top: 0;
    padding-top: 0 !important;
  }
  .preview-cards-container {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 500px) {
  .preview-cards-container .blogContent {
    width: 30% !important;
    padding-right: 30px;
  }
}

@media screen and (min-width: 600px) and (max-width: 650px) {
  .preview-cards-container .blogContent {
    width: 40% !important;
  }
  .site {
    padding-top: 120px !important;
  }
}

@media screen and (max-width: 799px) {
  .preview-page-title h2 {
    text-align: left;
    margin-top: 0px;
  }
  .preview-cards-container {
    justify-content: center !important;
  }
  .preview-cards-container .blogContent {
    padding-bottom: 20px !important;
    padding-right: 0px !important;
  }
  .site {
    margin-left: 0px;
    margin-right: 0px;
  }
  .site-main > article {
    padding-bottom: 1.5em;
    margin-bottom: -1.7em;
  }
  .preview-cards-container {
    width: 100%;
  }
  .preview-page-title {
    margin-top: 0 !important;
    margin-left: 10px;
    padding-top: 0px !important;
  }
}
