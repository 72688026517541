#careers-community-content a.careers-applynow {
    position: fixed;
    width: 176px;
    z-index: 1000;
    top: 200px;
    right: -46px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: none;
}

#careers-community-content .btn {
    font-weight: 400;
}

#careers-community-content a {
    font-weight: 700;
}

#careers-community-content .btn {
    display: inline-block;
    text-align: center;
    background: #d40029;
    color: #fff;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.3333333333333333em;
    font-family: 'Roboto',sans-serif;
    padding: 15px 30px;
    margin-top: 10px;
    font-style: normal;
    border: 0;
    transition: all .3s ease;
}

#careers-community-content a {
    color: #d40029;

    text-decoration: none;
}

#careers-community-content a.careers-applynow svg {
    height: 39px;
    position: absolute;
    left: 15px;
    top: 15px;
}

#careers-community-content svg {
    max-height: 160px;
}

#careers-community-content .careers-community {
    /* margin: 30px 0; */
    margin: 10px;
    overflow: hidden;
}

#careers-community-content .content-section>.row {
    margin-bottom: 40px;
}

#careers-community-content .content-section .row {
    margin-bottom: 40px;
}

#careers-community-content .careers-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    transition: max-width .3s ease;
}

#careers-community-content .col-sd-12 {
    width: 100%;
}

#careers-community-content h3 {
    color: #000 !important;
    font-weight: bold !important;
}

#careers-community-content h3 {
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
    
    color: #000;
    
    font-family: 'Roboto Slab',serif;
    font-weight: 700;
}

#careers-community-content h3, #careers-community-content p, #careers-community-content blockquote {
    margin: 0 0 20px;
    margin-bottom: 15px;
}

#careers-community-content h3 span {
    font-family: 'Roboto',sans-serif;
    color: #d40029;
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
}

#careers-community-content .careers-community .container blockquote {
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
    padding: 0 40px;
    font-style: italic;
}

#careers-community-content .lg-swoosh {
    font-size: 1.125rem;
    line-height: 1.4444444444444444em;
    padding: 0 40px;
    font-style: italic;
}

#careers-community-content .lg-swoosh {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: 100% 100%;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 158px;
    position: relative;
    margin: 0 0 10px 0;
    font-family: 'Roboto Slab',serif;
}

#careers-community-content blockquote {
    position: relative;

    color: #d40029;
    font-weight: 400;
    font-family: 'Roboto Slab',serif;
    font-style: normal;
    font-size: 1.375rem;
    line-height: 1.4545454545454546em;
    text-align: center;
    margin: 30px 0;
}

#careers-community-content blockquote{
    quotes: none;
}

#careers-community-content .lg-swoosh:before {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    display: block;
    width: 169px;
    height: 158px;
    content: '';
    position: absolute;
    left: -169px;
    top: 0;
}

#careers-community-content blockquote:before, #careers-community-content blockquote:after {
    content: '';
    content: none;
}

#careers-community-content .careers-community .container blockquote p {
    margin-bottom: 15px;
}

#careers-community-content .careers-community .container blockquote span {
    position: absolute;
    bottom: 35px;
    right: 0;
    font-style: normal;
    font-size: 80%;
    color: #000;
}

#careers-community-content .lg-swoosh:after {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position:center center;
    display: block;
    width: 136px;
    height: 158px;
    content: '';
    position: absolute;
    right: -136px;
    top: 0;
}

#careers-community-content .careers-community .container figure {
    display: none;
    margin-bottom: 20px;
}

#careers-community-content .careers-community .container figure img {
    float: left;
    max-width: 100%;
}

#careers-community-content img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    float: left;
    max-width: 100%;
}

#careers-community-content img, #careers-community-content iframe, #careers-community-content object {
    vertical-align: middle;
}

#careers-community-content .careers-container blockquote span {
    position: absolute;
    bottom: 35px;
    /* right: 0; */
    right: 20px;
    font-style: normal;
    font-size: 90%;
    color: #000;
}

#careers-community-content .careers-container figure {
    /* to display image in all views in testimonialWithImage tray */
    /* display: none; */
    margin-bottom: 20px;
}

@media only screen and (min-width: 600px){

    #careers-community-content .careers-community {
        margin: 60px 0;
    }

    #careers-community-content img {
        width: auto !important;
    }

    #careers-community-content .quote {
        text-align: center;
        font-size: 22px;
    }    
}

@media only screen and (min-width: 800px){ 
    #careers-community-content .careers-community {
        margin: 30px 0;
    }

    #careers-community-content .careers-container blockquote span {
        right: 0;
    }

    #careers-community-content .careers-container figure {
        display: block;
    }

    #careers-community-content .lg-swoosh {
        font-size: 1.25rem;
        line-height: 1.4em;
        padding: 0;
        max-width: 500px;
        margin-left: 80px;
    }   

    #careers-community-content a.careers-applynow {
        display: inline;
    }

    #careers-community-content .content-section>.row {
        margin-bottom: 80px;
    }

    #careers-community-content .content-section .row {
        margin-bottom: 60px;
    }

    #careers-community-content .careers-container {
        padding: 0 20px;
    }

    #careers-community-content .pad-right-lg {
        padding-right: 60px;
    }

    #careers-community-content .col-dd-8 {
        /* changed from width: 66.66% */
        width: 60.66% !important;
    }

    #careers-community-content h3 {
        font-size: 2.5rem;
        line-height: 1.25em;
    }

    /* h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
    } */

    #careers-community-content h3 span {
        font-size: 1.625rem;
        line-height: 1.3846153846153846em;
    }

    #careers-community-content .careers-community .container blockquote {
        font-size: 1.25rem;
        line-height: 1.4em;
        padding: 0;
        max-width: 500px;
        margin-left: 80px;
    }

    #careers-community-content .careers-community .container figure {
        display: block;
    }

    #careers-community-content .col-dd-4 {
        /* changed from width: 33.33% */
        width: 33.33%;
    }
}

@media only screen and (min-width: 1300px) {
    #careers-community-content .careers-container {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 1240px) {
    #careers-community-content img {
        max-width: 140%;
    }
}

/* to fix mobile view cropped content */

@media only screen and (max-width: 400px){
    .careers-container span p {
        margin-right: 20px !important;
    }
}